import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row, Form, Table, Button, Modal, Spinner } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import apis from '../../apis';
import {
  AuthedLayout,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../../components';
import {
  SchoolRank,
  StudentGradeNoSeatYearSem,
  StudentPHIAll,
} from '../../model';
import { AuthDispatches, ErrorDispatches } from '../../redux/Dispatches';
import { ApplicationState } from '../../redux/States';
import { SightSection } from './SightSection';
import { TeeSection } from './TeeSection';
import { WHSection } from './WHSection';
import { DiseaseSection } from './DiseaseSection';
import { SeriousDiseaseSection } from './SeriousDiseaseSection';
import { BodyMindBookNSection } from './BodyMindBookNSection';
import { PhysicalSection } from './PhysicalSection';
import { LabParasiteSection } from './LabParasiteSection';
import { LabUrineSection } from './LabUrineSection';
import { BloodSection } from './BloodSection';
import { XraySection } from './XraySection';
import { SundrySection } from './SundrySection';
import { HospitalSection } from './HospitalSection';
import { SolidSection } from './SolidSection';
import { InheritSection } from './InheritSection';
import { InsuranceSection } from './InsuranceSection';
import { RemarkSection } from './RemarkSection';
import { useHistory } from 'react-router';
import { getPhysicalGradeByRank } from '../../utils';
import { I18N } from '../../i18n-raw';
import { useClassGrade, useClassStudents } from '../../hook';
import { toast } from 'react-toastify';

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatch = { ...AuthDispatches, ...ErrorDispatches };
const bodyMindBookNDegree: { [key: string]: string } = {
  1: '輕度',
  2: '中度',
  3: '極重度',
};
const relativeMap1: { [key: number]: string } = {
  1: '父親',
  2: '母親',
  3: '祖父',
  4: '祖母',
  5: '兄',
  6: '弟',
  7: '姐',
  8: '妹',
  13: '外祖父',
  14: '外祖母',
};
const inheritDiseaseMap1: { [key: number]: string } = {
  1: '糖尿病',
  2: '血友病',
  5: '蠶豆症',
  31: '高血壓',
  99: '其他',
};
const InsuranceMap1: { [key: number]: string } = {
  1: '全民健保',
  2: '學生團保',
  9: '其他',
};
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;
const studentClassesHeader: HeaderDisplayKeysWithType<StudentGradeNoSeatYearSem>[] =
  [
    { property: 'year', display: '學年' },
    { property: 'sem', display: '學期' },
    { property: 'schoolName', display: '學校名稱' },
    { property: 'grade', display: '年級' },
    { property: 'className', display: '班級' },
    { property: 'seat', display: '座號' },
  ];
const studentPHIPage: FunctionComponent<Props> = ({
  user,
  loading: globalLoading,
  catchErrorForModal,
}) => {
  if (globalLoading || !user) {
    useHistory().go(0);
    return <></>;
  }

  const [loading, setLoading] = useState(false);
  const [student, setStudent] = useState<StudentPHIAll>();
  const physicalGradeRange = getPhysicalGradeByRank(
    user.schools[user.currentSchool].rank ?? SchoolRank.Junior
  );
  const [radioGrade, setRadioGrade] = useState<number>(physicalGradeRange[0]);
  const [showClassHistory, setShowClassHistory] = useState<boolean>(false);
  const [studentClasses, setStudentClasses] = useState(
    [] as StudentGradeNoSeatYearSem[]
  );
  const semesterArray = Object.values(user.semesters);
  const yearSem = user.semesters[user.currentSemester];

  const { selectedClassGrade, element: classGradeButton } = useClassGrade({
    yearSem,
    disableQueryParamStore: true,
  });

  const { selectedStudent, element: studentButton } = useClassStudents({
    yearSem,
    classId: selectedClassGrade?.id,
  });
  useEffect(refreshStudent, [selectedStudent]);
  function refreshStudent() {
    if (selectedStudent) {
      setLoading(true);
      apis
        .GetStudentWithPHIAll(selectedStudent.pid)
        .then((student) => {
          setStudent(student);
          const validGrades = physicalGradeRange.filter((g) =>
            student.classes.some((s) => s.grade === g)
          );
          setRadioGrade(
            validGrades.length > 0
              ? validGrades[validGrades.length - 1]
              : physicalGradeRange[0]
          );
          setLoading(false);
        })
        .catch(catchErrorForModal);
    }
  }

  let studentView = <></>;
  if (student && selectedStudent) {
    studentView = (
      <>
        <Row>
          <Button
            className="mb-2"
            onClick={() => {
              const pids = [selectedStudent.pid];
              // eslint-disable-next-line no-debugger
              console.log(pids);
              toast
                .promise(
                  apis.downloadWHHealthCard(student, selectedStudent, pids),
                  {
                    pending: '下載中...',
                    success: '下載成功！',
                    error: '下載失敗！請查看錯誤資訊。',
                  }
                )
                .then((blob) => {
                  const objlink = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.href = objlink;
                  a.setAttribute(
                    'download',
                    `國民中小學學生健康通知卡-${selectedStudent.name}.pdf`
                  );
                  document.body.appendChild(a);
                  a.click();

                  a.parentNode?.removeChild(a);
                })
                .catch(catchErrorForModal);
            }}
          >
            <span className="feather icon-download">
              列印學生健康檢查紀錄卡
            </span>
          </Button>
          {/* <Col sm="12">列印學生健康檢查紀錄卡 (
            <Form.Check
              key={`schoolrank-1`}
              inline
              name="group-schoolrank"
              type="radio"
              label={'國小'}
              value={'schoolrank-1'}
            // onChange={(e) => {
            //   setRadioGrade(g);
            // }}
            />
            <Form.Check
              key={`schoolrank-2`}
              inline
              name="group-schoolrank"
              type="radio"
              label={'國中'}
              value={'schoolrank-2'}
            // onChange={(e) => {
            //   setRadioGrade(g);
            // }}
            />
            <Form.Check
              key={`schoolrank-3`}
              inline
              name="group-schoolrank"
              type="radio"
              label={'高中'}
              value={'schoolrank-3'}
            // onChange={(e) => {
            //   setRadioGrade(g);
            // }}
            />
            )</Col> */}
        </Row>
        <Row sm={{ cols: 12 }} className="align-items-start">
          {/* 學生基本資料 */}
          <Col lg="2" className="mr-2 mb-2">
            <div className="sheet-title">學生資料</div>
            <Table bordered className="student-phi">
              <colgroup>
                <col span={1} style={{ width: '20%' }} />
                <col span={1} style={{ width: '80%' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>身份證</th>
                  <td>{student.pid}</td>
                </tr>
                <tr>
                  <th>年級</th>
                  <td>
                    {student.grade}
                    {/* <Button
                      key="cell-plus"
                      className="border-0 px-2 py-1"
                      variant="outline-primary"
                      size="sm"
                      onClick={() => {
                        setShowClassHistory(true);
                        if(!studentClasses.length) {
                          apis
                          .getStudentClasses(selectedStudent.pid, yearSem.year)
                          .then((classes) => {
                            setStudentClasses(classes);
                          })
                          .catch(catchErrorForModal);
                        }
                      }}
                    >
                      <span className="feather icon-plus-square" />
                    </Button> */}
                  </td>
                </tr>
                <tr>
                  <th>班級</th>
                  <td>{student.classes[student.classes.length - 1].no}</td>
                </tr>
                <tr>
                  <th>座號</th>
                  <td>{student.seat}</td>
                </tr>
                <tr>
                  <th>學生</th>
                  <td>{student.name}</td>
                </tr>
                <tr>
                  <th>學號</th>
                  <td>{student.sid}</td>
                </tr>
                <tr>
                  <th>生日</th>
                  <td>{selectedStudent.birth?.toFormat('yyyy/MM/dd')}</td>
                </tr>
                <tr>
                  <th>父親</th>
                  <td>{selectedStudent.dad}</td>
                </tr>
                <tr>
                  <th>母親</th>
                  <td>{selectedStudent.mom}</td>
                </tr>
                <tr>
                  <th>緊急連絡</th>
                  <td>{selectedStudent.erTel}</td>
                </tr>
              </tbody>
            </Table>
            <div className="sheet-title">健康基本資料</div>
            <Table bordered className="student-phi">
              <colgroup>
                <col span={1} style={{ width: '30%' }} />
                <col span={1} style={{ width: '70%' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <DiseaseSection
                      editable
                      deleteable
                      diseases={student.disease}
                      student={selectedStudent}
                      semesters={semesterArray}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></DiseaseSection>
                  </th>
                  {student.disease.length ? (
                    <td
                      style={{
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {student.disease.map(
                        (d, i) =>
                          `${i + 1}.${d.diseaseName}(${d.description})${
                            d.treatment
                          }\n`
                      )}
                    </td>
                  ) : (
                    <td>無資料</td>
                  )}
                </tr>
                <tr>
                  <th>
                    <SeriousDiseaseSection
                      editable
                      deleteable
                      seriousDiseases={student.seriousDisease}
                      student={selectedStudent}
                      semesters={semesterArray}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></SeriousDiseaseSection>
                  </th>
                  {student.seriousDisease.length ? (
                    <td
                      style={{
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {student.seriousDisease.map(
                        (d, i) => `${i + 1}.${d.diseaseName}\n`
                      )}
                    </td>
                  ) : (
                    <td>無資料</td>
                  )}
                </tr>
                <tr>
                  <th>
                    <BodyMindBookNSection
                      editable
                      deleteable
                      bodyMindBookN={student.bodyMindBookN}
                      student={selectedStudent}
                      semesters={semesterArray}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></BodyMindBookNSection>
                  </th>
                  {student.bodyMindBookN.length ? (
                    <td
                      style={{
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {student.bodyMindBookN.map(
                        (d, i) =>
                          `${i + 1}.${d.bodyMindName}(${
                            bodyMindBookNDegree[d.degree]
                          })\n`
                      )}
                    </td>
                  ) : (
                    <td>無資料</td>
                  )}
                </tr>
                <tr>
                  <th>
                    <InheritSection
                      editable
                      deleteable
                      inherit={student.inheritDisease}
                      student={selectedStudent}
                      semesters={semesterArray}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></InheritSection>
                  </th>
                  {student.inheritDisease.length ? (
                    <td
                      style={{
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {student.inheritDisease.map((d, i) => {
                        const inheritDisease =
                          d.inheritDisease === 99
                            ? `其他(${d.states})`
                            : inheritDiseaseMap1[d.inheritDisease];
                        return `${i + 1}.${inheritDisease}_${
                          relativeMap1[d.relative]
                        }\n`;
                      })}
                    </td>
                  ) : (
                    <td>無資料</td>
                  )}
                </tr>
                <tr>
                  <th>
                    <HospitalSection
                      editable
                      deleteable
                      hospitals={student.hospital}
                      student={selectedStudent}
                      semesters={semesterArray}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></HospitalSection>
                  </th>
                  <td>
                    {student.hospital.length
                      ? student.hospital.map((h, i) => `${h.hospitalName}\n`)
                      : '特約醫院'}
                  </td>
                </tr>
                <tr>
                  <th>
                    <InsuranceSection
                      editable
                      deleteable
                      insurance={student.insurance}
                      student={selectedStudent}
                      semesters={semesterArray}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></InsuranceSection>
                  </th>
                  <td>
                    {student.insurance.length
                      ? student.insurance.map((v, i) => {
                          let studentInsurance = ``;
                          if (v.insurance === 9) {
                            studentInsurance = v.states
                              ? `${v.states}\n`
                              : `其他保險\n`;
                          } else {
                            studentInsurance = InsuranceMap1[v.insurance];
                          }
                          return `${studentInsurance}\n`;
                        })
                      : ''}
                  </td>
                </tr>
                <tr>
                  <th>
                    <SolidSection
                      editable
                      deleteable
                      solid={student.sightSolid}
                      student={selectedStudent}
                      semesters={semesterArray}
                      yearSems={student.classes || []}
                      onApiError={catchErrorForModal}
                      onValueUpdated={refreshStudent}
                    ></SolidSection>
                  </th>
                  <td>{student.sightSolid.length ? '有異狀' : '無異狀'}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          {/* 身高體重視力口腔 */}
          <Col lg="3" className="mr-3 mb-3">
            <WHSection
              editable
              whs={student.wh || []}
              student={selectedStudent}
              schoolId={user.currentSchool}
              semesters={semesterArray}
              yearSems={student.classes || []}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <SightSection
              editable
              sights={student.sight || []}
              student={selectedStudent}
              semesters={semesterArray}
              schoolId={user.currentSchool}
              yearSems={student.classes || []}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <TeeSection
              editable
              tee={student.tee || []}
              student={selectedStudent}
              schoolId={user.currentSchool}
              semesters={semesterArray}
              yearSems={student.classes || []}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
          </Col>
          <Col lg="3" className="mr-3 mb-3">
            <div
              className="sheet-title mb-3 py-2"
              style={{
                backgroundColor: 'rgba(194,245,245,0.5)',
                borderRadius: '5px',
              }}
            >
              <Row>
                <Col>
                  <span className="mr-2">年級 :</span>
                  {physicalGradeRange.map((g) => {
                    return (
                      <Form.Check
                        key={`grade-${g}`}
                        disabled={!student.classes.some((c) => c.grade === g)}
                        inline
                        name="group-grade"
                        type="radio"
                        label={I18N.Grades[g] || '錯誤年級'}
                        value={g}
                        checked={radioGrade === g}
                        onChange={(e) => {
                          setRadioGrade(g);
                        }}
                      />
                    );
                  })}
                </Col>
              </Row>
            </div>
            <Row>
              <Col>&nbsp;</Col>
            </Row>
            <PhysicalSection
              editable
              physicals={student.physical}
              student={selectedStudent}
              schoolId={user.currentSchool}
              semesters={semesterArray}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <br />
            {/* <LabSection
              editable
              lab={student.lab}
              student={selectedStudent}
              semesters={semesters}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            /> */}
            <LabParasiteSection
              editable
              parasite={student.parasite || []}
              student={selectedStudent}
              schoolId={user.currentSchool}
              semesters={semesterArray}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <LabUrineSection
              editable
              urine={student.urine}
              student={selectedStudent}
              schoolId={user.currentSchool}
              semesters={semesterArray}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <BloodSection
              editable
              blood={student.blood}
              student={{
                ...selectedStudent,
                classNo: student.classes[student.classes.length - 1].no,
              }}
              schoolId={user.currentSchool}
              semesters={semesterArray}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <XraySection
              editable
              xRay={student.xRay}
              student={selectedStudent}
              schoolId={user.currentSchool}
              semesters={semesterArray}
              yearSems={student.classes || []}
              grade={radioGrade}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
          </Col>
          <Col lg="3" className="mr-3 mb-3">
            {/*臨時性檢查*/}
            <SundrySection
              editable
              sundry={student.sundry || []}
              student={selectedStudent}
              schoolId={user.currentSchool}
              semesters={semesterArray}
              yearSems={student.classes || []}
              onApiError={catchErrorForModal}
              onValueUpdated={refreshStudent}
            />
            <br />
            <div className="sheet-title">
              在校期間重大傷病事故
              {/* <Button
                key="cell-edit"
                className="border-0 px-2 py-1"
                variant="outline-primary"
                size="sm"
                // onClick={() => {
                //   setInputing(true);
                // }}
              >
                <span className="feather icon-edit" />
              </Button> */}
            </div>
            <Table className="student-phi mb-2">
              <tbody>
                <tr>
                  <td>無</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <hr />
        <Row sm={{ cols: 12 }}>
          <Col lg="12" className="mr-12 mb-12">
            <Form.Group>
              <Form.Label className="sheet-title">
                <RemarkSection
                  remark={student.remark ?? ''}
                  student={selectedStudent}
                  semesters={semesterArray}
                  yearSems={student.classes || []}
                  onApiError={catchErrorForModal}
                  onValueUpdated={refreshStudent}
                ></RemarkSection>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                disabled
                value={student.remark ?? ''}
              />
            </Form.Group>
          </Col>
        </Row>
        <Modal
          show={showClassHistory}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          dialogClassName="modal-dialog-full"
          centered
        >
          <Modal.Header closeButton onHide={() => setShowClassHistory(false)}>
            <Modal.Title id="contained-modal-title-vcenter">
              歷年班級座號 -{' '}
              <strong>
                {student.seat} {student.name}
              </strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="overflow-auto">
            <EditableTableDiv
              values={studentClasses}
              headers={studentClassesHeader}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <AuthedLayout>
      <Row className="mb-2 justify-content-start">
        <div className="wid-130 mr-2">{classGradeButton}</div>
        <div className="mr-2">{studentButton}</div>
      </Row>
      <hr />
      {loading ? <Spinner animation="border" variant="info" /> : studentView}
    </AuthedLayout>
  );
};

export const StudentPHIPage = connector(studentPHIPage);
