import { DateTime } from 'luxon';
import React, { FunctionComponent, useState, useRef, useMemo } from 'react';
import { Modal, Row, Button, Table, FormCheck } from 'react-bootstrap';
import apis from '../../apis';
import {
  HeaderDisplayKeysWithType,
  InlineEditableTable,
} from '../../components';
import { PHIWH, Semester, Student, YearSemClasses } from '../../model';
import { WHTableHeader } from '../TableHeaders/WHHeader';

type Props = {
  editable: boolean;
  whs: PHIWH[];
  student: Student;
  schoolId: string;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
};

const inlineWHTableHeader: HeaderDisplayKeysWithType<PHIWH>[] = [
  { property: 'year', display: '學年' },
  { property: 'sem', display: '學期' },
  ...WHTableHeader,
];

export const WHSection: FunctionComponent<Props> = ({
  editable,
  whs,
  student,
  semesters,
  yearSems,
  schoolId,
  onApiError,
  onValueUpdated,
}) => {
  const currentSemester = semesters.find((s) => s.isNow);
  const [inputing, setInputing] = useState(false);
  const [whsightHistoryChecked, whHistoryChecked] = useState<boolean>(false);
  const [whHistory, setWHHistory] = useState([] as PHIWH[]);
  const showSightHistory = useRef<HTMLInputElement>(null);
  const nowYearSem = yearSems.find(
    (s) => s.sem === currentSemester?.sem && s.year === currentSemester?.year
  );
  const wh = useMemo(
    () =>
      whs.find(
        (s) =>
          s.sem === currentSemester?.sem && s.year === currentSemester?.year
      ),
    [currentSemester, whs]
  );

  yearSems
    .filter((s) => !whs.some((wh) => wh.sem === s.sem && wh.year === s.year))
    .forEach((ys) => whs.push({ ...ys, id: 0 }));

  whs.sort((a, b) => a.sem - b.sem + (a.year - b.year) * 100);
  function onValueUpdate(value: PHIWH) {
    apis
      .InsertStudentBodyMetrix(student.pid, value)
      .catch(onApiError)
      .then(onValueUpdated);
  }

  function onValueDelete(value: PHIWH) {
    apis
      .deleteStudentWH(student.pid, value.year, value.sem)
      .catch(onApiError)
      .then(onValueUpdated);
  }

  function getWHHistory() {
    console.log(yearSems);
    if (!whHistory.length && currentSemester?.year && nowYearSem) {
      apis
        .GetBodyMatrixHistory(
          student.pid,
          currentSemester?.year,
          nowYearSem.grade
        )
        .then((student) => {
          setWHHistory(student);
        })
        .catch(onApiError);
    }
  }

  return (
    <>
      <div className="sheet-title">
        身高體重
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Table bordered className="student-phi">
        <tbody>
          <tr>
            <th>身高</th>
            <td>{wh?.height ?? ''}</td>
          </tr>
          <tr>
            <th>體重</th>
            <td>{wh?.weight ?? ''}</td>
          </tr>
          <tr>
            <th>評值</th>
            <td>{wh?.bmiCode ?? ''}</td>
          </tr>
          <tr>
            <th>實歲</th>
            <td>
              {student.birth &&
                DateTime.now().diff(student.birth, 'years').years.toFixed(0)}
            </td>
          </tr>
        </tbody>
      </Table>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            身高體重數據 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineEditableTable
            values={whs}
            headers={inlineWHTableHeader}
            onValueUpdate={onValueUpdate}
            onValueDelete={onValueDelete}
            onRowRender={(v) => ({
              editable:
                editable &&
                (!v.schoolId || schoolId === v.schoolId) &&
                semesters.some((s) => s.sem === v.sem && s.year === v.year),
              deleteable:
                (!v.schoolId || schoolId === v.schoolId) &&
                semesters.some((s) => s.sem === v.sem && s.year === v.year),
            })}
          />
          {/* {!document.location.pathname.includes("graduated") &&
          (
            <FormCheck
              ref={showSightHistory}
              label="顯示歷年資料"
              onChange={(e) => {
                whHistoryChecked(e.target.checked);
                if(e.target.checked) {
                  getWHHistory();
                }
              }}
            />
          )} */}
          {whsightHistoryChecked && (
            <>
              <InlineEditableTable
                values={whHistory}
                headers={inlineWHTableHeader}
                onValueUpdate={onValueUpdate}
                onRowRender={(v) => ({
                  editable:
                    editable &&
                    v.schoolId == schoolId &&
                    semesters.some((s) => s.sem === v.sem && s.year === v.year),
                })}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
