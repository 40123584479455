import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import {  StudentGradeNoSeat, WHAndPreWHNoDiags, YearSem } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { sightDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
type PageData = StudentGradeNoSeat & Nullable<WHAndPreWHNoDiags>;

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '身分證字號', property: 'pid', style: { width: "7%" } },
        { display: '姓名', property: 'name' },
        { display: '年級', property: 'grade' },
        { display: '學期', property: 'year' },
        { display: '班級', property: 'no'},
        { display: '座號', property: 'seat'},
        {
            display: '性別', property: 'sex',
            onRender: (v) => (v === '1' ? '男' : '女'),
            onSheetRender: (v) => (v === '1' ? '男' : '女'),
      
        },
        { display: '身高', property: 'height' },
        { display: '年級', property: 'preGrade'},
        { display: '學期', property: 'preYear' },
        { display: '身高', property: 'heightPre' },
        { display: '增加', property: 'differ' },
    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;


const useCalculateInterval = (): {
    calculateintervalId?: string;
    element: ReactNode;
} => {
    const [chooseId, settype] = useState<string>();
    return {
        calculateintervalId: chooseId,
        element: (
            <>
                <DropdownButton
                    title={(chooseId) || '請選擇查詢區間'}
                    onSelect={(k: string | null) => {
                        if (k == null) return;
                        settype(k);
                    }}
                >
                    <Dropdown.Item eventKey="學年">學年</Dropdown.Item>
                    <Dropdown.Item eventKey="學期">學期</Dropdown.Item>
                </DropdownButton>
            </>
        ),
    };
};

const useIncreasedHight = (): {
    increasedHeightId?: string;
    element: ReactNode;
} => {
    const [chooseId, settype] = useState<string>();
    return {
        increasedHeightId: chooseId,
        element: (
            <>
                <DropdownButton
                    title={(chooseId) || '請選擇增高不足公分數'}
                    onSelect={(k: string | null) => {
                        if (k == null) return;
                        settype(k);
                    }}
                >
                    <Dropdown.Item eventKey="-1 公分">-1公分</Dropdown.Item>
                    <Dropdown.Item eventKey="0 公分">0公分</Dropdown.Item>
                    <Dropdown.Item eventKey="1 公分">1公分</Dropdown.Item>
                    <Dropdown.Item eventKey="2 公分">2公分</Dropdown.Item>
                    <Dropdown.Item eventKey="3 公分">3公分</Dropdown.Item>
                    <Dropdown.Item eventKey="4 公分">4公分</Dropdown.Item>
                </DropdownButton>
            </>
        ),
    };
};

const wHIncressedHeight: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { calculateintervalId, element: calculateIntervalElement } = useCalculateInterval();
    const { increasedHeightId, element: increasedHeightElement } = useIncreasedHight();

    const [students, setStudents] = useState<PageData[]>([]);
    const { currentSemester = '', semesters = {} } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];
    useEffect(() => {
        if (calculateintervalId && increasedHeightId) {
            apis
                .getIncreasedHeight(year, sem, calculateintervalId == "學年" ? 0 : 1, Number(increasedHeightId?.split(" ")[0]))
                .then((r) =>
                    setStudents(
                        r.map(({ wh, ...s }) => ({
                            ...wh,
                            ...s,
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [increasedHeightId, calculateintervalId, year,sem]);

    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-2">
                            {calculateIntervalElement}
                        </Col>
                        <Col xs={3} className="mr-2">
                            {increasedHeightElement}
                        </Col>
                    </Row>
                </Col>
                <div>
                    <Button
                        disabled={!students.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${year}學年${sem}學期_增高不足${increasedHeightId}清單`,
                                values: students,
                                headers: tableHeader,
                                context: SightContext,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={students}
                    context={SightContext}
                />
            </Row>
        </AuthedLayout>
    );
};


export const WHIncressedHeight = connector(wHIncressedHeight);
