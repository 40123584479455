import React, {
  FunctionComponent,
  ReactNode,
  RefObject,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  ExaminedResultEnum,
  PHIBlood,
  Semester,
  Student,
  YearSemClasses,
  PhysicalSetting,
} from '../../model';
import {
  InputDropdownGroupField,
  NumberField,
  OverflowWithTooltip,
  TextField,
} from '../../components';
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import { I18N } from '../../i18n-raw';
import apis, { ApiError, E_GRADE_INVALID } from '../../apis';
import { HoverToolTip } from '../../components/HoverTooltip';

type Props = {
  editable: boolean;
  blood: PHIBlood[];
  student: Student;
  schoolId: string;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  grade: number;
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
};

export const BloodSection: FunctionComponent<Props> = ({
  editable,
  blood,
  student,
  schoolId,
  semesters,
  yearSems,
  grade,
  onApiError,
  onValueUpdated,
}) => {
  const [inputing, setInputing] = useState(false);
  const currentBlood = useMemo(
    () => blood.find((s) => s.grade === grade && s.sem === 1),
    [grade, blood]
  );
  function onValueUpdate(value: PHIBlood, examined: boolean) {
    const validGrade = yearSems.find((s) => s.grade === grade && s.sem === 1);
    console.log(value);
    if (validGrade) {
      const promise = examined
        ? apis.InsertStudentBlood(student.pid, {
            ...DEFAULT_BLOOD_RESULT,
            ...value,
            grade: validGrade.grade,
            sem: 1,
            year: validGrade.year,
          })
        : apis.deleteStudentBlood(student.pid, validGrade.year, 1);

      promise.catch(onApiError).then(onValueUpdated);
    } else {
      onApiError(new ApiError(E_GRADE_INVALID));
    }
    setInputing(false);
  }

  const serialized = serializeBlood(currentBlood);

  return (
    <>
      <div className="sheet-title">
        血液檢查 {grade ?? ''}年級
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
          disabled={currentBlood?.schoolId !== schoolId}
        >
          {currentBlood && currentBlood.schoolId !== schoolId ? (
            <HoverToolTip tooltip={'無法修改非本校資料'}>
              <span className="feather icon-x text-danger border border-danger rounded" />
            </HoverToolTip>
          ) : (
            <span className="feather icon-edit" />
          )}
        </Button>
      </div>
      <Table bordered className="student-phi">
        <tbody>
          <tr>
            <th>受檢</th>
            <td>{currentBlood ? '有受檢' : '尚未受檢'}</td>
          </tr>
          {serialized.length ? (
            <tr>
              <th>血液檢查</th>
              <td>{serialized}</td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {inputing ? (
        <BloodModal
          blood={currentBlood}
          student={student}
          grade={grade}
          onClose={() => setInputing(false)}
          onValueUpdate={onValueUpdate}
        />
      ) : (
        <></>
      )}
    </>
  );
};

type ModelProps = {
  blood?: PHIBlood;
  student: Student;
  grade: number;
  onClose: () => void;
  onValueUpdate: (v: PHIBlood, examined: boolean) => void;
};
function BloodModal({
  blood,
  student,
  grade,
  onClose,
  onValueUpdate,
}: ModelProps): JSX.Element {
  const [examined, setExamined] = useState(!!blood);
  const [physicalSetting, setPhysicalSetting] = useState<PhysicalSetting[]>();
  const [gradePhysicalSetting, setGradePhysicalSetting] = useState<string[]>();
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getSettings();
  }, [examined]);

  useEffect(() => {
    if (physicalSetting?.length) {
      const gradeSetting = physicalSetting.filter(
        (x) => x.grade === grade && x.no === student.classNo
      );
      setGradePhysicalSetting(
        gradeSetting.length ? gradeSetting[0].bloodAdditional : []
      );
    }
  }, [physicalSetting, grade]);

  async function getSettings() {
    try {
      if (!physicalSetting) {
        const rawSettings = await apis.getPhysicalSettings(113, 113);
        setPhysicalSetting(rawSettings);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Modal
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      dialogClassName="modal-dialog-full"
      centered
    >
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title id="contained-modal-title-vcenter">
          血液檢查 -{' '}
          <strong>
            {student.seat} {student.name}
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          initialValues={blood ?? NO_EXAMINED_BLOOD_RESULT}
          onSubmit={(v: PHIBlood) => onValueUpdate(v, examined)}
          validateOnBlur
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, form }) => {
            return (
              <>
                <Row noGutters className="p-2 border">
                  身分證： {student.pid}
                  檢查年級： {grade}
                  是否受檢：{' '}
                  <DropdownButton
                    className="d-inline mr-2"
                    title={examined ? '是' : '否'}
                    onSelect={(k: string | null) => {
                      setExamined((lastExamine) => {
                        const nextExamine = k == '0' ? false : true;
                        if (!lastExamine && nextExamine) {
                          if (
                            gradePhysicalSetting &&
                            gradePhysicalSetting.includes('1')
                          ) {
                            DEFAULT_BLOOD_RESULT.tgResult =
                              ExaminedResultEnum.NoProblem;
                          }
                          if (
                            gradePhysicalSetting &&
                            gradePhysicalSetting.includes('2')
                          ) {
                            DEFAULT_BLOOD_RESULT.hdlResult =
                              ExaminedResultEnum.NoProblem;
                          }
                          if (
                            gradePhysicalSetting &&
                            gradePhysicalSetting.includes('3')
                          ) {
                            DEFAULT_BLOOD_RESULT.ldlResult =
                              ExaminedResultEnum.NoProblem;
                          }
                          if (
                            gradePhysicalSetting &&
                            gradePhysicalSetting.includes('4')
                          ) {
                            DEFAULT_BLOOD_RESULT.acResult =
                              ExaminedResultEnum.NoProblem;
                          }
                          if (
                            gradePhysicalSetting &&
                            gradePhysicalSetting.includes('5')
                          ) {
                            DEFAULT_BLOOD_RESULT.pcResult =
                              ExaminedResultEnum.NoProblem;
                          }
                          if (
                            gradePhysicalSetting &&
                            gradePhysicalSetting.includes('6')
                          ) {
                            DEFAULT_BLOOD_RESULT.hBeAgResult =
                              ExaminedResultEnum.NoProblem;
                          }
                          if (
                            gradePhysicalSetting &&
                            gradePhysicalSetting.includes('7')
                          ) {
                            DEFAULT_BLOOD_RESULT.bunResult =
                              ExaminedResultEnum.NoProblem;
                          }
                          if (
                            gradePhysicalSetting &&
                            gradePhysicalSetting.includes('8')
                          ) {
                            DEFAULT_BLOOD_RESULT.htResult =
                              ExaminedResultEnum.NoProblem;
                          }
                          form.reset(DEFAULT_BLOOD_RESULT);
                        } else if (!nextExamine) {
                          form.reset(NO_EXAMINED_BLOOD_RESULT);
                        }
                        return nextExamine;
                      });
                    }}
                  >
                    <Dropdown.Item eventKey={0}>否</Dropdown.Item>
                    <Dropdown.Item eventKey={1}>是</Dropdown.Item>
                  </DropdownButton>
                </Row>
                <div ref={divRef}>
                  <Row noGutters className="p-2 border">
                    {generateTestInput('hb', 'hbResult', divRef, !examined)}
                    {generateTestInput('wbc', 'wbcResult', divRef, !examined)}
                    {generateTestInput('rbc', 'rbcResult', divRef, !examined)}
                    {generateTestInput('pl', 'plResult', divRef, !examined)}
                    {generateTestInput('mcv', 'mcvResult', divRef, !examined)}
                    {generateTestInput('cl', 'clResult', divRef, !examined)}
                    {generateTestInput('cr', 'crResult', divRef, !examined)}
                    {generateTestInput('ua', 'uaResult', divRef, !examined)}
                    {generateTestInput('got', 'gotResult', divRef, !examined)}
                    {generateTestInput('gpt', 'gptResult', divRef, !examined)}
                    {generateTestInput(
                      'hbsa',
                      'hbsaResult',
                      divRef,
                      !examined,
                      BloodI18N.BloodHBsAgExaminationResult
                    )}
                    {generateTestInput(
                      'antiHB',
                      'antiHBResult',
                      divRef,
                      !examined,
                      BloodI18N.BloodAntiHBExaminationResult
                    )}
                  </Row>
                  <Row noGutters className="">
                    追加部分
                  </Row>
                  <Row noGutters className="p-2 border">
                    {generateTestInput('bun', 'bunResult', divRef, !examined)}
                    {generateTestInput('tg', 'tgResult', divRef, !examined)}
                    {generateTestInput('hdl', 'hdlResult', divRef, !examined)}
                    {generateTestInput('ldl', 'ldlResult', divRef, !examined)}
                    {generateTestInput('ht', 'htResult', divRef, !examined)}
                    {generateTestInput('ac', 'acResult', divRef, !examined)}
                    {generateTestInput('pc', 'pcResult', divRef, !examined)}
                    {generateTestInput(
                      'hBeAg',
                      'hBeAgResult',
                      divRef,
                      !examined,
                      BloodI18N.BloodHBeAgExaminationResult
                    )}
                  </Row>
                  <Row noGutters className="p-2 border">
                    <Col className="d-flex align-items-center my-1">
                      <div className="wid-80">備註: </div>
                      <TextField
                        property="bloodCheckMemo"
                        disabled={!examined}
                      />
                    </Col>
                  </Row>
                  <Row noGutters className="p-2">
                    <Button className="mx-2" onClick={() => handleSubmit()}>
                      <span className="feather icon-check mr-1" />
                      儲存
                    </Button>
                    <Button className="mx-2" variant="danger" onClick={onClose}>
                      <span className="feather icon-x  mr-1" />
                      取消
                    </Button>
                  </Row>
                </div>
              </>
            );
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

function generateTestInput(
  valueProperty: string,
  resultProperty: string,
  dropdownRef: RefObject<HTMLElement>,
  disabled: boolean,
  dropdownDisplayMap: Record<string, string> = I18N.ExaminationResult
) {
  return (
    <Col sm={6} md={4} className="d-flex align-items-center my-1">
      <InputDropdownGroupField
        className={`wid-40 mx-1`}
        property={resultProperty}
        content={dropdownDisplayMap}
        type="number"
        needGroup={false}
        disabled={disabled}
      />
      <div className="wid-120">{I18N.Blood[resultProperty]}判讀</div>
      <NumberField
        className="wid-80 mx-1"
        property={valueProperty}
        disabled={disabled}
      />
      <div
        className="wid-60"
        dangerouslySetInnerHTML={{ __html: I18N.BloodUnit[resultProperty] }}
      />
    </Col>
  );
}

function serializeBlood(blood?: PHIBlood): ReactNode[] {
  if (!blood) return [];

  const nodes = Object.entries(I18N.Blood).reduce((nodes, [key, display]) => {
    const result = (blood as any)[key];
    if (invalidStatus(result)) {
      nodes.push(
        <div key={key}>
          <span className="text-danger">*{display}:</span>
          {result}
        </div>
      );
    }

    return nodes;
  }, [] as ReactNode[]);

  if (blood.bloodCheckMemo) {
    nodes.push(
      <div key="bloodCheckMemo">
        <span className="text-danger">*備註：</span>
        {blood.bloodCheckMemo}
      </div>
    );
  }

  return nodes;
}

function invalidStatus(data: ExaminedResultEnum): boolean {
  return data === 1 || data === 2 || data === 3;
}

const NO_EXAMINED_BLOOD_RESULT: PHIBlood = {
  id: 0,
  year: 0,
  sem: 0,
  grade: 0,
  yearClassId: 0,
  hbResult: ExaminedResultEnum.NoExamined,
  wbcResult: ExaminedResultEnum.NoExamined,
  rbcResult: ExaminedResultEnum.NoExamined,
  plResult: ExaminedResultEnum.NoExamined,
  mcvResult: ExaminedResultEnum.NoExamined,
  htResult: ExaminedResultEnum.NoExamined,
  clResult: ExaminedResultEnum.NoExamined,
  crResult: ExaminedResultEnum.NoExamined,
  uaResult: ExaminedResultEnum.NoExamined,
  bunResult: ExaminedResultEnum.NoExamined,
  gotResult: ExaminedResultEnum.NoExamined,
  gptResult: ExaminedResultEnum.NoExamined,
  hbsaResult: ExaminedResultEnum.NoExamined,
  antiHBResult: ExaminedResultEnum.NoExamined,
  tgResult: ExaminedResultEnum.NoExamined,
  hdlResult: ExaminedResultEnum.NoExamined,
  ldlResult: ExaminedResultEnum.NoExamined,
  acResult: ExaminedResultEnum.NoExamined,
  pcResult: ExaminedResultEnum.NoExamined,
  hBeAgResult: ExaminedResultEnum.NoExamined,
};

const DEFAULT_BLOOD_RESULT: PHIBlood = {
  id: 0,
  year: 0,
  sem: 0,
  grade: 0,
  yearClassId: 0,
  hbResult: ExaminedResultEnum.NoProblem,
  wbcResult: ExaminedResultEnum.NoProblem,
  rbcResult: ExaminedResultEnum.NoProblem,
  plResult: ExaminedResultEnum.NoProblem,
  mcvResult: ExaminedResultEnum.NoProblem,
  clResult: ExaminedResultEnum.NoProblem,
  crResult: ExaminedResultEnum.NoProblem,
  uaResult: ExaminedResultEnum.NoProblem,
  gotResult: ExaminedResultEnum.NoProblem,
  gptResult: ExaminedResultEnum.NoProblem,
  hbsaResult: ExaminedResultEnum.NoProblem,
  antiHBResult: ExaminedResultEnum.NoProblem,
  bunResult: ExaminedResultEnum.NoExamined,
  tgResult: ExaminedResultEnum.NoExamined,
  hdlResult: ExaminedResultEnum.NoExamined,
  ldlResult: ExaminedResultEnum.NoExamined,
  htResult: ExaminedResultEnum.NoExamined,
  acResult: ExaminedResultEnum.NoExamined,
  pcResult: ExaminedResultEnum.NoExamined,
  hBeAgResult: ExaminedResultEnum.NoExamined,
};

const BloodI18N = {
  BloodHBsAgExaminationResult: {
    0: '無反應',
    1: '有反應',
    2: '複診無反應',
    3: '複診有反應',
    9: '未受檢',
  } as Record<number, string>,
  BloodHBeAgExaminationResult: {
    0: '陰性',
    1: '陽性',
    2: '複診陰性',
    3: '複診陽性',
    9: '未受檢',
  } as Record<number, string>,
  BloodAntiHBExaminationResult: {
    0: '無反應',
    1: '有反應',
    2: '複診無反應',
    3: '複診有反應',
    9: '未受檢',
  } as Record<number, string>,
};
