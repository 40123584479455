import { DateTime } from 'luxon';
import {
  TransferInfo,
  TransferInfoZod,
  TransferListData,
  TransferListDataZod,
} from '../model';
import { delet, get, post } from './BaseApi';
import { z } from 'zod';

export async function getTransferOutInfo(
  studentId: string
): Promise<TransferInfo> {
  return await get(`/api/transfer/student/${studentId}`, TransferInfoZod);
}

export async function getTransferInInfo(
  studentId: string,
  birth: DateTime
): Promise<TransferInfo> {
  return await get(`/api/transfer/student/${studentId}/data`, TransferInfoZod, {
    birth: birth.toISO(),
  });
}

export async function transferOut(studentId: string): Promise<void> {
  await post(`/api/transfer/student/${studentId}/out`);
}

export async function deleteStudent(
  studentId: string,
  type: number
): Promise<void> {
  await post(`/api/transfer/student/${studentId}/delete/${type}`);
}

export async function transferIn(
  transferId: number,
  data: {
    sid: string;
    seat: number;
    year: number;
    sem: number;
    classGrade: number;
    classNo: number;
  }
): Promise<void> {
  await post(`/api/transfer/${transferId}/in`, data);
}

export async function getTransferList(): Promise<TransferListData[]> {
  return await get(`/api/transfer/students`, z.array(TransferListDataZod));
}

export async function RequestTransferOut(
  pid: string,
  birth: DateTime
): Promise<void> {
  await post('/api/transfer/out/request', { pid, birth: birth.toISO() });
}
