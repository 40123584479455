import { z } from 'zod';
import {
  PHIWH,
  PHISight,
  PHITee,
  transformBodyMetricToPrimitive,
  transfromDateTimeToPrimitive,
  StudentPHIAllZod,
  StudentPHIAll,
  Student,
  StudentZod,
  PHIPhysical,
  StudentInjury,
  StudentInjuryZod,
  StudentHealthInfo,
  StudentHealthInfoZod,
  StudentWithClassGradeZod,
  StudentWithClassGrade,
  PHIBlood,
  PHIXRay,
  PHIUrine,
  ExtractedPHIParasite,
  PHIWHZod,
  StudentGradeNoSeatYearSemZod,
  ExtractedPHIUrine,
  StudentPHIWHSight,
  PHIWHSight,
} from '../model';
import { delet, get, post, put } from './BaseApi';

const PHIWHListZod = z.array(PHIWHZod);
type PHIWHList = z.infer<typeof PHIWHListZod>;
const StudentGradeNoSeatYearSemListZod = z.array(StudentGradeNoSeatYearSemZod);
type StudentGradeNoSeatYearSemList = z.infer<
  typeof StudentGradeNoSeatYearSemListZod
>;

export async function InsertStudentBodyMetrix(
  id: string,
  metric: PHIWH
): Promise<void> {
  await put(
    `/api/student/${id}/metric`,
    transformBodyMetricToPrimitive(metric)
  );
}

export async function InsertStudentSight(
  id: string,
  sight: PHISight
): Promise<void> {
  await put(
    `/api/student/${id}/phi/sight`,
    transfromDateTimeToPrimitive(sight)
  );
}
export async function InsertStudentWHSight(
  id: string,
  sight: PHIWHSight
): Promise<void> {
  await put(
    `/api/student/${id}/phi/whsight`,
    transfromDateTimeToPrimitive(sight)
  );
}
export async function InsertStudentTee(id: string, tee: PHITee): Promise<void> {
  await put(`/api/student/${id}/phi/tee`, transfromDateTimeToPrimitive(tee));
}

export async function InsertStudentPhysical(
  id: string,
  physical: PHIPhysical
): Promise<void> {
  await put(
    `/api/student/${id}/phi/physical`,
    transfromDateTimeToPrimitive(physical)
  );
}

export async function InsertStudentBlood(
  id: string,
  blood: PHIBlood
): Promise<void> {
  await put(
    `/api/student/${id}/phi/blood`,
    transfromDateTimeToPrimitive(blood)
  );
}
export async function InsertStudentXRay(
  id: string,
  xray: PHIXRay
): Promise<void> {
  await put(`/api/student/${id}/phi/xray`, transfromDateTimeToPrimitive(xray));
}
export async function InsertStudentUrine(
  id: string,
  urine: ExtractedPHIUrine
): Promise<void> {
  await put(
    `/api/student/${id}/phi/urine`,
    transfromDateTimeToPrimitive(urine)
  );
}
export async function InsertStudentParasite(
  id: string,
  parasite: ExtractedPHIParasite
): Promise<void> {
  await put(
    `/api/student/${id}/phi/parasite`,
    transfromDateTimeToPrimitive(parasite)
  );
}

export async function GetStudentWithPHIAll(
  id: string,
  graduatedYear?: number
): Promise<StudentPHIAll> {
  return graduatedYear
    ? await get(`/api/student/${id}/phi`, StudentPHIAllZod, { graduatedYear })
    : await get(`/api/student/${id}/phi`, StudentPHIAllZod);
}

export async function GetBodyMatrixHistory(
  id: string,
  year: number,
  grade: number
): Promise<PHIWHList> {
  return await get(`/api/student/${id}/phi/wh/history`, PHIWHListZod, {
    year,
    grade,
  });
}

export async function GetStudent(
  id: string,
  year?: number,
  sem?: number
): Promise<Student> {
  return year || sem
    ? await get(`/api/student/${id}`, StudentZod, { year, sem })
    : await get(`/api/student/${id}`, StudentZod);
}

export async function GetStudentBySid(sid: string): Promise<Student> {
  return await get(`/api/student/sid/${sid}`, StudentZod);
}

export async function GetStudentWithClass(
  id: string,
  year: number,
  sem: number
): Promise<StudentWithClassGrade> {
  return await get(`/api/student/${id}/withclass`, StudentWithClassGradeZod, {
    year,
    sem,
  });
}

export async function SetStudent(
  student: Student,
  year?: number,
  sem?: number
): Promise<void> {
  year || sem
    ? await post(`/api/student/${student.pid}`, student, { year, sem })
    : await post(`/api/student/${student.pid}`, student);
}

export async function SetStudentRemark(
  pid: string,
  remark: string,
  year?: number,
  sem?: number
): Promise<void> {
  year || sem
    ? await post(`/api/student/${pid}/remark/update`, { remark }, { year, sem })
    : await post(`/api/student/${pid}/remark/update`, { remark });
}

export async function CreateStudent(
  student: Student,
  year?: number,
  sem?: number,
  grade?: number,
  no?: number,
  className?: string,
  classId?: string
): Promise<void> {
  year || sem
    ? await post(`/api/student/${student.pid}/create`, student, {
        year,
        sem,
        grade,
        no,
        className,
        classId,
      })
    : await post(`/api/student/${student.pid}/create`, student);
}

const StudentInjuriesZod = z.array(StudentInjuryZod);
export async function GetStudentInjuries(
  pid: string
): Promise<StudentInjury[]> {
  return await get(`/api/student/${pid}/injuries`, StudentInjuriesZod);
}

export async function InsertStudentInjuries(
  pid: string,
  injury: StudentInjury
): Promise<void> {
  await put(`/api/student/${pid}/injuries`, injury);
}

export async function UpdateStudentInjuries(
  pid: string,
  injury: StudentInjury
): Promise<void> {
  await post(`/api/student/${pid}/injuries`, injury);
}

export async function RemoveStudentInjuries(
  pid: string,
  injuryId: number
): Promise<void> {
  await delet(`/api/student/${pid}/injuries/${injuryId}`);
}

export async function GetHealthInfo(
  studentId: string
): Promise<StudentHealthInfo> {
  return await get(
    `/api/student/${studentId}/health-info`,
    StudentHealthInfoZod
  );
}

export async function deleteStudentWH(
  pid: string,
  year: number,
  sem: number
): Promise<void> {
  await delet(`/api/student/${pid}/phi/wh`, {}, { year, sem });
}

export async function deleteStudentSight(
  pid: string,
  year: number,
  sem: number
): Promise<void> {
  await delet(`/api/student/${pid}/phi/sight`, {}, { year, sem });
}

export async function deleteStudentWHSight(
  pid: string,
  year: number,
  sem: number
): Promise<void> {
  await delet(`/api/student/${pid}/phi/whsight`, {}, { year, sem });
}

export async function deleteStudentTee(
  pid: string,
  year: number,
  sem: number
): Promise<void> {
  await delet(`/api/student/${pid}/phi/tee`, {}, { year, sem });
}

export async function deleteStudentPhysical(
  pid: string,
  year: number,
  sem: number
): Promise<void> {
  await delet(`/api/student/${pid}/phi/physical`, {}, { year, sem });
}

export async function deleteStudentBlood(
  pid: string,
  year: number,
  sem: number
): Promise<void> {
  await delet(`/api/student/${pid}/phi/blood`, {}, { year, sem });
}

export async function deleteStudentXRay(
  pid: string,
  year: number,
  sem: number
): Promise<void> {
  await delet(`/api/student/${pid}/phi/xray`, {}, { year, sem });
}

export async function deleteStudentParasite(
  pid: string,
  year: number,
  sem: number
): Promise<void> {
  await delet(`/api/student/${pid}/phi/parasite`, {}, { year, sem });
}

export async function deleteStudentUrine(
  pid: string,
  year: number,
  sem: number
): Promise<void> {
  await delet(`/api/student/${pid}/phi/urine`, {}, { year, sem });
}

export async function getStudentClasses(
  pid: string,
  year: number
): Promise<StudentGradeNoSeatYearSemList> {
  return await get(
    `/api/student/${pid}/classes`,
    StudentGradeNoSeatYearSemListZod,
    { year }
  );
}
