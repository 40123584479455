import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type Props = {
  children: ReactElement;
  tooltip: ReactNode;
};

export const HoverToolTip: FunctionComponent<Props> = ({
  children,
  tooltip,
}) => {
  return (
    <React.Fragment>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        overlay={<Tooltip id="overflow-tooltip">{tooltip}</Tooltip>}
      >
        {children}
      </OverlayTrigger>
    </React.Fragment>
  );
};
