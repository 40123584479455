import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Button, Col, FormCheck, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  SheetTemplate,
} from '../../../components';
import { useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeat } from '../../../model';
import apis from '../../../apis';
import {
  DefaultSightManageMap,
  SightContext,
} from '../../TableHeaders/SightHeader';
import { SheetHeaderDisplay, downloadDataAsExcel } from '../../../utils';
import { sightDiag } from '../../../utils/sight';
import { DateTime } from 'luxon';

type PageData = StudentGradeNoSeat & Sight & { diag: string };

function boolRender(v: unknown) {
  return v ? '是' : '否';
}

const tableHeader: (SheetHeaderDisplay<PageData> &
  HeaderDisplayKeysWithType<PageData>)[] = [
    { display: '統編', property: 'pid' },
    { display: '年級', property: 'grade' },
    { display: '班級', property: 'no' },
    { display: '座號', property: 'seat' },
    { display: '學生', property: 'name' },
    {
      display: '性別',
      property: 'sex',
      onRender: (v) => (v === '1' ? '男' : '女'),
      onSheetRender: (v) => (v === '1' ? '男' : '女'),
    },
    {
      display: '散瞳治療',
      property: 'isDilated',
      onRender: boolRender,
      onSheetRender: boolRender,
    },
    {
      display: '裸視右',
      property: 'sight0R',
      onRender: (value: unknown) => {
        if (typeof value === 'number') {
          return value % 1 === 0 ? value.toFixed(1) : value.toString();
        }
        return value as string;
      },
      onSheetRender: (value: unknown) => {
        if (typeof value === 'number') {
          return value % 1 === 0 ? value.toFixed(1) : value.toString();
        }
        return value as string;
      }
    },
    {
      display: '裸視左',
      property: 'sight0L',
      onRender: (value: unknown) => {
        if (typeof value === 'number') {
          return value % 1 === 0 ? value.toFixed(1) : value.toString();
        }
        return value as string;
      },
      onSheetRender: (value: unknown) => {
        if (typeof value === 'number') {
          return value % 1 === 0 ? value.toFixed(1) : value.toString();
        }
        return value as string;
      }
    },
    {
      display: '戴鏡右',
      property: 'sightR',
      onRender: (value: unknown) => {
        if (typeof value === 'number') {
          return value % 1 === 0 ? value.toFixed(1) : value.toString();
        }
        return value as string;
      },
      onSheetRender: (value: unknown) => {
        if (typeof value === 'number') {
          return value % 1 === 0 ? value.toFixed(1) : value.toString();
        }
        return value as string;
      }
    },
    {
      display: '戴鏡左',
      property: 'sightL',
      onRender: (value: unknown) => {
        if (typeof value === 'number') {
          return value % 1 === 0 ? value.toFixed(1) : value.toString();
        }
        return value as string;
      },
      onSheetRender: (value: unknown) => {
        if (typeof value === 'number') {
          return value % 1 === 0 ? value.toFixed(1) : value.toString();
        }
        return value as string;
      }
    },
    {
      display: '散瞳',
      property: 'isDilating',
      onRender: boolRender,
      onSheetRender: boolRender,
    },
    { display: '近視右', property: 'eNearR' },
    { display: '近視左', property: 'eNearL' },
    { display: '遠視右', property: 'eFarR' },
    { display: '遠視左', property: 'eFarL' },
    { display: '散光右', property: 'eSanR' },
    { display: '散光左', property: 'eSanL' },
    { display: '屈光右', property: 'diopR' },
    { display: '屈光左', property: 'diopL' },
    { display: '診斷', property: 'diag' },
    { display: '備註', property: 'eSight99State' },
    {
      display: '處置代號',
      property: 'manageID',
      onRender: (v) => (Array.isArray(v) ? v.join(',') : v),
      onSheetRender: (v) => (Array.isArray(v) ? v.join(',') : (v as string)),
    },
    { display: '醫師建議處置', property: 'manage' },
    {
      display: '定期檢查', property: 'periodical',
      onRender: (v) => {
        return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
      },
      onSheetRender: (v) => {
        return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : ('value' as string);
      },
    },
  ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const sight0RankNotice: FunctionComponent<Props> = ({
  user,
  catchErrorForModal,
}) => {
  const { yearSem, element } = useSemGrade();
  const [students, setStudents] = useState<PageData[]>([]);
  const [rank, setRank] = useState({
    best: false, // 1
    good: false, // 2
    normal: false, // 3
    poor: false, //4
  });

  useEffect(() => {
    const sight0Rank = [rank.best, rank.good, rank.normal, rank.poor]
      .map((s, i) => ({ s, i: i + 1 }))
      .filter(({ s, i }) => s)
      .map(({ s, i }) => i);
    if (yearSem) {
      apis
        .getSight0Rank(
          yearSem.year,
          yearSem.sem,
          sight0Rank.length ? sight0Rank : undefined
        )
        .then((r) =>
          setStudents(
            r.map(({ sight, ...s }) => ({
              ...sight,
              ...s,
              diag: sightDiag(sight),
            }))
          )
        )
        .catch(catchErrorForModal);
    }
  }, [
    yearSem?.year,
    yearSem?.sem,
    rank.best,
    rank.good,
    rank.normal,
    rank.poor,
  ]);

  return (
    <AuthedLayout>
      <Row className="justify-content-between">
        <Col>
          <Row>
            <span className="mr-3">{element}</span>
            <FormCheck
              className="mr-3"
              type="checkbox"
              label="視力正常"
              checked={rank.best}
              onChange={() => {
                setRank({ ...rank, best: !rank.best });
              }}
            />
            <FormCheck
              className="mr-3"
              type="checkbox"
              label="0.5-0.8"
              checked={rank.good}
              onChange={() => {
                setRank({ ...rank, good: !rank.good });
              }}
            />
            <FormCheck
              className="mr-3"
              type="checkbox"
              label="0.1-0.4"
              checked={rank.normal}
              onChange={() => {
                setRank({ ...rank, normal: !rank.normal });
              }}
            />
            <FormCheck
              className="mr-3"
              type="checkbox"
              label="<0.1"
              checked={rank.poor}
              onChange={() => {
                setRank({ ...rank, poor: !rank.poor });
              }}
            />
          </Row>
        </Col>
        <Button
          disabled={!students.length}
          variant="success"
          className="text-dark"
          onClick={() => {
            downloadDataAsExcel({
              title: `${yearSem?.year}學年${yearSem?.sem}學期_裸眼視力不良但未有診斷名單`,
              values: students,
              headers: tableHeader,
              context: SightContext,
              footer:
                '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
            });
          }}
        >
          Excel 下載
        </Button>
      </Row>
      <hr />
      <Row>
        <EditableTableDiv
          headers={tableHeader}
          values={students}
          context={SightContext}
        />
      </Row>
    </AuthedLayout>
  );
};

export const Sight0RankNotice = connector(sight0RankNotice);
