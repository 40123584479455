import React, {
  FunctionComponent,
  useState,
  ReactNode,
  useEffect,
  ChangeEvent,
} from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import apis from '../../apis';
import {
  HeaderDisplayKeysWithType,
  InlineEditableTable,
  TextField,
  OverflowWithTooltip,
} from '../../components';
import {
  PHIBodyMindBookN,
  Semester,
  Student,
  YearSemClasses,
} from '../../model';
import { InputDropdownGroup, InputDropdownGroupField } from '../../components';
import { I18N } from '../../i18n-raw';
import { toast } from 'react-toastify';

type Props = {
  editable: boolean;
  deleteable: boolean;
  bodyMindBookN: PHIBodyMindBookN[];
  student: Student;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
  onValueDelete?: () => void;
};
const transformInputDropdownField = (
  property: string,
  content: Record<number, string>,
  v: unknown,
  i: number,
  e?: boolean
) =>
  e ? (
    <InputDropdownGroupField
      type="number"
      content={content}
      property={property}
    />
  ) : (
    (v as string)
  );
const StringMap1 = {
  1: '輕度',
  2: '中度',
  3: '極重度',
};

const inlineDiseaseTableHeader: HeaderDisplayKeysWithType<PHIBodyMindBookN>[] =
  [
    { property: 'studentId', display: '統編' },
    { property: 'bodyMindId', display: '診斷代號' },
    { property: 'bodyMindName', display: '疾病名稱' },
    {
      property: 'degree',
      display: '等級',
      onRender: transformInputDropdownField.bind(null, 'degree', StringMap1),
    },
  ];

export const BodyMindBookNSection: FunctionComponent<Props> = ({
  editable,
  deleteable,
  bodyMindBookN,
  student,
  semesters,
  yearSems,
  onApiError,
  onValueUpdated,
}) => {
  const currentSemester = semesters.find((s) => s.isNow);
  const [inputing, setInputing] = useState(false);
  const [bodyMindKindItems, setBodyMindKindItems] = useState<object>();
  const [bodyMindKindDropDownItems, setBodyMindKindDropDownItems] =
    useState<Record<number, string>>();
  const [bodyMindKindDropDown, setBodyMindKindDropDown] = useState<number>();
  const [degreeDropDown, setDegreeDropDown] = useState<number>();
  const [isDiseaseValid, setIsDiseaseValid] = useState<boolean>(false);
  const [insertBtn, setInsertBtn] = useState<boolean>(true);
  const [year, setYear] = useState<number>();
  const [sem, setSem] = useState<number>();
  const wh = bodyMindBookN.find(
    (s) => s.sem === currentSemester?.sem && s.year === currentSemester?.year
  );

  useEffect(() => {
    // 目前學期、學年
    const isNow = semesters.filter((y) => y.isNow == true)[0];
    setYear(isNow.year);
    setSem(isNow.sem);
    // 重大傷病下拉選單
    const results = I18N.BodyMindBooknType;
    setBodyMindKindDropDownItems(results);
  }, [semesters]);

  useEffect(() => {
    setInsertBtn(false);
  }, [bodyMindKindDropDown]);

  useEffect(() => {
    // 檢查疾病代號
    setIsDiseaseValid(false);
    if (bodyMindKindDropDown) {
      if(I18N.BodyMindBooknType[bodyMindKindDropDown]) {
        setIsDiseaseValid(true);
      }
    }
  }, [bodyMindKindDropDown]);

  yearSems.filter(
    (s) => !bodyMindBookN.some((wh) => wh.sem === s.sem && wh.year === s.year)
  );

  bodyMindBookN.sort((a, b) => a.sem - b.sem + (a.year - b.year) * 100);

  function onValueUpdate(value: PHIBodyMindBookN) {
    apis
      .updateStudentBodyMindN(
        value?.id,
        value.studentId,
        value?.bodyMindId,
        value?.bodyMindName,
        value?.degree
      )
      .then(onValueUpdated)
      .catch(onApiError);
  }

  function onValueDelete(value: PHIBodyMindBookN) {
    apis
      .deleteStudentDeleteBodyMinds(
        value?.id,
        value.studentId,
        value?.bodyMindId,
        value?.bodyMindName,
        value?.degree
      )
      .then(onValueUpdated)
      .catch(onApiError);
  }

  function insertBodyMindBookNCheck() {
    // 檢查診斷代號
    if (isDiseaseValid) {
      if (degreeDropDown) {
        insertBodyMindBookN();
      } else {
        toast.error('請輸入等級!', {});
      }
    } else {
      toast.error('診斷代號錯誤!', {});
    }
  }

  function insertBodyMindBookN() {
    if (bodyMindKindDropDown) {
      // 送出api
      if (year && sem) {
        toast
          .promise(
            apis.insertBodyMinds(
              year,
              sem,
              '',
              0,
              0,
              0,
              student.pid,
              bodyMindKindDropDown,
              degreeDropDown || 1,
              1
            ),
            {
              pending: '資料新增中......',
              success: '新增成功！',
            }
          )
          .then(onValueUpdated)
          .catch(onApiError);
      }
    }
  }

  return (
    <>
      <div>
        身心障礙手冊新制
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            身心障礙手冊新制 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <InlineEditableTable
            values={bodyMindBookN}
            headers={inlineDiseaseTableHeader}
            onValueUpdate={onValueUpdate}
            onValueDelete={onValueDelete}
            onRowRender={(v) => ({
              editable:
                editable,
              deleteable:
                deleteable,
            })}
          />
          <Row className='px-2'>
            類別代號：
            <Col sm={3}>
              <InputDropdownGroup
                className={'p-1 text-center mr-2'}
                name="diseaseId"
                type="number"
                options={bodyMindKindDropDownItems || {}}
                defaultShow="無異常"
                value={bodyMindKindDropDown || ''}
                onChange={(
                  e: ChangeEvent<
                    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                  >
                ) => {
                  setBodyMindKindDropDown(parseInt(e.target.value));
                }}
                onBlur={() => {}}
                onFocus={() => {}}
              />
            </Col>
            等級：
            <Col sm={3}>
              <InputDropdownGroup
                className={'p-1 text-center mr-2'}
                name="degree"
                type="number"
                options={{
                  1: '輕度',
                  2: '中度',
                  3: '極重度',
                }}
                defaultShow="無異常"
                value={degreeDropDown}
                onChange={(
                  e: ChangeEvent<
                    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                  >
                ) => {
                  setDegreeDropDown(parseInt(e.target.value));
                }}
                onBlur={() => {}}
                onFocus={() => {}}
              />
            </Col>
            <Button disabled={insertBtn} onClick={insertBodyMindBookNCheck}>
              新增
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
