import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import {
  Button,
  Col,
  FormCheck,
  FormControl,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import { connect, ConnectedProps } from 'react-redux';
import apis from '../../apis';
import {
  AuthedLayout,
  NumberField,
  TextField,
  InputTransformField,
  DateTimePicker,
} from '../../components';
import { Student, TransferInfo } from '../../model';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ApplicationState } from '../../redux/States';
import { getGradeMinMax } from '../../utils';
import { I18N } from '../../i18n-raw';
import { DateTime } from 'luxon';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { toast } from 'react-toastify';

const mapState = (state: ApplicationState) => ({
  ...state.auth,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const transferInPage: FunctionComponent<Props> = ({
  user: { currentRank, currentSemester, semesters },
  catchErrorForModal,
}) => {
  const [transferInfo, setTransfrInfo] = useState<TransferInfo>();
  const [student, setStudent] = useState<Student>();
  const [modalContent, setModalContent] = useState<string>();
  const [selectedSem, setSelectedSem] = useState<string>(currentSemester);
  const [birth, setBirth] = useState<DateTime>(); // 學生生日
  const [transferDisabled, setTransferDisabled] = useState<boolean>(true);

  const ref = useRef<HTMLInputElement>(null);

  const studentContent = student ? (
    <>
      <Table bordered className="student-phi">
        <tbody>
          <tr>
            <th>身份證</th>
            <td>{student.pid}</td>
          </tr>
          <tr>
            <th>學生</th>
            <td>{student.name}</td>
          </tr>
          <tr>
            <th>性別</th>
            <td>{student.sex}</td>
          </tr>
          <tr>
            <th>生日</th>
            <td>{student.birth?.toFormat('yyyy/MM/dd')}</td>
          </tr>
          <tr>
            <th>父親</th>
            <td>{student.dad}</td>
          </tr>
          <tr>
            <th>母親</th>
            <td>{student.mom}</td>
          </tr>
          <tr>
            <th>連絡電話</th>
            <td>{student.tel}</td>
          </tr>
          <tr>
            <th>緊急連絡</th>
            <td>{student.erTel}</td>
          </tr>
          <tr>
            <th>身份</th>
            <td>{student.aborigine}</td>
          </tr>
        </tbody>
      </Table>
    </>
  ) : (
    <></>
  );

  const { min, max } = getGradeMinMax(currentRank);
  const classGradeContent = transferInfo ? (
    <>
      <hr />
      <Form
        onSubmit={({ sid, seat, classGrade, classNo }) => {
          const { year, sem } = semesters[selectedSem];
          apis
            .transferIn(transferInfo.transferId, {
              sid: sid ?? ' ',
              seat,
              classGrade: +classGrade,
              classNo,
              year,
              sem,
            })
            .then(() => {
              setStudent(undefined);
              setTransfrInfo(undefined);
              setModalContent('轉入成功');
            })
            .catch((e) => {
              catchErrorForModal(e);
            });
        }}
        render={(props) => {
          const { handleSubmit } = props;
          return (
            <>
              <Row className="mb-2">
                <Col xs={1}>轉入學年：</Col>
                {Object.entries(semesters)
                  .filter(([k]) => k >= currentSemester)
                  .map(([n]) => {
                    return (
                      <Col key={`sem-${n}`} xs={1}>
                        <FormCheck
                          type="radio"
                          label={n}
                          name="setSemester"
                          checked={n === selectedSem}
                          onChange={(e) => {
                            setSelectedSem(n);
                          }}
                        />
                      </Col>
                    );
                  })}
              </Row>
              <Row className="mb-2">
                <Col xs={1}>轉入學號:</Col>
                <Col xs={5} className="mr-2">
                  <TextField property="sid" />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={1}>轉入年級:</Col>
                <Col xs={4} className="mr-2">
                  <InputTransformField
                    property="classGrade"
                    type="number"
                    min={min}
                    max={max}
                    transform={(v) =>
                      (+v >= min && +v <= max && I18N.Grades[+v]) || '未知年級'
                    }
                    validate={(v) => {
                      return +v >= min && +v <= max
                        ? undefined
                        : `範圍應在${min}~${max}之間`;
                    }}
                  />
                </Col>
                <Col xs={1}>轉入班級:</Col>
                <Col xs={2} className="mr-2">
                  <NumberField property="classNo" min={1} max={50} />
                </Col>
                <Col xs={1}>班級座號:</Col>
                <Col xs={2} className="mr-2">
                  <NumberField property="seat" min={1} max={99} />
                </Col>
              </Row>
              <Row className="mb-2">
                <Button onClick={handleSubmit}>轉入</Button>
              </Row>
            </>
          );
        }}
      />
    </>
  ) : (
    <></>
  );

  useEffect(() => {
    setTransferDisabled(true);
    if (ref.current?.value) {
      if (birth) {
        setTransferDisabled(false);
      }
    }
  }, [ref.current?.value, birth]);

  return (
    <AuthedLayout>
      <Row className="mb-2">
        <div className="d-flex align-items-center">
          學生身份證：
          <FormControl ref={ref} className="mr-2 wid-140" />
          學生生日：
          <DateTimePicker
            containerClass="wid-140 mr-2"
            format="YYYY/MM/DD"
            value={birth}
            onChange={(date) => {
              setBirth(date);
            }}
          />
          <Button
            className="mr-2"
            disabled={transferDisabled}
            onClick={() => {
              if (ref.current && ref.current?.value.length > 5)
                if (birth) {
                  apis
                    .getTransferInInfo(ref.current?.value, birth)
                    .then((t) => {
                      setTransfrInfo(t);
                      setStudent(t.student || undefined);
                    })
                    .catch((e) => {
                      setTransfrInfo(undefined);
                      setStudent(undefined);
                      catchErrorForModal(e);
                    });
                }
            }}
          >
            搜尋
          </Button>
          <Button
            variant="secondary"
            disabled={!(ref.current?.value && birth)}
            onClick={() => {
              if (ref.current && ref.current?.value.length > 5)
                if (birth) {
                  const localStorageKey = 'lastTransferRequest';
                  const lastClickDate =
                    localStorage.getItem(localStorageKey) ?? '';
                  if (lastClickDate !== DateTime.now().toISODate())
                    toast
                      .promise(
                        apis.RequestTransferOut(ref.current?.value, birth),

                        {
                          success: '轉出申請通知函已寄出',
                          error: '轉出申請通知函失敗！請查看錯誤資訊。',
                          pending: '轉出申請通知函寄出中....',
                        }
                      )
                      .then(() => {
                        localStorage.setItem(
                          localStorageKey,
                          DateTime.now().toISODate()
                        );
                      })
                      .catch(catchErrorForModal);
                  else {
                    toast.error(`距離上次通知函寄出時間過短, 請於隔日再試。`);
                  }
                }
            }}
          >
            寄送轉出申請通知函
          </Button>
        </div>
      </Row>
      {classGradeContent}
      <hr />
      <Row className="mb-2">
        <Col>{studentContent}</Col>
      </Row>
      <Modal
        show={!!modalContent}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header
          closeButton
          onHide={() => {
            setStudent(undefined);
            setTransfrInfo(undefined);
            setModalContent(undefined);
            if (ref.current) ref.current.value = '';
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            轉學生 - 轉入
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{modalContent}</Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setStudent(undefined);
              setTransfrInfo(undefined);
              setModalContent(undefined);
              if (ref.current) ref.current.value = '';
            }}
          >
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </AuthedLayout>
  );
};

export const TransferInPage = connector(transferInPage);
