import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Form as FormInput,
  InputGroup,
  FormCheck,
} from 'react-bootstrap';

import { CheckPid, CheckCode, GenNonPID } from '../utils/checkFormat';

import {
  DateTimeField,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  InputDropdownGroupField,
  InputTransformField,
  NumberField,
  TextField,
  // AuthedLayout,
} from '../components';

import { WrapClassBaseLayout, ClassSem } from '../layouts';
import { Student } from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../redux/States';
import { DateTime } from 'luxon';
import { Form } from 'react-final-form';
import apis from '../apis';
import { listToObject } from '../utils';
import { toast } from 'react-toastify';
import { getGradeMinMax } from '../utils';
import { I18N } from '../i18n-raw';

const headerDisplayKeys: HeaderDisplayKeysWithType<Student>[] = [
  { display: '統編', property: 'pid' },
  { display: '學生', property: 'name' },
  { display: '學號', property: 'sid' },
  { display: '座號', property: 'seat' },
  { display: '性別', property: 'sex' },
  {
    display: '生日',
    property: 'birth',
    onRender: (v) => (v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null),
  },
  { display: '血型', property: 'blood' },
  { display: '身分', property: 'aborigine' },
  { display: '父親', property: 'dad' },
  { display: '母親', property: 'mom' },
  { display: '監護人', property: 'guardian' },
  { display: '家中電話', property: 'tel' },
  { display: '郵區', property: 'zip' },
  { display: '住址', property: 'address' },
  { display: '緊急聯絡電話', property: 'erTel' },
];

const mapStates = (state: ApplicationState, ownProps: ClassSem) => ({
  ...ownProps,
  ...state.auth,
});
const mapDispatches = ErrorDispatches;
const connector = connect(mapStates, mapDispatches);
type Props = ConnectedProps<typeof connector>;

const basicClassStudent: FunctionComponent<Props> = ({
  classId,
  className,
  year,
  sem,
  grade,
  no,
  user,
  catchErrorForModal,
}) => {
  const [students, setStudent] = useState([] as Student[]);
  const [studentPid, setStudentPid] = useState<string>('');
  const [editing, setEditing] = useState(false);
  const [editingStudent, setEditingStudent] = useState<Student>();
  const [deleting, setDeleting] = useState(false);
  const [deletingStudent, setDeletingStudent] = useState<Student>();
  const [deleteType, setDeleteType] = useState<number>(5); // 預設休學
  const [deleteTypeName, setDeleteTypeName] = useState<string>('刪除'); // 預設
  const [inputingPid, setInputingPid] = useState(false);
  const [creating, setCreating] = useState(false);
  const [creatingStudent, setCreatingStudent] = useState<Student>();
  const [zipCodes, setZipCodes] = useState<Record<string, string>>({});
  const [showInsertBtn, setShowInsertBtn] = useState<boolean>(false);
  const [noPid, setNoPid] = useState<boolean>(false);
  const [pidInputRefDisabled, setPidInputRefDisabled] =
    useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>();
  const pidInputRef = useRef<HTMLInputElement>(null);
  const noPidOrPermit = useRef<HTMLInputElement>(null);
  const currentSchool = user.currentSchool;
  const { min, max } = getGradeMinMax(user.currentRank);

  useEffect(() => {
    apis.getZipCodes().then((s) =>
      setZipCodes(
        listToObject(
          s,
          (s) => `${s.zip}`,
          (s) => `${s.county}${s.town}`
        )
      )
    );
  }, []);

  useEffect(() => {
    if (editing === false) onClassChanged();
  }, [classId, year, sem, editing]);

  function onClassChanged() {
    if (classId && year && sem) {
      // 取得學生
      apis
        .getClassStudents(classId, year, sem)
        .then((ss) => {
          setStudent(ss);
        })
        .catch(catchErrorForModal);
    } else {
      setStudent([]);
    }
  }

  function onCreatingHide() {
    setCreating(false);
    setCreatingStudent(undefined);
  }

  async function onCreating() {
    if (pidInputRef.current?.value) {
      // 若無勾選無身份證字號或居留證號 直接下一步
      if (!noPidOrPermit.current?.checked) {
        // 檢查身分證格式
        const checkPidResult = CheckPid(pidInputRef.current?.value, true);
        const _pid = checkPidResult.pid;
        if (!checkPidResult.okUpload) {
          toast.error(checkPidResult.errorMsg);
          return;
        }

        // 檢查身分證有無重複
        const checkRepeatStudentResult = await CheckRepeatStudent(
          pidInputRef.current?.value
        );
        if (checkRepeatStudentResult.grade) {
          toast.error(
            `身分證重複:${checkRepeatStudentResult.grade}年${checkRepeatStudentResult.no}班${checkRepeatStudentResult.seat}號學生`
          );
          return;
        }
      }
      onInputingHide(); // 關閉輸入身分證或居留證Modal
      setCreating(true); // 顯示新增學生Modal
    }
    // setCreatingStudent(s);
  }

  async function CheckRepeatStudent(pid: string) {
    try {
      const s = await apis.GetStudentWithClass(pid, year, sem);
      const { classGrade: grade, classNo: no, seat } = s;
      return { grade, no, seat };
    } catch (error) {
      return { grade: 0, no: 0, seat: 0 };
    }
  }

  function onInputingHide() {
    setInputingPid(false);
    setPidInputRefDisabled(false); // 解除鎖定pidInputRef
  }

  function onInputing() {
    setInputingPid(true);
    setStudentPid(''); // 清空StudentPid
  }


  // 檢查身分證格式
  function checkPidIsValid(student: Student) {
    let okUpload = false;
    // 若無勾選無身份證字號或居留證號 直接下一步
    if (!noPid) {
      // 1.檢查性別格式是否正確
      // Util.cs
      // ROCIDSex
      const checkSexRes = CheckCode(
        student.sex,
        ['1', '2','男', '女'],
        '性別'
      );
      let _sex = '';
      let sexCode = '';
      if (!checkSexRes.okUpload) {
        toast.error(checkSexRes.errorMsg);
      } else {
        _sex = checkSexRes.code;
      }

      if (studentPid != null) {
        if (['1', '2'].includes(studentPid[1])) { 
          sexCode = studentPid[1]; 
          if (sexCode != _sex) { 
            toast.error('【身分證字號】與【性別】資料不符'); 
          } else { 
            okUpload = true; 
          } 
        } else if (['8', '9', 'A', 'B', 'C', 'D'].includes(studentPid[1])) { // 若為居留證 
          if (['8', 'A', 'C'].includes(studentPid[1])) { // 男性 
            sexCode = '1';
          } else if (['9', 'B', 'D'].includes(studentPid[1])) { // 女性 
            sexCode = '2'; 
          } if (sexCode !== _sex) { 
            toast.error('【身分證字號】與【性別】資料不符'); 
          } else { 
            okUpload = true; 
          } 
        }
      }
    } else {
      okUpload = true;
    }
    student.pid = studentPid; // 代入已驗證身分證

    // DMHealth.cs
    // CheckStPID裡
    return { okUpload, student };
  }

  // 無身份證字號或居留證號
  function ClickNonPID() {
    if (noPidOrPermit.current?.checked) {
      //  產生臨時證號
      const genNonPIDRes = GenNonPID(currentSchool);
      setPidInputRefDisabled(true); // 鎖定pidInputRef
      setStudentPid(genNonPIDRes); // 代入pidInputRef
      setNoPid(true);
    } else {
      setNoPid(false);
      setPidInputRefDisabled(false); // 解除鎖定pidInputRef
    }
  }

  function onHide() {
    setEditing(false);
    setEditingStudent(undefined);
  }

  function onEdit(student: Student) {
    setStudentPid(student?.pid?.replace(/^t-/, '') ?? '');
    setEditing(true);
    setEditingStudent(student);
  }

  function onHideDeleting() {
    setDeleting(false);
  }

  function onDelete(student: Student) {
    setDeletingStudent(student);
    setDeleting(true);
  }

  function deleteStudent() {
    if (deletingStudent) {
      apis
        .deleteStudent(deletingStudent.pid, deleteType)
        .then(() => {
          setModalContent(`成功設定${deleteTypeName}`);
          setDeleteType(0); // 刪除後 重設deleteType
          setDeleting(false);
          onClassChanged();
        })
        .catch(catchErrorForModal);
    }
  }

  function onUpdateStudent(student: Student) {
    const checkPidIsValidRes = checkPidIsValid(student); //  檢查性別與身分證是否一致
    if(checkPidIsValidRes.okUpload) {
      toast
        .promise(apis.SetStudent(student, year, sem), {
          pending: '學生基本資料上傳中......',
          success: '學生基本資料上傳成功！',
          error: '學生基本資料上傳失敗！請查看錯誤資訊。',
        })
        .then(() => onHide())
        .catch(catchErrorForModal);
    }
  }

  async function onCreateStudent(student: Student) {
    let studentClassGrade = grade;
    let studentClassNo = no;
    let studentClassName = className;
    const checkPidIsValidRes = checkPidIsValid(student); // 檢查身分證格式

    if (studentClassGrade === 0) {
      studentClassGrade = student.classGrade || 0;
    }
    if (studentClassNo === 0) {
      studentClassNo = student.classNo || 0;
      studentClassName = `${I18N.Grades[studentClassGrade]}${studentClassNo}班`;
    }
    student.sid = student.sid ?? ' ';
    if (grade) {
      student.classNo = no;
      studentClassNo = no;
      studentClassGrade = grade;
    }
    if (checkPidIsValidRes.okUpload) {
      const birthWithTimeSetToStartOfDay = student.birth.startOf('day');
      student.birth = birthWithTimeSetToStartOfDay;
      toast
        .promise(
          apis.CreateStudent(
            student,
            year,
            sem,
            studentClassGrade,
            studentClassNo,
            studentClassName,
            classId
          ),
          {
            pending: '學生基本資料新增上傳中......',
            success: '學生基本資料新增上傳成功！',
            error: '學生基本資料新增上傳失敗！請查看錯誤資訊。',
          }
        )
        .then(() => {
          onCreatingHide();
          onClassChanged();
          setStudentPid(''); // 清空pidInputRef
        })
        .catch(catchErrorForModal);
    }
  }

  function modalInputFieldTitle(title: string, require: boolean = false) {
    return (
      <Col sm={2}>
        {require ? <span className="text-danger">*</span> : ''}
        {title}:
      </Col>
    );
  }

  return (
    <Row>
      {
        <Button type="submit" className="mb-2" onClick={onInputing}>
          新增學生
        </Button>
      }
      <EditableTableDiv
        editable
        deleteable
        headers={headerDisplayKeys}
        values={students}
        onEdit={onEdit}
        onDelete={onDelete}
      />
      <Modal
        show={editing}
        size="lg"
        aria-labelledby="student-editing-modal"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="student-editing-modal">
            學生資料 -{' '}
            <strong>
              {editingStudent?.pid} {editingStudent?.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Form
          initialValues={editingStudent}
          onSubmit={onUpdateStudent}
          validateOnBlur
          subscription={{ values: true }}
          render={(prop) => {
            const { values, handleSubmit } = prop;
            const rowClassName =
              'pb-3 align-items-center justify-content-start';
            return (
              <React.Fragment>
                <Modal.Body className="text-center">
                  <Container>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('學號')}
                      <Col sm={4}>
                        <TextField property="sid" />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('姓名', true)}
                      <Col sm={4}>
                        <TextField property="name" />
                      </Col>
                      {modalInputFieldTitle('座號', true)}
                      <Col sm={4}>
                        <NumberField
                          property="seat"
                          min={1}
                          max={100}
                          validate={(v) =>
                            !v || v < 0 || v > 100
                              ? '必須在1~100之間'
                              : undefined
                          }
                        />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('性別', true)}
                      <Col sm={4}>
                        <InputDropdownGroupField
                          type="text"
                          resultShow="both"
                          property="sex"
                          content={{
                            '1': '男生',
                            '2': '女生',
                          }}
                        />
                      </Col>
                      {modalInputFieldTitle('身份')}
                      <Col sm={4}>
                        <InputDropdownGroupField
                          type="text"
                          resultShow="both"
                          property="aborigine"
                          content={{
                            '0': '一般生',
                            '1': '原住民',
                            '2': '僑生',
                            '4': '外籍生',
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('生日', true)}
                      <Col sm={4}>
                        <DateTimeField property="birth" />
                      </Col>
                      {modalInputFieldTitle('血型')}
                      <Col sm={4}>
                        <InputDropdownGroupField
                          type="text"
                          selectShow="key"
                          property="blood"
                          content={{
                            A: 'A',
                            AB: 'AB',
                            B: 'B',
                            O: 'O',
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('父親')}
                      <Col sm={4}>
                        <TextField property="dad" />
                      </Col>
                      {modalInputFieldTitle('母親')}
                      <Col sm={4}>
                        <TextField property="mom" />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('監護人')}
                      <Col sm={4}>
                        <TextField property="guardian" />
                      </Col>
                      {modalInputFieldTitle('家中電話')}
                      <Col sm={4}>
                        <TextField property="tel" />
                        不用輸入區號
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('郵區')}
                      <Col sm={4}>
                        <InputDropdownGroupField
                          type="text"
                          resultShow="both"
                          property="zip"
                          content={zipCodes}
                        />
                      </Col>
                      {modalInputFieldTitle('住址')}
                      <Col sm={4}>
                        <TextField property="address" />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('緊急聯絡')}
                      <Col sm={4}>
                        <TextField property="erTel" />
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" onClick={handleSubmit}>
                    儲存
                  </Button>
                  <Button type="reset" variant="secondary" onClick={onHide}>
                    關閉
                  </Button>
                </Modal.Footer>
              </React.Fragment>
            );
          }}
        />
      </Modal>
      <Modal
        show={creating}
        size="lg"
        aria-labelledby="student-editing-modal"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={onCreatingHide}>
          <Modal.Title id="student-editing-modal">
            {pidInputRef.current?.value}
          </Modal.Title>
        </Modal.Header>
        <Form
          initialValues={creatingStudent}
          onSubmit={onCreateStudent}
          validateOnBlur
          subscription={{ submitting: true, pristine: true }}
          render={(prop) => {
            const { values, handleSubmit } = prop;
            const rowClassName =
              'pb-3 align-items-center justify-content-start';
            return (
              <React.Fragment>
                <Modal.Body className="text-center">
                  <Container>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('學號')}
                      <Col sm={4}>
                        <TextField property="sid" />
                      </Col>
                      {modalInputFieldTitle('姓名', true)}
                      <Col sm={4}>
                        <TextField property="name" />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('入學年', false)}
                      <Col sm={4}>
                        {year}-{sem}
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('年級', true)}
                      {grade ? (
                        <Col sm={4}>{className}</Col>
                      ) : (
                        <Col sm={4}>
                          <InputTransformField
                            property="classGrade"
                            type="number"
                            min={min}
                            max={max}
                            transform={(v) =>
                              (+v >= min && +v <= max && I18N.Grades[+v]) ||
                              '未知年級'
                            }
                            validate={(v) => {
                              return +v >= min && +v <= max
                                ? undefined
                                : `範圍應在${min}~${max}之間`;
                            }}
                          />
                        </Col>
                      )}
                      {grade ? (
                        <></>
                      ) : (
                        <>
                          {modalInputFieldTitle('班級', true)}
                          <Col xs={4}>
                            <NumberField
                              property="classNo"
                              min={1}
                              max={50}
                              validate={(v) =>
                                !v || v < 0 || v > 50
                                  ? '必須在1~50之間'
                                  : undefined
                              }
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('性別', true)}
                      <Col sm={4}>
                        <InputDropdownGroupField
                          type="text"
                          resultShow="both"
                          property="sex"
                          content={{
                            '1': '男生',
                            '2': '女生',
                          }}
                        />
                      </Col>
                      {modalInputFieldTitle('座號', true)}
                      <Col sm={4}>
                        <NumberField
                          property="seat"
                          min={1}
                          max={100}
                          validate={(v) =>
                            !v || v < 0 || v > 100
                              ? '必須在1~100之間'
                              : undefined
                          }
                        />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('生日', true)}
                      <Col sm={4}>
                        <DateTimeField property="birth" />
                      </Col>
                      {modalInputFieldTitle('身份')}
                      <Col sm={4}>
                        <InputDropdownGroupField
                          type="text"
                          resultShow="both"
                          property="aborigine"
                          content={{
                            '0': '一般生',
                            '1': '原住民',
                            '2': '僑生',
                            '4': '外籍生',
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('父親')}
                      <Col sm={4}>
                        <TextField property="dad" />
                      </Col>
                      {modalInputFieldTitle('血型')}
                      <Col sm={4}>
                        <InputDropdownGroupField
                          type="text"
                          selectShow="key"
                          property="blood"
                          content={{
                            A: 'A',
                            AB: 'AB',
                            B: 'B',
                            O: 'O',
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('監護人')}
                      <Col sm={4}>
                        <TextField property="guardian" />
                      </Col>
                      {modalInputFieldTitle('母親')}
                      <Col sm={4}>
                        <TextField property="mom" />
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('郵區')}
                      <Col sm={4}>
                        <InputDropdownGroupField
                          type="text"
                          resultShow="both"
                          property="zip"
                          content={zipCodes}
                        />
                      </Col>
                      {modalInputFieldTitle('家中電話')}
                      <Col sm={4}>
                        <TextField property="tel" />
                        不用輸入區號
                      </Col>
                    </Row>
                    <Row className={rowClassName}>
                      {modalInputFieldTitle('緊急聯絡')}
                      <Col sm={4}>
                        <TextField property="erTel" />
                      </Col>
                      {modalInputFieldTitle('住址')}
                      <Col sm={4}>
                        <TextField property="address" />
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" onClick={handleSubmit}>
                    儲存
                  </Button>
                  <Button
                    type="reset"
                    variant="secondary"
                    onClick={onCreatingHide}
                  >
                    關閉
                  </Button>
                </Modal.Footer>
              </React.Fragment>
            );
          }}
        />
      </Modal>
      <Modal
        show={inputingPid}
        size="lg"
        aria-labelledby="student-editing-modal"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={onInputingHide}>
          <Modal.Title id="student-editing-modal">新增學生</Modal.Title>
        </Modal.Header>
        <React.Fragment>
          <Modal.Body className="text-center">
            <Container>
              <Row className="pb-3 align-items-center justify-content-start">
                <Col>請輸入新增學生之身份證字號或居留證號</Col>
              </Row>
              <Row className="pb-3 align-items-center justify-content-start">
                <InputGroup>
                  <FormInput.Control
                    className="mr-2"
                    disabled={pidInputRefDisabled}
                    value={studentPid || ''}
                    placeholder="身分證或居留證號"
                    aria-label="pid"
                    aria-describedby="basic-addon1"
                    ref={pidInputRef}
                    onChange={(e) => {
                      setStudentPid(e.target.value.toUpperCase());
                    }}
                  />
                </InputGroup>
                <div>
                  <FormCheck
                    className="ml-3"
                    ref={noPidOrPermit}
                    label="無身份證字號或居留證號"
                    onChange={(e) => ClickNonPID()}
                  />
                </div>
              </Row>
              <Row className="pb-3 align-items-center justify-content-start">
                <div>
                  {' '}
                  身份證字號或居留證號皆為10碼, 若居留證號為7碼者, 是舊號,
                  請轉為新號(洽各地警察局外事單位)
                </div>
                <div> 如勾選無身份證字號或居留證號, 將由系統自動產生一代號</div>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              disabled={studentPid == ''}
              onClick={onCreating}
            >
              下一步
            </Button>
            <Button type="reset" variant="secondary" onClick={onInputingHide}>
              取消
            </Button>
          </Modal.Footer>
        </React.Fragment>
      </Modal>
      <Modal
        show={deleting}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton onHide={onHideDeleting}>
          <Modal.Title id="contained-modal-title-vcenter">刪除學生</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col sm={12} className="mb-2">
                請選擇刪除學生原因
              </Col>
              <Col sm={3}>
                <FormCheck
                  type="radio"
                  label="刪除"
                  name="transferTypeEnum"
                  checked={deleteType == 5}
                  onChange={(e) => {
                    setDeleteType(5);
                    setDeleteTypeName('刪除');
                  }}
                />
              </Col>
              <Col sm={3}>
                <FormCheck
                  type="radio"
                  label="休學"
                  name="transferTypeEnum"
                  checked={deleteType == 2}
                  onChange={(e) => {
                    setDeleteType(2);
                    setDeleteTypeName('休學');
                  }}
                />
              </Col>
              <Col sm={3}>
                <FormCheck
                  type="radio"
                  label="退學"
                  name="transferTypeEnum"
                  checked={deleteType == 3}
                  onChange={(e) => {
                    setDeleteType(3);
                    setDeleteTypeName('退學');
                  }}
                />
              </Col>
              <Col sm={3}>
                <FormCheck
                  type="radio"
                  label="其他"
                  name="transferTypeEnum"
                  checked={deleteType == 99}
                  onChange={(e) => {
                    setDeleteType(99);
                    setDeleteTypeName('其他');
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" type="submit" onClick={deleteStudent}>
            確認
          </Button>
          <Button type="reset" variant="secondary" onClick={onHideDeleting}>
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={!!modalContent}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header
          closeButton
          onHide={() => {
            setModalContent(undefined);
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {deletingStudent?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{modalContent}</Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setModalContent(undefined);
            }}
          >
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};

export const BasicClassStudent = connector(
  WrapClassBaseLayout(basicClassStudent)
);
