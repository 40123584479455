import { DateTime } from 'luxon';
import { dateTimeFromString, semesterRange } from './date';
import { Nullable } from '../types';
import { ExaminedResultEnum, UrineEnum } from '../model';

//檢查Pid
export function CheckPid(pid: any, checkFormat: boolean = false) {
  let okUpload = true;
  let errorMsg = '';
  if (!pid && !checkFormat) {
    okUpload = false;
    errorMsg = '請填入【身分證字號】，';
  } else {
    //身分證格式驗證
    if (checkFormat && !checkIDFormat(pid)) {
      okUpload = false;
      errorMsg = '請確認【身分證字號】格式，';
    }
  }
  return { pid, okUpload, errorMsg };
}

//身分證格式驗證
function checkIDFormat(id: string) {
  /* 第一碼英文轉換格式
      A | B | C | D | E | F | G | H | I | J | K | L | M | N | O | P | Q | R | S | T | U | V | W | X | Y | Z |
     10 |11 |12 |13 |14 |15 |16 |17 |34 |18 |19 |20 |21 |22 |35 |23 |24 |25 |26 |27 |28 |29 |32 |30 |31 |33 |
  */
  const _id0 = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  const _id0Code0 = [
    1, 1, 1, 1, 1, 1, 1, 1, 3, 1, 1, 2, 2, 2, 3, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3,
    3,
  ];
  const _id0Code1 = [
    0, 1, 2, 3, 4, 5, 6, 7, 4, 8, 9, 0, 1, 2, 5, 3, 4, 5, 6, 7, 8, 9, 2, 0, 1,
    3,
  ];

  //身分證共10碼
  if (id.length != 10) {
    return false;
  }

  //第一碼必為英文，取得英文的index
  const _id0Index = _id0.indexOf(id[0]);
  if (_id0Index == -1) {
    return false;
  }

  //若為身分證第二碼必為['1','2']，性別，'1'代表男性，'2'代表女性; 若為居留證第二碼性別碼 8為男性，9為女性
  if (!['1', '2', '8', '9'].includes(id[1])) {
    if(['A', 'B', 'C', 'D'].includes(id[1])) { // 若為舊式居留證第二碼必為['A','B', 'C', 'D']性別碼(入出境管理局使用ＡＢ；警察局外事科/課使用ＣＤ)
      // 居留證 ex: 'FA12345689'
      // Ａ轉換為10─＞取尾數「0」
      const _id0Index = _id0.indexOf(id[1]); 
      if (_id0Index !== -1) { 
        const newChar = _id0Code1[_id0Index].toString(); // 將數字轉換為字串 
        id = id[0] + newChar + id.slice(2); // 替換 id[1] 並重建 id 字串 
      }
    }
  }
    

  //第三碼到第九碼為流水號碼
  /* ex: 身分證為 "A123456789"
    原  :  A  | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
    轉換: 1 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
   權重*: 1 9 | 8 | 7 | 6 | 5 | 4 | 3 | 2 | 1 | 檢查碼
   
   計算方式:
    1*1 + 0*9 + 1*8 + 2*7 + 3*6 + 4*5 + 5*4 + 6*3 + 7*2 + 8*1
    =121
    %10 取餘數:
    121%10 = 1
    檢查碼 = (10-餘數)%10
    10-1 = 9

  */

  //計算權重
  let _sum = _id0Code0[_id0Index] * 1 + _id0Code1[_id0Index] * 9;
  [1, 2, 3, 4, 5, 6, 7, 8].forEach(function (i) {
    const _n = parseInt(id[i]);
    if (isNaN(_n)) {
      //1~8碼不為數字
      return false;
    }
    _sum += _n * (9 - i);
  });

  //第十個碼為檢查號碼
  const _id9 = parseInt(id[9]);
  if (isNaN(_id9)) {
    //第九碼不為數字
    return false;
  }

  if (_id9 != (10 - (_sum % 10)) % 10) {
    return false;
  }

  return true;
}

//檢查Sid
//To-Check: 學號是否必填
export function CheckSid(oriSid: any) {
  const okUpload = true;
  const errorMsg = '';
  let sid = oriSid;
  if (!sid) {
    // okUpload = false;
    // errorMsg += '請填入【學號】，';
    sid = '';
  }
  return { sid, okUpload, errorMsg };
}

//檢查年級班級座號
export function CheckGradeNoSeat(gns: any) {
  let okUpload = true;
  let errorMsg = '';
  let grade: number | null | undefined = null;
  let no: number | null | undefined = null;
  let seat: number | null | undefined = null;
  if (gns) {
    if (gns.length == 6) {
      grade = +gns.substring(0, 2);
      no = +gns.substring(2, 4);
      seat = +gns.substring(4, 6);
    } else {
      if (gns.length == 5) {
        grade = +gns.substring(0, 1);
        no = +gns.substring(1, 3);
        seat = +gns.substring(3, 5);
      } else {
        okUpload = false;
        errorMsg = '請確認【年級班級座號】格式，';
      }
    }
  } else {
    // To-Check: 年級班級座號 是否必填
    // okUpload = false;
    // errorMsg = '請填入【年級班級座號】，';
  }

  return { grade, no, seat, okUpload, errorMsg };
}

//檢查 身分證、學號、年級班級座號
export function CheckStudentBaseData(
  oriPid: any,
  oriSid: any,
  oriGns: any,
  checkFormat: boolean = false
) {
  let okUpload = true;
  let errorMsg = '';
  const hasValue = oriPid || oriSid || oriGns;
  const checkPidResult = CheckPid(oriPid, checkFormat);
  const pid = checkPidResult.pid;
  if (!checkPidResult.okUpload && !hasValue) {
    okUpload = checkPidResult.okUpload;
    errorMsg += checkPidResult.errorMsg;
  }

  //To-Check: 學號是否必填
  const checkSidResult = CheckSid(oriSid);
  const sid = checkSidResult.sid;
  if (!checkSidResult.okUpload && !hasValue) {
    okUpload = checkPidResult.okUpload;
    errorMsg += checkPidResult.errorMsg;
  }

  //檢查年級班級座號
  const checkGradeNoSeat = CheckGradeNoSeat(oriGns);
  const grade = checkGradeNoSeat.grade;
  const no = checkGradeNoSeat.no;
  const seat = checkGradeNoSeat.seat;
  if (!checkGradeNoSeat.okUpload && !hasValue) {
    okUpload = checkGradeNoSeat.okUpload;
    errorMsg += checkGradeNoSeat.errorMsg;
  }

  return { pid, sid, grade, no, seat, okUpload, errorMsg };
}

//檢查學期
export function CheckSemester(semester: string | null | undefined): {
  okUpload: boolean;
  errorMsg: string;
  semester: string;
} {
  const vSems: Record<string, string> = {
    '1': '1',
    '2': '2',
    上: '1',
    下: '2',
  };
  if (!semester) {
    return {
      okUpload: false,
      errorMsg: '請填入【學期】，',
      semester: '',
    };
  }

  const sem = vSems[semester];

  if (!sem || typeof sem !== 'string') {
    return {
      okUpload: false,
      errorMsg: '請確認【學期】格式，',
      semester: '',
    };
  }
  return {
    okUpload: true,
    errorMsg: '',
    semester: sem,
  };
}

//檢查日期格式
export function CheckDate(date: string) {
  const dateArray = date.split('/');
  let _yyyy = '';
  let _mm = '';
  let _dd = '';
  if (dateArray[0].length < 4) {
    //Date=(mm/dd/yy)
    _mm = dateArray[0].length == 2 ? dateArray[0] : '0' + dateArray[0];
    _dd = dateArray[1].length == 2 ? dateArray[1] : '0' + dateArray[1];
    _yyyy = dateArray[2].length == 4 ? dateArray[2] : '20' + dateArray[2];
  } else {
    //Date=(yyyy/mm/dd)
    _yyyy = dateArray[0];
    _mm = dateArray[1].length == 2 ? dateArray[1] : '0' + dateArray[1];
    _dd = dateArray[2].length == 2 ? dateArray[2] : '0' + dateArray[2];
  }

  const yyyy = Number(_yyyy);
  const mm = Number(_mm);
  const dd = Number(_dd);
  const resDate = DateTime.fromFormat(
    _yyyy + '/' + _mm + '/' + _dd ?? '',
    'yyyy/MM/dd'
  );

  return { yyyy, mm, dd, resDate };
}

export function CheckDateInSemester(
  dateStr: string | null | undefined,
  year: number,
  sem: number,
  itemName: string
): {
  isNull: boolean;
  okUpload: boolean;
  resDate: DateTime;
  errorMsg: string;
} {
  if (!dateStr) {
    return {
      isNull: true,
      okUpload: false,
      resDate: DateTime.invalid('null value'),
      errorMsg: `請填入【${itemName}】，`,
    };
  }

  const date = dateTimeFromString(dateStr);
  if (!date.isValid) {
    return {
      isNull: false,
      okUpload: false,
      resDate: date,
      errorMsg: `【${itemName}】應為"年/月/日"格式，`,
    };
  }

  const [start, end] = semesterRange(year, sem);
  if (start > date || end < date) {
    return {
      isNull: false,
      okUpload: false,
      resDate: date,
      errorMsg: `請確認【${itemName}】期限，`,
    };
  }
  return {
    isNull: false,
    okUpload: true,
    resDate: date,
    errorMsg: '',
  };
}

//檢查是否符合特定字元
export function CheckCode(oriCode: any, codeArray: string[], itemName: string) {
  let okUpload = true;
  let errorMsg = '';
  let isNull = false;
  let code = '';
  if (!oriCode) {
    okUpload = false;
    errorMsg = '請填入【' + itemName + '】，';
  } else {
    if (!codeArray.includes(oriCode)) {
      okUpload = false;
      errorMsg = '請確認【' + itemName + '】格式，';
    }
    //格式錯誤還是assign，以便前端顯示錯誤資料
    code = oriCode;
  }
  if (oriCode == null) {
    isNull = true;
    okUpload = false;
    errorMsg = '請填入【' + itemName + '】，';
  }
  return { code, okUpload, errorMsg, isNull };
}

//檢查是否為數字
export function CheckNumber(
  oriCode: any,
  defaultCode: number,
  itemName: string
) {
  let okUpload = true;
  let errorMsg = '';
  let isNull = false;
  let code = defaultCode;
  if (!oriCode) {
    isNull = true;
    okUpload = false;
    errorMsg = '請填入【' + itemName + '】，';
  } else {
    if (Number(oriCode).toString() == 'NaN') {
      //非number
      okUpload = false;
      errorMsg += '請確認【' + itemName + '】格式，';
    }
    code = Number(oriCode);
  }

  return { code, okUpload, errorMsg, isNull };
}

//檢查是否為數字
export function CheckNumberRange(
  oriCode: any,
  defaultCode: number,
  range: { min: number; max: number },
  itemName: string
) {
  let okUpload = true;
  let errorMsg = '';
  let isNull = false;
  let code = defaultCode;
  if (oriCode === undefined || oriCode === null) {
    isNull = true;
    okUpload = false;
    errorMsg = '請填入【' + itemName + '】，';
  } else {
    if (Number.isNaN(oriCode)) {
      //非number
      okUpload = false;
      errorMsg += '請確認【' + itemName + '】格式，';
    } else if (oriCode < range.min || oriCode > range.max) {
      // 小於 或 大於
      okUpload = false;
      errorMsg += '【' + itemName + '】錯誤，';
    }
    code = Number(oriCode);
  }

  return { code, okUpload, errorMsg, isNull };
}

// 血液檢查-檢查是否為數字，可輸入大小於符號（Ex：<2.0）
export function CheckBloodNumberRange(
  oriCode: any,
  defaultCode: number,
  range: { min: number; max: number },
  itemName: string
) {
  let okUpload = true;
  let errorMsg = '';
  let isNull = false;
  let code = defaultCode;

  if (oriCode === undefined || oriCode === null) {
    isNull = true;
    okUpload = false;
    errorMsg = '請填入【' + itemName + '】，';
  } else {
    if (typeof oriCode === 'string' && oriCode.startsWith('<')) {
      const num = parseFloat(oriCode.substring(1));
      if (!Number.isNaN(num)) {
        oriCode = num - 0.00001; // 將值自動轉為 2.99999
      }
    }

    if (Number.isNaN(oriCode)) {
      // 非number
      okUpload = false;
      errorMsg += '請確認【' + itemName + '】格式，';
    } else if (oriCode < range.min || oriCode > range.max) {
      // 小於 或 大於
      okUpload = false;
      errorMsg += '【' + itemName + '】錯誤，';
    } else {
      code = Number(oriCode);
    }
  }

  return { code, okUpload, errorMsg, isNull };
}

// 無身份證字號或居留證號時 自動產生臨時證號
// 臨時證號 = 學校代號+時間戳
export function GenNonPID(schoolId: string) {
  const NonPID = schoolId + Date.now();
  return NonPID;
}

export function checkExaminedResultEnum(
  oriCode: any,
  itemName: string
): {
  code: ExaminedResultEnum;
  isNull: boolean;
  okUpload: boolean;
  errorMsg: string;
} {
  if (!oriCode) {
    return {
      code: ExaminedResultEnum.NoExamined,
      isNull: true,
      okUpload: false,
      errorMsg: '請填入【' + itemName + '】，',
    };
  }

  if ([0, 1, 2, 3, 9, '0', '1', '2', '3', '9'].indexOf(oriCode) !== -1) {
    return {
      code: +oriCode as ExaminedResultEnum,
      isNull: false,
      okUpload: true,
      errorMsg: '',
    };
  }

  return {
    code: ExaminedResultEnum.NoExamined,
    isNull: false,
    okUpload: false,
    errorMsg: '請確認【' + itemName + '】格式，',
  };
}

export function CheckUrineEnum(
  oriCode: any,
  itemName: string
): {
  code: UrineEnum;
  isNull: boolean;
  okUpload: boolean;
  errorMsg: string;
} {
  if (!oriCode) {
    return {
      code: UrineEnum.Negetive,
      isNull: true,
      okUpload: false,
      errorMsg: '請填入【' + itemName + '】，',
    };
  }

  if ([0, 1, 2, 3, 4, 5].indexOf(+oriCode) !== -1) {
    return {
      code: +oriCode as UrineEnum,
      isNull: false,
      okUpload: true,
      errorMsg: '',
    };
  }

  return {
    code: UrineEnum.Negetive,
    isNull: false,
    okUpload: false,
    errorMsg: '請確認【' + itemName + '】格式，',
  };
}
