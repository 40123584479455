export const I18N = Object.freeze({
  SchoolRanks: {
    1: '國小',
    6: '國小與國中',
    7: '國中',
    8: '國中與高中',
    10: '高中',
    11: '四年制高中',
  } as Record<number, string>,
  PhysicalBodyAdditional: {
    '0': '斜視',
    '1': '睫毛倒插',
    '2': '眼球震顫',
    '3': '眼瞼下垂',
    '4': '唇顎裂',
    '5': '構音異常',
    '6': '耳前瘻管',
    '7': '慢性鼻炎',
    '8': '過敏性鼻炎',
    '9': '水腫',
    a: '疝氣',
    b: '陰囊腫大',
    c: '紫斑',
    d: '隱睪',
    e: '口腔黏膜異常',
    f: '耳道畸型',
    g: '耳膜破損',
    h: '耵聹栓塞',
    i: '扁桃腺腫大',
    j: '恆牙臼齒窩溝封填',
  } as Record<string, string>,
  PhysicalBloodAdditional: {
    '1': '三酸甘油酯',
    '2': '高密度膽固醇',
    '3': '低密度膽固醇',
    '4': '飯後血糖',
    '5': '空腹血糖',
    '6': 'B型肝炎e抗原',
    '7': '血尿素氮',
    '8': '血球容積比',
  } as Record<string, string>,
  Grades: {
    1: '一年',
    2: '二年',
    3: '三年',
    4: '四年',
    5: '五年',
    6: '六年',
    7: '國一',
    8: '國二',
    9: '國三',
    10: '高一',
    11: '高二',
    12: '高三',
    13: '高四',
  } as Record<number, string>,
  Strabismus: {
    1: '內斜視',
    2: '外斜視',
    3: '上斜視',
    4: '下斜視',
    5: '外旋斜視',
    6: '內旋斜視',
    7: '麻痺性斜視',
    9: '其他',
  } as Record<number, string>,
  Physical: {
    sbp: '收縮壓',
    dbp: '舒張壓',
    bpResult: '血壓判讀',
    waistline: '腰圍',
    waistResult: '',
    pulse: '脈博',
    pulseResult: '脈搏判讀',
    colorBlind: '辨色力異常',
    e99: '其他',
    e99State: '',
    hearing: '聽力異常',
    hearingLR: '',
    earMisshapen: '耳道畸型',
    earMisshapenLR: '',
    eardrum: '耳膜破損',
    eardrumLR: '',
    cerumen: '耵聹栓塞',
    cerumenLR: '',
    tonsillitis: '扁桃腺腫大',
    o99: '其他',
    o99State: '',
    torticollis: '斜頸',
    mass: '異常腫塊',
    goiter: '甲狀腺腫',
    lymphadenectasis: '淋巴腺腫大',
    mass99: '其他異常腫塊',
    n99: '其他',
    n99State: '',
    cardiopulmonary: '心肺疾病',
    heartMurmur: '心雜音',
    arrhythmia: '心律不整',
    wheeze: '呼吸聲異常',
    cardiopulmonary99: '其他心肺疾病',
    thorax: '胸廓異常',
    c99: '其他',
    c99State: '',
    abdomen: '腹部異常腫大',
    a99: '其他',
    a99State: '',
    scoliosis: '脊柱側彎',
    dysmelia: '肢體畸形',
    polydactyly: '多併指（趾）',
    dysarthrosis: '關節變形',
    dysmelia99: '其他肢體畸形',
    squatting: '蹲踞困難',
    l99: '其他',
    l99State: '',
    cryptorchidism: '隱睪',
    prepuce: '包皮異常',
    varicocele: '精索靜脈曲張',
    u99: '其他',
    u99State: '',
    epidermophytosis: '癬',
    scabies: '疥瘡',
    wart: '疣',
    atopicDermatitis: '異位性皮膚炎',
    eczema: '溼疹',
    d99: '其他',
    d99State: '',
    t01: '未治療齲齒',
    t02: '缺牙',
    t03: '待拔牙',
    t04: '口腔衛生不良',
    t05: '牙結石',
    t06: '',
    t07: '咬合不正',
    t08: '牙齦炎',
    t99: '其他',
    t99state: '',
    tSheet: '',
    t09: '口腔黏膜異常',
    t11: '已治療齲齒',
    t12: '上顎恆牙第一大臼齒齲齒經驗',
    t13: '下顎恆牙第一大臼齒齲齒經驗',
    t15: '恆牙臼齒窩溝封填',
    t16: '牙周病',
    t17: '乳牙待拔牙',
    t18: '贅生牙',
    t19: '阻生牙',
    strabismus: '斜視',
    trichiasis: '睫毛倒插',
    nystagmus: '眼球震顫',
    blepharoptosis: '眼瞼下垂',
    clp: '唇顎裂',
    articulationDisorders: '構音異常',
    preauricularFistula: '耳前瘻管',
    rhinitis: '慢性鼻炎',
    allergicRhinitis: '過敏性鼻炎',
    hernia: '疝氣',
    edema: '水腫',
    scrotalSwelling: '陰囊腫大',
    purpura: '紫斑',
    checkUnit: '檢查單位',
    doctor: '醫師',
    teeDoctor: '牙醫師',
    examDate: '檢查日期',
  } as Record<string, string>,
  ExaminationResult: {
    0: '無異狀',
    1: '初檢異常',
    2: '複診正常',
    3: '複診異常',
    9: '未實施',
  } as Record<number, string>,
  SingleExaminationOnlyResult: {
    0: '無異狀',
    1: '有',
    9: '未受檢',
  } as Record<number, string>,
  FirstExaminationOnlyResult: {
    0: '無異狀',
    1: '初檢異常',
    9: '未實施',
  } as Record<number, string>,
  FollowUpExaminationResult: {
    2: '複診正常',
    3: '複診異常',
  } as Record<number, string>,
  StrabismusResult: {
    0: '無',
    1: '內斜視',
    2: '外斜視',
    3: '上斜視',
    4: '下斜視',
    5: '外旋斜視',
    6: '內旋斜視',
    7: '麻痺性斜視',
    9: '其他',
  } as Record<number, string>,
  LeftRightResult: {
    0: '無',
    1: '左',
    2: '右',
    3: '左右',
  } as Record<number, string>,
  Injury: {
    head: '頭',
    neck: '頸',
    shoulder: '肩',
    chest: '胸',
    belly: '腹',
    back: '背',
    eye: '眼',
    face: '顏面',
    mouth: '口腔',
    nose: '耳鼻喉',
    arm: '上肢',
    waist: '腰',
    leg: '下肢',
    buttock: '臀部',
    perineum: '會陰部',
    friction: '擦傷',
    slash: '裂割刺傷',
    press: '夾壓傷',
    strick: '挫撞傷',
    twist: '扭傷',
    burn: '灼燙傷',
    sting: '叮咬傷',
    fracture: '骨折',
    old: '舊傷',
    aOther: '外科其它',
    fever: '發燒',
    dizzy: '暈眩',
    puke: '噁心嘔吐',
    headache: '頭痛',
    toothache: '牙痛',
    stomachache: '胃痛',
    bellyache: '腹痛',
    diarrhoea: '腹瀉',
    menses: '經痛',
    pant: '氣喘',
    noseBlood: '流鼻血',
    rash: '疹癢',
    eyeache: '眼疾',
    hOther: '內科其它',
    woundCare: '傷口處理',
    ice: '冰敷',
    hot: '熱敷',
    rest: '休息觀察',
    noteParent: '通知家長',
    backHome: '家長帶回',
    hospital: '校方送醫',
    education: '衛生教育',
    mOther: '處理其他',
    isSerious: '列入重大傷病',
    rightEye: '右眼',
    leftEye: '左眼',
    rightArm: '右上肢',
    leftArm: '左上肢',
    rightLeg: '右下肢',
    leftLeg: '左下肢',
  } as Record<string, string>,
  Blood: {
    hbResult: '血色素',
    wbcResult: '白血球',
    rbcResult: '紅血球',
    plResult: '血小板',
    mcvResult: '平均血球容積',
    htResult: '血球容積比',
    clResult: '總膽固醇',
    crResult: '肌酸酐',
    uaResult: '尿酸',
    bunResult: '血尿素氮',
    gotResult: 'SGOT',
    gptResult: 'SGPT',
    hbsaResult: 'B型肝炎表面抗原',
    antiHBResult: 'B型肝炎表面抗體',
    tgResult: '三酸甘油酯',
    hdlResult: '高密度膽固醇',
    ldlResult: '低密度膽固醇',
    acResult: '飯後血糖',
    pcResult: '空腹血糖',
    hBeAgResult: 'B型肝炎e抗原',
  } as Record<string, string>,
  BloodUnit: {
    hbResult: 'g/dl',
    wbcResult: '10<sup>3</sup>/uL',
    rbcResult: '10<sup>6</sup>/uL',
    plResult: '10<sup>3</sup>/uL',
    mcvResult: 'fl',
    htResult: '%',
    clResult: 'mg/dl',
    crResult: 'mg/dl',
    uaResult: 'mg/dl',
    bunResult: 'mg/dl',
    gotResult: 'U/L',
    gptResult: 'U/L',
    hbsaResult: '',
    antiHBResult: '',
    tgResult: 'mg/dl',
    hdlResult: 'mg/dl',
    ldlResult: 'mg/dl',
    acResult: 'mg/dl',
    pcResult: 'mg/dl',
    hBeAgResult: '',
  } as Record<string, string>,
  XRay: {
    xRay: '胸部X光檢查',
    phthisis: '肺結核病徵',
    calcify: '肺結核鈣化',
    c03: '胸廓異常',
    water: '肋膜腔積水',
    c02: '脊柱側彎',
    bigHeart: '心臟肥大',
    brochus: '支氣管擴張',
  } as Record<string, string>,
  Parasite: {
    parasite: '結果',
    examDate: '日期',
    drug: '已服藥',
  } as Record<string, string>,
  Urine: {
    uProtein: '尿蛋白',
    uob: '潛血',
    uGlucose: '尿糖',
    uPh: '酸鹼值',
    reUProtein: '尿蛋白',
    reUob: '潛血',
    reUGlucose: '尿糖',
    reUPh: '酸鹼值',
    followUp: '追蹤',
  } as Record<string, string>,
  TransferType: {
    0: '未知原因',
    1: '轉學',
    2: '休學',
    3: '退學',
    4: '復學',
    5: '刪除',
    99: '其他',
  } as Record<string, string>,
  BodyMindBooknType: {
    1: '神經系統構造及精神、心智功能',
    2: '眼、耳及相關構造與感官功能及疼痛',
    3: '涉及聲音與言語構造及其功能',
    4: '涉及聲音與言語構造及其功能',
    5: '消化、新陳代謝與內分泌系統相關構造及其功能',
    6: '泌尿與生殖系統相關構造及其功能',
    7: '神經、肌肉、骨骼之移動相關構造及其功能',
    8: '皮膚與相關構造及其功能',
  } as Record<number, string>,
  SeriousDiseaseType: {
    1: '須積極或長期治療之癌症',
    2: '先天性凝血因子異常',
    3: '嚴重溶血性及再生不良性貧血',
    4: '慢性腎衰竭(尿毒症)，必須接受定期透析治療者',
    5: '需終身治療之全身性自體免疫症候群',
    6: '慢性精神病',
    7: '先天性新陳代謝異常疾病',
    8: '心、肺、胃腸、腎臟、神經、骨骼系統等之先天性畸型及染色體異常',
    9: '燒燙傷面積達全身百分之二十以上；或顏面燒燙傷合併五官功能障礙者。',
    10: '接受腎臟、心臟、肺臟、肝臟及骨髓移植後之追蹤治療',
    11: '小兒麻痺、腦性麻痺、早產兒所引起之神經、肌肉、骨骼、肺臟等之併發症者',
    12: '重大創傷且其嚴重程度到達創傷嚴重程度分數十六分以上者',
    13: '因呼吸衰竭長期使用呼吸器者',
    14: '因腸道大量切除或失去功能，或其它慢性疾病引起嚴重營養不良者',
    15: '因潛水、或減壓不當引起之嚴重型減壓病或空氣栓塞症，伴有呼吸、循環或神經系統之併發症且需長期治療者',
    16: '重症肌無力症',
    17: '先天性免疫不全症',
    18: '脊髓損傷或病變所引起之神經、肌肉、皮膚、骨骼、心肺、泌尿及腸胃等之併發症者',
    19: '職業病',
    20: '急性腦血管疾病',
    21: '多發性硬化症',
    22: '先天性肌肉萎縮症',
    23: '外皮之先天畸型',
    24: '痲瘋病',
    25: '肝硬化症',
    26: '早產兒所引起之神經、肌肉、骨骼、心臟、肺臟等之併發症',
    27: '砷及其化合物之毒性作用（烏腳病）',
    28: '後天免疫缺乏症候群',
    29: '運動神經元疾病其身心障礙者',
    30: '庫賈氏病',
    99: '罕見疾病',
  } as Record<number, string>,
  StudentDiseaseType: {
    1: '心臟病',
    2: '糖尿病',
    3: '腎臟病',
    4: '血友病',
    5: '蠶豆症',
    6: '肺結核',
    7: '氣喘',
    8: '肝炎',
    9: '癲癇症',
    10: '腦炎',
    11: '疝氣',
    12: '過敏物質',
    13: '重大手術',
    14: '罕見疾病',
    15: '海洋性貧血',
    16: '紅斑性狼瘡',
    17: '關節炎',
    18: '心理或精神性疾病',
    19: '癌症',
    99: '其他',
  } as Record<number, string>,
  StudentDiseaseTreatment: {
    1: '避免劇烈運動注意心跳節律',
    2: '早晚胰島素注射 注意低血糖或酮酸中毒症狀',
    3: '依醫囑按時服藥定期追蹤評估腎功能',
    4: '注意是否有出血傾向禁忌各種穿刺或熱敷',
    5: '避免使用禁忌的食品藥品',
    6: '按時服藥不可任意停藥維持正常生活作息',
    7: '注意季節變化家中存有備藥避免接觸過敏原',
    8: '禁忌亂服成藥規律生活定期肝功能追蹤',
    9: '按時服藥和調整劑量及避免發作時的傷害',
    10: '依遺留之後遺症書寫照護重點',
    11: '若尚未手術依疝氣之程度給予適當照護',
    12: '避免接觸過敏原及休克症狀發生',
    13: '依手術後是否留下後遺寫照護重點',
    14: '依其診斷書寫照護重點',
    15: '依個案之輕重度寫照護重點',
    16: '適度運動和休息及藥物副作用之照護',
    17: '關節照護及注意服藥後之副作用',
    18: '依其診斷書寫照護重點',
    19: '依其診斷書寫照護重點',
    99: '依其診斷書寫照護重點',
  } as Record<number, string>,
  SchoolClassNameEnum: {
    0: '1, 2, 3......',
    1: '甲, 乙, 丙.....',
    2: '忠, 孝, 仁.....',
    3: '梅, 蘭, 竹.....',
  },
  InjuryPlace: {
    0: '',
    1: '操場',
    2: '遊戲運動器材',
    3: '普通教室',
    4: '專科教室',
    5: '走廊',
    6: '樓梯',
    7: '地下室',
    8: '體育館活動中心',
    9: '廁所',
    10: '校外',
    99: '其它',
  },
  InjuryMoment: {
    0: '',
    1: '上午',
    2: '中午',
    3: '下午',
  },
});
