const E_UNAUTHORIZED = 'unauthorized';
const E_INVALID_PARAMETER = 'invalid-parameter';
const E_INACTIVE_SEMESTER = 'inactive-semester';
const E_NOT_FOUND = 'not-found';
const E_CAN_NOT_PROCESS = 'can-not-process';
const E_USER_NOT_FOUND = 'user-not-found';
const E_CLASS_NOT_FOUND = 'class-not-found';
const E_ACCOUNT_NOT_FOUND = 'account-not-found';
const E_STUDENT_NOT_FOUND = 'student-not-found';
const E_SCHOOL_NOT_FOUND = 'school-not-found';
const E_USER_ALREADY_EXISTS = 'user-already-exists';
const E_ACCOUNT_ALREADY_EXISTS = 'account-already-exists';
const E_CLASS_ALREADY_EXISTS = 'class-already-exists';
const E_STUDENT_ALREADY_EXISTS = 'student-already-exists';
const E_CLASS_NO_SEAT_ALREADY_EXISTS = 'student-class-no-seat-already-exists';
const E_SCHOOL_ALREADY_EXISTS = 'school-already-exists';
const E_WRONG_PASSWORD = 'wrong-password';
const E_LOGIN_FAILED = 'login-failed';
const E_INSERT_FAILED = 'insert-failed';
const E_INSERT_DUPLICATE = 'insert-duplicate';
const E_INSERT_NOT_PARTICIPATE_DUPLICATE = 'insert-not-participate-duplicate';
const E_QUERY_FAILED = 'query-failed';
const E_DELETE_FAILED = 'delete-failed';
const E_CANNOT_ACCESS_CLASS = 'can-not-access-class';
const E_INVALID_SERVICE = 'invalid-services'; // mostfully server error
const E_UNKNOWN_ERROR = 'unknown-error';

const E_SUB_TRANSFER = 'transfer-data';

// frontend error
export const E_GRADE_INVALID = 'GRADE_INVALID';

export const ErrorCodeMap = Object.freeze({
  [E_UNAUTHORIZED]: '未登入或操作逾時，請重新登入',
  [E_INVALID_PARAMETER]: '錯誤的API資料格式',
  [E_INACTIVE_SEMESTER]: '學期資料錯誤，請確認是否爲可修改之學期',
  [E_NOT_FOUND]: '找不到相關資料',
  [E_CAN_NOT_PROCESS]: '無法進行操作',
  [E_USER_NOT_FOUND]: '找不到使用者',
  [E_CLASS_NOT_FOUND]: '找不到班級',
  [E_STUDENT_NOT_FOUND]: '找不到學生',
  [E_SCHOOL_NOT_FOUND]: '找不到學校',
  [E_ACCOUNT_NOT_FOUND]: '找不到帳號',
  [E_WRONG_PASSWORD]: '密碼錯誤',
  [E_CANNOT_ACCESS_CLASS]: '沒有權限存取',
  [E_INVALID_SERVICE]: '暫時無法提供服務，請稍候嘗試',
  [E_UNKNOWN_ERROR]: '未知錯誤，請聯繫管理員',
  [E_USER_ALREADY_EXISTS]: '使用者已經存在',
  [E_ACCOUNT_ALREADY_EXISTS]: '帳號已經存在',
  [E_CLASS_ALREADY_EXISTS]: '班級已經存在',
  [E_STUDENT_ALREADY_EXISTS]: '學生已經存在',
  [E_CLASS_NO_SEAT_ALREADY_EXISTS]: '年級班級座號重複，該年級班級座號已有學生',
  [E_SCHOOL_ALREADY_EXISTS]: '學校已經存在',
  [E_LOGIN_FAILED]: '登入失敗,請檢查帳號與密碼是否正確',
  [E_INSERT_FAILED]: '新增失敗,請檢查資料是否正確',
  [E_INSERT_DUPLICATE]: '新增失敗,請檢查資料是否重複',
  [E_INSERT_NOT_PARTICIPATE_DUPLICATE]: '新增失敗,該學生已於永不同意參加名單中',
  [E_QUERY_FAILED]: '搜尋失敗,請檢查資料是否正確',
  [E_DELETE_FAILED]: '刪除失敗,請檢查資料是否允許刪除',

  // compound error message
  [`${E_NOT_FOUND}${E_SUB_TRANSFER}`]: '找不到轉學生資料',
  [`${E_CAN_NOT_PROCESS}${E_SUB_TRANSFER}`]: '轉學生資料有誤，無法進行操作',
  [`${E_INSERT_FAILED}${E_SUB_TRANSFER}`]:
    '輸入學生資料有誤，可能需要透過轉學生系統進行匯入。',

  // frontend error code
  [E_GRADE_INVALID]: '年級不正確,無法匯入資料',
} as Record<string, string>);

export const ErrorSubCode = Object.freeze([E_SUB_TRANSFER] as const);
export type ErrorCode = keyof typeof ErrorCodeMap;
export type ErrorSubCode = typeof ErrorSubCode;

const ErrorWithSubCode = new Set([
  E_NOT_FOUND,
  E_CAN_NOT_PROCESS,
  E_INSERT_FAILED,
]);

const AC_REDIRECT_TO_LOGIN_IN = 'redirect-to-login-page';
const AC_GO_TO_LAST_PAGE = 'go-to-last-page';
export const ErrorActionEnum = {
  AC_REDIRECT_TO_LOGIN_IN: AC_REDIRECT_TO_LOGIN_IN,
  AC_GO_TO_LAST_PAGE: AC_GO_TO_LAST_PAGE,
} as const;
export type ErrorAction =
  (typeof ErrorActionEnum)[keyof typeof ErrorActionEnum];

export class ApiError extends Error {
  readonly code: ErrorCode;
  readonly subCode: ErrorSubCode;
  readonly data?: any;
  constructor(code?: ErrorCode, data?: any) {
    super(code + '');
    this.code = code ?? E_UNKNOWN_ERROR;
    this.data = data ?? new Error().stack;
    this.subCode =
      data && typeof data.code === 'string' ? data.code : E_UNKNOWN_ERROR;
  }

  toHumanLang(): string {
    if (!ErrorCodeMap[this.code]) {
      return `${ErrorCodeMap[E_UNKNOWN_ERROR]} -- ${this.data}`;
    }
    switch (this.code) {
      case E_UNKNOWN_ERROR:
        return `${ErrorCodeMap[this.code]} -- ${this.data}`;
      default:
        return ErrorCodeMap[this.code];
    }
  }

  toTitleAndMsg(): { title: string; msg: string } {
    if (!ErrorWithSubCode.has(this.code)) {
      return {
        title: '發生錯誤',
        msg: this.toHumanLang(),
      };
    }

    return {
      title: ErrorCodeMap[this.code],
      msg: ErrorCodeMap[`${this.code}${this.subCode}`] || '',
    };
  }

  errorAction(): ErrorAction | undefined {
    switch (this.code) {
      case E_UNAUTHORIZED:
        return AC_REDIRECT_TO_LOGIN_IN;
      default:
        return;
    }
  }
}
