import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  ReactNode,
} from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import XLSX from 'xlsx';
import { z } from 'zod';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  TeeSheet,
  OverflowWithTooltip,
  EditableTable,
} from '../components';
import Apis from '../apis';
import {
  PhiTee,
  PhiTeeFile,
  PhiTeeFileZod,
  ClassStudent,
  CheckField,
} from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { Nullable } from '../types';
import {
  CheckShouldUseIdentify,
  assignExists,
  takeClassStudent,
} from '../utils';
import { useActiveSems } from '../hook';

type TeePageData = ClassStudent & Nullable<PhiTee>;

const inputFileParser = z.array(PhiTeeFileZod);
const headerDisplayKeys: HeaderDisplayKeysWithType<PhiTeeFile>[] = [
  { display: '備註', property: 'remark' },
  { display: '身份證字號', property: 'pid' },
  { display: '學號', property: 'sid' },
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { display: '學期', property: 'sem' },
  { property: 't01', display: '未治療齲齒' },
  { property: 't11', display: '已治療齲齒' },
  {
    property: 't12',
    display: (
      <React.Fragment>
        上顎恆牙
        <br />
        第一大臼齒齲齒經驗
      </React.Fragment>
    ),
  },
  {
    property: 't13',
    display: (
      <React.Fragment>
        下顎恆牙
        <br />
        第一大臼齒齲齒經驗
      </React.Fragment>
    ),
  },
  {
    property: 't15',
    display: (
      <React.Fragment>
        恆牙臼齒
        <br />
        窩溝封填
      </React.Fragment>
    ),
  },
  { property: 't04', display: '口腔衛生不良' },
  { property: 't05', display: '牙結石' },
  { property: 't08', display: '牙齦炎' },
  { property: 't07', display: '咬合不正' },
  { property: 't09', display: '口腔黏膜異常' },
  { property: 't16', display: '牙周病' },
  { property: 't17', display: '乳牙待拔牙' },
  { property: 't03', display: '待拔牙' },
  { property: 't18', display: '贅生牙' },
  { property: 't02', display: '缺牙' },
  { property: 't19', display: '阻生牙' },
  { property: 't99', display: '口腔其他' },
  {
    property: 't99state',
    display: '口腔其他陳述',
    style: { maxWidth: '100px' },
    onRender: wrapOverflow,
  },
  { property: 'tSheet', display: '口檢表' },
  {
    property: 'ret99state',
    display: (
      <React.Fragment>
        複檢
        <br />
        口腔其他陳述
      </React.Fragment>
    ),
    onRender: wrapOverflow,
  },
  {
    property: 'retSheet',
    display: (
      <React.Fragment>
        複檢
        <br />
        口檢表
      </React.Fragment>
    ),
    style: { maxWidth: '100px' },
  },
  // {
  //   display: '測量日',
  //   property: 'examDate',
  //   onRender: (v) => {
  //     return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
  //   },
  // },
];

function wrapOverflow(content: ReactNode) {
  return <OverflowWithTooltip>{content}</OverflowWithTooltip>;
}

function filterOutNonPhiTeeField({
  okUpload,
  remark,
  ...phitee
}: PhiTeeFile): PhiTee {
  return phitee;
}

function checkOkToUpload(okUpload: boolean, data: PhiTeeFile[]): boolean {
  // console.log('checkOkToUpload: ',okUpload);
  if (okUpload) {
    const a = data.every(function (item) {
      return item.remark === '';
    });
    // console.log(a);
    return a;
  } else {
    return false;
  }
}

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatch = ErrorDispatches;
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const importPhiTeePage: FunctionComponent<Props> = ({
  user: { semesters, currentSemester },
  catchErrorForModal,
  showError,
}) => {
  const [fileName, setFileName] = useState('檔案'); // 如 :  const [fileName, setFileName] = ''; 可?
  const [fileData, setFileData] = useState([] as PhiTeeFile[]);
  const [okToUpload, setOkToUpload] = useState(false);
  const [checkField, setCheckField] = useState(CheckField.None);

  const { selectedSemester, yearSemElement } = useActiveSems(
    semesters,
    currentSemester
  );

  return (
    <AuthedLayout>
      <Row className="mb-2">匯入學期：{yearSemElement}</Row>
      <Row className="mb-2">
        下載匯入格式：
        <a
          href="/Doc/經常性檢查口腔6.xls"
          style={{ textDecoration: 'underline' }}
        >
          經常性檢查口腔匯入格式
        </a>
      </Row>
      <Row className="mb-2">
        <Form.File
          type="file"
          className="my-file-label"
          id="inputGroupFile01"
          label={fileName}
          accept=".xlsx, .csv, .xls, .ods, .ots"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const { files } = e.target;
            try {
              if (files?.length === 1) {
                setFileName(files[0].name);
                const buf = await files[0].arrayBuffer();
                const workShop = XLSX.read(buf, {
                  type: 'buffer',
                  sheets: 0,
                });
                if (workShop.SheetNames[0]) {
                  const jsonData = XLSX.utils.sheet_to_json(
                    workShop.Sheets[workShop.SheetNames[0]],
                    { raw: false, rawNumbers: false }
                  );
                  const headerData = XLSX.utils.sheet_to_json(workShop.Sheets[workShop.SheetNames[0]], { header: 1 });
                  const headers = headerData[0] as string[];
                  const isCorrectFile = headers.find((h: string, i: number) => h === '未治療齲齒');
                  // 檢查表頭是否正確
                  if(!isCorrectFile) {
                    showError('Excel中的表頭欄位不正確，請確認是否選擇了正確的檔案。');
                    return;
                  }
                  const ss = await inputFileParser.parseAsync(jsonData);
                  if (ss) {
                    const [cf, matchFunc] = CheckShouldUseIdentify(ss);
                    setCheckField(cf);
                    if (cf == CheckField.None) {
                      showError(
                        '匯入之檔案應有唯一使用"身份證"或"學號"或"班級座號"'
                      );
                      return;
                    }
                    await Apis.checkClassesStudentExists(
                      takeClassStudent(ss),
                      cf,
                      selectedSemester
                    )
                      .then((result) => {
                        const students = ss.map((x) => {
                          if(x.tSheet != null) {
                            const matchArr = x.tSheet.match(/(\d+[A-Za-z]+)/g);
                            x.tSheet = matchArr ? matchArr.join(',') : x.tSheet;
                          }
                          if(x.retSheet != null) {
                            const matchArr = x.retSheet.match(/(\d+[A-Za-z]+)/g);
                            x.retSheet = matchArr ? matchArr.join(',') : x.retSheet;
                          }
                          return x;
                        });
                        setFileData(assignExists(matchFunc, students, result));
                        //check ss is okToUpload ,then setOkToUpload(true);
                        setOkToUpload(checkOkToUpload(true, students));
                      })
                      .catch((e) => {
                        catchErrorForModal(e);
                        setOkToUpload(false);
                      });
                  } else {
                    console.log(ss);
                  }
                }
              }
            } catch (e) {
              console.log('importPhiTee.ts #147', e);
              setFileName('檔案');
              setFileData([]);
              setOkToUpload(false);
              showError('檔案內容不正確，請檢查資料內容格式');
            }
            e.target.value = '';
          }}
          custom
        />
        <Button
          className="mr-0"
          disabled={!okToUpload}
          onClick={() => {
            if (fileData.length > 0 && checkField !== CheckField.None)
              toast
                .promise(
                  Apis.insertPhiTee(
                    fileData.map(filterOutNonPhiTeeField),
                    checkField,
                    selectedSemester
                  ),
                  {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                  }
                )
                .then(() => {
                  setOkToUpload(false);
                })
                .catch(catchErrorForModal);
          }}
        >
          匯入資料
        </Button>
      </Row>
      {
        <Row>
          <EditableTable
            headers={headerDisplayKeys}
            values={fileData}
            scrollable={true}
            onRowRender={({ examDate, ...rest }) => ({
              rowClassName: rest.remark ? 'bg-danger text-white' : '',
            })}
          />
        </Row>
      }
    </AuthedLayout>
  );
};

export const ImportPhiTeePage = connector(importPhiTeePage);
