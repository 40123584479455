import { DateTime } from 'luxon';
import React, {
    ReactNode,
    FocusEvent,
    FunctionComponent,
    ReactElement,
    useEffect,
    useState,
} from 'react';
import {
    Accordion,
    Button,
    Col,
    Container,
    FormControl,
    Modal,
    Row,
} from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { connect, ConnectedProps } from 'react-redux';
import Apis from '../../../apis';
import {
    AuthedLayout,
    CheckBoxField,
    CustomAccordionToggle,
    DateTimeField,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
    InputDropdownGroupField,
    SightManageSheet,
} from '../../../components';
import { PHISight, Sight, StudentGradeNoSeat } from '../../../model';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { Nullable } from '../../../types';
import { ClassSem, WrapClassBaseLayout, WrapGradeBaseLayout } from '../../../layouts';
import { ApplicationState } from '../../../redux/States';
import {
    DefaultSightManageMap,
    denormalizeStudentSight,
    normalizeStudentSight,
    SightCalculators,
    SightPropertyFields,
    SightTableHeader,
} from './../../TableHeaders/SightHeader';
import { useClassGrade, useSemGrade } from '../../../hook';
import { sightDiag } from '../../../utils/sight';
import { SheetHeaderDisplay } from '../../../utils';

type PageData = StudentGradeNoSeat & Nullable<PHISight>

const SightManageAccordionKey = '0';

const modalDisplayHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
    { property: 'pid', display: '統編' },
    { property: 'name', display: '學生' },
    {
        display: '性別', property: 'sex',
        onRender: (v) => (v === '1' ? '男' : '女'),
    },
    { property: 'grade', display: '年級' },
    { property: 'no', display: '班級' },
    { property: 'seat', display: '座號' },
    {
        display: '裸視右',
        property: 'sight0R',
        onRender: (value: unknown) => {
            if (typeof value === 'number') {
                return value % 1 === 0 ? value.toFixed(1) : value.toString();
            }
            return value as string;
        },
        onSheetRender: (value: unknown) => {
            if (typeof value === 'number') {
                return value % 1 === 0 ? value.toFixed(1) : value.toString();
            }
            return value as string;
        }
    },
    {
        display: '裸視左',
        property: 'sight0L',
        onRender: (value: unknown) => {
            if (typeof value === 'number') {
                return value % 1 === 0 ? value.toFixed(1) : value.toString();
            }
            return value as string;
        },
        onSheetRender: (value: unknown) => {
            if (typeof value === 'number') {
                return value % 1 === 0 ? value.toFixed(1) : value.toString();
            }
            return value as string;
        }
    },
    {
        display: '戴鏡右',
        property: 'sightR',
        onRender: (value: unknown) => {
            if (typeof value === 'number') {
                return value % 1 === 0 ? value.toFixed(1) : value.toString();
            }
            return value as string;
        },
        onSheetRender: (value: unknown) => {
            if (typeof value === 'number') {
                return value % 1 === 0 ? value.toFixed(1) : value.toString();
            }
            return value as string;
        }
    },
    {
        display: '戴鏡左',
        property: 'sightL',
        onRender: (value: unknown) => {
            if (typeof value === 'number') {
                return value % 1 === 0 ? value.toFixed(1) : value.toString();
            }
            return value as string;
        },
        onSheetRender: (value: unknown) => {
            if (typeof value === 'number') {
                return value % 1 === 0 ? value.toFixed(1) : value.toString();
            }
            return value as string;
        }
    },
];
const mapState = (state: ApplicationState, ownProps: ClassSem) => ({
    ...ownProps,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const sightUnCheck: FunctionComponent<Props> = ({
    year, sem,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const [students, setStudents] = useState<PageData[]>([]);
    const [inputing, setInputing] = useState(false);
    const [sightManageMap, setSightManageMap] = useState(DefaultSightManageMap);
    const [editingStudent, setEditingStudent] = useState<PageData>();
    const [manageIdStr, setManageIdStr] = useState('');

    useEffect(() => {
        if (yearSem) {
            Apis
                .getSightUnCheck(yearSem.year, yearSem.sem)
                .then((r) =>
                    setStudents(
                        r.map(({ sight, ...s }) => ({
                            ...sight,
                            ...s,
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [yearSem?.year, yearSem?.sem]);


    function onHide() {
        setInputing(false);
        setEditingStudent(undefined);
    }

    function onEdit(sight: PageData) {
        setInputing(true);
        setEditingStudent(sight);
        setManageIdStr(sight.manageID?.join(',') || '');
    }

    function normalizeManageId(source?: string): string[] {
        const manageIds = (source || '')
            .split(/[,]/i)
            .map((v) => v.trim())
            .filter((v) => !!sightManageMap[v]);
        return manageIds;
    }

    function onUpdateStudentSight(sight: PHISight) {
        if (editingStudent && editingStudent.pid && yearSem) {
            sight.sem = yearSem?.sem;
            sight.year = yearSem?.year;
            // const manageIds = normalizeManageId(manageIdStr);
            // sight.manageID = manageIds;
            toast
                .promise(Apis.InsertStudentSight(editingStudent?.pid, sight), {
                    pending: '視力資料上傳中......',
                    success: '視力資料上傳成功！',
                    error: '視力資料上傳失敗！請查看錯誤資訊。',
                })
                .then(() => onHide())
                .catch(catchErrorForModal);
        }
    }

    const rowClassName = 'pb-3 align-items-center';
    let modalContent = <React.Fragment />;
    if (inputing) {
        modalContent = (
            <Modal
                show={inputing}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
                dialogClassName="modal-dialog-full"
            >
                <Modal.Header closeButton onHide={onHide}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        視力數據 -{' '}
                        <strong>
                            {editingStudent?.seat} {editingStudent?.name}
                        </strong>
                    </Modal.Title>
                </Modal.Header>
                <Form
                    initialValues={
                        editingStudent ? denormalizeStudentSight(editingStudent) : undefined
                    }
                    decorators={SightCalculators}
                    onSubmit={(value) => {
                        onUpdateStudentSight({
                            ...value,
                            ...normalizeStudentSight(value),
                            id: 0,
                            sem,
                            year,
                            yearClassId: 0,
                        });
                    }}
                    subscription={{ submitting: true, pristine: true }}
                    render={(prop) => {
                        const { values, handleSubmit } = prop;
                        return (
                            <React.Fragment>
                                <Modal.Body className="text-center">
                                    <Container>
                                        <Row className={rowClassName}>
                                            {generateCheckedField('散瞳治療', 'isDilated')}
                                            {generateNumberField(
                                                '裸視右',
                                                SightPropertyFields.sight0R
                                            )}
                                            {generateNumberField(
                                                '裸視左',
                                                SightPropertyFields.sight0L
                                            )}
                                        </Row>
                                        <Row className={rowClassName}>
                                            <Col sm={4} />
                                            {generateNumberField(
                                                '戴鏡右',
                                                SightPropertyFields.sightR
                                            )}
                                            {generateNumberField(
                                                '戴鏡左',
                                                SightPropertyFields.sightL
                                            )}
                                        </Row>
                                        <Row className={rowClassName}>
                                            {generateCheckedField('近視', 'eNear')}
                                            {generateNumberField(
                                                '近視右',
                                                SightPropertyFields.eNearR
                                            )}
                                            {generateNumberField(
                                                '近視左',
                                                SightPropertyFields.eNearL
                                            )}
                                        </Row>
                                        <Row className={rowClassName}>
                                            {generateCheckedField('遠視', 'eFar')}
                                            {generateNumberField('遠視右', SightPropertyFields.eFarR)}
                                            {generateNumberField('遠視左', SightPropertyFields.eFarL)}
                                        </Row>
                                        <Row className={rowClassName}>
                                            {generateCheckedField('散光', 'eSan')}
                                            {generateNumberField('散光右', SightPropertyFields.eSanR)}
                                            {generateNumberField('散光左', SightPropertyFields.eSanL)}
                                        </Row>
                                        <Row className={rowClassName}>
                                            {generateCheckedField('散瞳後檢查', 'isDilating')}
                                            {generateCheckedField('弱視', 'eWeak')}
                                            {generateCheckedField('複檢無異狀', 'noProblem')}
                                        </Row>
                                        <Row className={rowClassName}>
                                            {generateCheckedField('其他', 'eSight99')}
                                            <Col sm="1">其他陳述:</Col>
                                            <Field name="eSight99State">
                                                {({ input, meta }) => (
                                                    <Col>
                                                        <FormControl {...input} type="text" />
                                                        {meta.error && meta.modified && !meta.active && (
                                                            <span className="text-danger">{meta.error}</span>
                                                        )}
                                                    </Col>
                                                )}
                                            </Field>
                                        </Row>
                                        <Row className={rowClassName}>
                                            <Col sm="1">處置代號:</Col>
                                            <Col sm="5">
                                                {SightPropertyFields.manageID(sightManageMap)}
                                            </Col>
                                            <Col sm="1">定期檢查:</Col>
                                            <Col sm="5">
                                                <DateTimeField property={'periodical'} />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button type="submit" onClick={handleSubmit}>
                                        儲存
                                    </Button>
                                    <Button type="reset" variant="secondary" onClick={onHide}>
                                        關閉
                                    </Button>
                                </Modal.Footer>
                            </React.Fragment>
                        );
                    }}
                />
            </Modal>
        );
    }
    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-3">
                            {semGradeElement}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    editable
                    headers={modalDisplayHeader}
                    values={students}
                    onEdit={onEdit}
                />
            </Row>
            {modalContent}
        </AuthedLayout>
    );
};

function generateNumberField(display: string, node: ReactNode): ReactElement {
    return (
        <React.Fragment>
            <Col sm="1">{display}:</Col>
            <Col sm="3">{node}</Col>
        </React.Fragment>
    );
}

function generateCheckedField(display: string, property: string): ReactElement {
    return (
        <React.Fragment>
            <Col sm="1">{display}:</Col>
            <Col sm="3">
                <CheckBoxField property={property} />
            </Col>
        </React.Fragment>
    );
}

export const SightUnCheck = connector(sightUnCheck);
