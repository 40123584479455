import { DateTime } from 'luxon';
import React, {
  FunctionComponent,
  useState,
  ReactNode,
  useRef,
  useEffect,
} from 'react';
import {
  Modal,
  Row,
  Button,
  Table,
  Col,
  DropdownButton,
  Dropdown,
  InputGroup,
  Form,
} from 'react-bootstrap';
import apis from '../../apis';
import {
  HeaderDisplayKeysWithType,
  InlineEditableTable,
  TextField,
  OverflowWithTooltip,
  InputDropdownGroupField,
} from '../../components';
import {
  PHISundryWithStudentId,
  Semester,
  Student,
  YearSemClasses,
} from '../../model';
import { toast } from 'react-toastify';

type Props = {
  editable: boolean;
  sundry: PHISundryWithStudentId[];
  student: Student;
  schoolId: string;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
};
const transformTextInput = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean
) => (e ? <TextField property={property} /> : wrapOverflow(v as string));
const transformInputDropdownField = (
  property: string,
  content: Record<number, string>,
  v: unknown,
  i: number,
  e?: boolean
) =>
  e ? (
    <InputDropdownGroupField
      type="number"
      content={content}
      property={property}
    />
  ) : (
    (v as string)
  );
export const checkKind = [
  {
    Id: 1,
    Checks: '心臟病篩檢',
  },
  {
    Id: 2,
    Checks: '胸部X光',
  },
  {
    Id: 3,
    Checks: '肝功能',
  },
  {
    Id: 4,
    Checks: '腎功能',
  },
  {
    Id: 5,
    Checks: '蛔蟲',
  },
  {
    Id: 6,
    Checks: '蟯蟲',
  },
  {
    Id: 7,
    Checks: 'A型肝炎',
  },
  {
    Id: 8,
    Checks: 'B型肝炎',
  },
  {
    Id: 9,
    Checks: '結核菌素測驗',
  },
  {
    Id: 10,
    Checks: '頭蝨',
  },
  {
    Id: 11,
    Checks: '尿液',
  },
  {
    Id: 12,
    Checks: '心電圖',
  },
  {
    Id: 13,
    Checks: '心臟超音波',
  },
];
const monthsObj = {
  sem1: [
    {
      id: 1,
      text: '1月',
    },
    {
      id: 8,
      text: '8月',
    },
    {
      id: 9,
      text: '9月',
    },
    {
      id: 10,
      text: '10月',
    },
    {
      id: 11,
      text: '11月',
    },
    {
      id: 12,
      text: '12月',
    },
  ],
  sem2: [
    {
      id: 2,
      text: '2月',
    },
    {
      id: 3,
      text: '3月',
    },
    {
      id: 4,
      text: '4月',
    },
    {
      id: 5,
      text: '5月',
    },
    {
      id: 6,
      text: '6月',
    },
    {
      id: 7,
      text: '7月',
    },
  ],
};
const ExaminationResult = {
  0: '無異狀',
  1: '初檢異常',
  2: '複診正常',
  3: '複診異常',
} as const;
const inlineSundryTableHeader: HeaderDisplayKeysWithType<PHISundryWithStudentId>[] =
  [
    { property: 'studentId', display: '統編' },
    {
      property: 'sundryId',
      display: '健檢',
      onRender: (value: unknown) => {
        if (value) {
          return typeof value == 'number'
            ? checkKind[value - 1].Checks
            : '無檢查項目';
        }
      },
    },
    {
      property: 'examDate',
      display: '檢查日期',
      onRender: (value: unknown) => {
        if (value) {
          return value.toLocaleString();
        }
      },
    },
    {
      property: 'sundryResult',
      display: '檢查結果',
      onRender: transformInputDropdownField.bind(
        null,
        'sundryResult',
        ExaminationResult
      ),
    },
    {
      property: 'followUpDescription',
      display: '複檢說明',
      onRender: transformTextInput.bind(null, 'followUpDescription'),
    },
    {
      property: 'agencies',
      display: '檢查單位',
      onRender: transformTextInput.bind(null, 'agencies'),
    },
    {
      property: 'remark',
      display: '備註',
      onRender: transformTextInput.bind(null, 'remark'),
    },
  ];
function wrapOverflow(content: ReactNode) {
  return <OverflowWithTooltip>{content}</OverflowWithTooltip>;
}

export const SundrySection: FunctionComponent<Props> = ({
  editable,
  sundry,
  student,
  schoolId,
  semesters,
  yearSems,
  onApiError,
  onValueUpdated,
}) => {
  const currentSemester = semesters.find((s) => s.isNow);
  const [inputing, setInputing] = useState(false);
  const [sundryId, setSundryId] = useState<number>();
  const [month, setMonth] = useState<number>();
  const [monthText, setMonthText] = useState<string>('');
  const [dates, setDates] = useState<string[]>([]);
  const [date, setDate] = useState<number>(0);
  const agencyInputRef = useRef<HTMLInputElement>(null);
  const semMonths =
    currentSemester?.sem == 1 ? monthsObj['sem1'] : monthsObj['sem2'];
  const currentYear = new Date().getFullYear();

  yearSems.filter(
    (s) => !sundry.some((wh) => wh.sem === s.sem && wh.year === s.year)
  );
  // .forEach(({ sem, year, yearClassId }) =>
  //   sundry.push({ year, sem, id: 0, yearClassId })
  // );

  useEffect(() => {
    if (agencyInputRef.current) {
      checkField();
    }
  }, [agencyInputRef.current]);

  useEffect(() => {
    if (month) {
      const daysInMonth = new Date(currentYear, month, 0).getDate();
      setDates(Array.from({ length: daysInMonth }, (v, i) => i + 1 + '日'));
    }
  }, [sundryId, month]);

  useEffect(() => {
    if (agencyInputRef.current) {
      checkField();
    }
  }, [agencyInputRef.current]);

  sundry.sort((a, b) => a.sem - b.sem + (a.year - b.year) * 100);

  function onValueUpdate(value: PHISundryWithStudentId) {
    apis
      .updateStudentSundry(
        value.year,
        value.sem,
        value.id,
        value.grade,
        value.sundryId,
        value.agencies,
        value.sundryResult,
        value.yearClassId,
        value.examDate?.toString() || '',
        value.followUpDescription || '',
        value.remark || ''
      )
      .catch(onApiError)
      .then(onValueUpdated);
  }
  function onValueDelete(value: PHISundryWithStudentId) {
    apis
      .deleteStudentSundry(
        value.year,
        value.sem,
        value.id,
        value.grade,
        value.sundryId,
        value.agencies,
        value.sundryResult,
        value.yearClassId,
        value.examDate?.toString() || '',
        value.followUpDescription || '',
        value.remark || ''
      )
      .then(onValueUpdated)
      .catch(onApiError);
  }

  // 檢查欄位是否填寫
  function checkField() {
    // console.log('sundryId',sundryId)
    // console.log('month',month)
    // console.log('date',date)
    if (!sundryId) {
      return true;
    } else if (!month) {
      return true;
    } else if (!date) {
      return true;
    } else {
      return false;
    }
  }
  async function getInsertData(
    month: number,
    date: number,
    check: number,
    agency: string
  ) {
    checkField();
    const examDate = new Date(currentYear, month - 1, date)
      .toLocaleString('sv')
      .slice(0, 10);
    const agencyInput = agencyInputRef.current?.value ?? '';

    // 新增臨時性檢查api
    // 檢查有無重複檢查項目
    if (sundry.some((s) => s.sundryId == check)) {
      toast.error('重複新增');
    } else {
      toast
        .promise(
          apis.insertStudentSundry(
            currentSemester?.year || 0,
            currentSemester?.sem || 0,
            check,
            agencyInput,
            0,
            examDate,
            '',
            '',
            yearSems.filter(
              (y) =>
                y.year === currentSemester?.year &&
                y.sem === currentSemester.sem
            )[0].yearClassId
          ),
          {
            pending: '資料新增中......',
            success: '新增成功！',
            error: '新增失敗！請查看錯誤資訊。',
          }
        )
        .then(() => {
          onValueUpdated();
          // 清空檢查項目、檢查日期、檢查單位欄位
          setSundryId(0);
          setMonthText('');
          setDate(0);
          if (agencyInputRef.current) {
            agencyInputRef.current.value = '';
          }
        })
        .catch(onApiError);
    }
  }

  return (
    <>
      <div className="sheet-title">
        臨時性檢查
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
          disabled={!editable}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Table bordered className="student-phi mb-2">
        <tbody>
          {sundry.length ? (
            sundry.map((v, i) => {
              return (
                <tr key={v.id}>
                  <th>{checkKind[v.sundryId - 1].Checks}</th>
                  <td>
                    {v.sundryResult == 0
                      ? '-'
                      : '+ ' + (v.followUpDescription ?? '')}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr key={0}>
              <td colSpan={2}>無資料</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            臨時性檢查數據 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineEditableTable
            values={sundry}
            headers={inlineSundryTableHeader}
            onValueUpdate={onValueUpdate}
            onValueDelete={onValueDelete}
            onRowRender={(v) => ({
              editable:
                editable &&
                schoolId == v.schoolId &&
                semesters.some((s) => s.sem === v.sem && s.year === v.year),
              deleteable:
                editable &&
                schoolId == v.schoolId &&
                semesters.some((s) => s.sem === v.sem && s.year === v.year),
            })}
          />
          <Row className="align-items-center mb-3 mx-0">
            <Col sm={3} className="d-flex align-items-center">
              檢查項目：
              <DropdownButton
                className=""
                title={sundryId ? checkKind[sundryId - 1].Checks : '檢查項目'}
                onSelect={(c) => {
                  if (c) {
                    setSundryId(parseInt(c));
                  }
                }}
              >
                <Dropdown.Item key={`check-${1}`} eventKey={1}>
                  心臟病篩檢
                </Dropdown.Item>
                <Dropdown.Item key={`check-${2}`} eventKey={2}>
                  胸部X光
                </Dropdown.Item>
                <Dropdown.Item key={`check-${3}`} eventKey={3}>
                  肝功能
                </Dropdown.Item>
                <Dropdown.Item key={`check-${4}`} eventKey={4}>
                  腎功能
                </Dropdown.Item>
                <Dropdown.Item key={`check-${5}`} eventKey={5}>
                  蛔蟲
                </Dropdown.Item>
                <Dropdown.Item key={`check-${6}`} eventKey={6}>
                  蟯蟲
                </Dropdown.Item>
                <Dropdown.Item key={`check-${7}`} eventKey={7}>
                  A型肝炎
                </Dropdown.Item>
                <Dropdown.Item key={`check-${8}`} eventKey={8}>
                  B型肝炎
                </Dropdown.Item>
                <Dropdown.Item key={`check-${9}`} eventKey={9}>
                  結核菌素測驗
                </Dropdown.Item>
                <Dropdown.Item key={`check-${10}`} eventKey={10}>
                  頭蝨
                </Dropdown.Item>
                <Dropdown.Item key={`check-${11}`} eventKey={11}>
                  尿液
                </Dropdown.Item>
                <Dropdown.Item key={`check-${12}`} eventKey={12}>
                  心電圖
                </Dropdown.Item>
                <Dropdown.Item key={`check-${13}`} eventKey={13}>
                  心臟超音波
                </Dropdown.Item>
              </DropdownButton>
            </Col>
            <Col sm={4} className="d-flex align-items-center">
              檢查日期：
              <DropdownButton
                className="mr-2"
                title={monthText || '檢查月'}
                onSelect={(g) => {
                  if (g) {
                    const selectMonth = semMonths[parseInt(g)];
                    setMonth(selectMonth.id);
                    setMonthText(
                      currentSemester?.year + '年' + selectMonth.text
                    );
                  }
                }}
              >
                {semMonths.map((month, idx) => (
                  <Dropdown.Item key={`month-${idx}`} eventKey={idx}>
                    {currentSemester?.year}年{month.text}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <DropdownButton
                className=""
                title={date ? dates[date - 1] : '檢查日'}
                onSelect={(d) => {
                  if (d) {
                    setDate(parseInt(d));
                  }
                }}
              >
                {dates.map((d, idx) => (
                  <Dropdown.Item key={`date-${idx + 1}`} eventKey={idx + 1}>
                    {d}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
            <Col sm={2}>
              <InputGroup>
                <Form.Control
                  className="mr-2"
                  placeholder="檢查單位"
                  aria-label="Agency"
                  aria-describedby="basic-addon1"
                  ref={agencyInputRef}
                />
              </InputGroup>
            </Col>
            <Col sm={1} className="text-right">
              <Button
                disabled={checkField() == true}
                onClick={() =>
                  getInsertData(month || 1, date || 1, sundryId || 1, '')
                }
              >
                新增
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
