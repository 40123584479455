import { z } from 'zod';
import { ExaminedResultEnum, ExaminedResultEnumZod } from './Enums';
import {
  CheckNumber,
  CheckStudentBaseData,
  checkExaminedResultEnum,
  CheckNumberRange,
  CheckBloodNumberRange,
} from '../utils/checkFormat';
import { PhysicalSetting} from '../model';
import {
  CheckShouldUseIdentify,
} from '../utils';
import apis from '../apis';
type physicalSettingObject = {
  [key: number]: PhysicalSetting;
};

export const PhiBloodZod = z.object({
  sem: z.number(),
  year: z.number(),
  hb: z.number().nullish(),
  wbc: z.number().nullish(),
  rbc: z.number().nullish(),
  pl: z.number().nullish(),
  mcv: z.number().nullish(),
  ht: z.number().nullish(),
  cl: z.number().nullish(),
  cr: z.number().nullish(),
  ua: z.number().nullish(),
  bun: z.number().nullish(),
  got: z.number().nullish(),
  gpt: z.number().nullish(),
  hbsa: z.number().nullish(),
  antiHB: z.number().nullish(),
  hbResult: ExaminedResultEnumZod,
  wbcResult: ExaminedResultEnumZod,
  rbcResult: ExaminedResultEnumZod,
  plResult: ExaminedResultEnumZod,
  mcvResult: ExaminedResultEnumZod,
  htResult: ExaminedResultEnumZod,
  clResult: ExaminedResultEnumZod,
  crResult: ExaminedResultEnumZod,
  uaResult: ExaminedResultEnumZod,
  bunResult: ExaminedResultEnumZod,
  gotResult: ExaminedResultEnumZod,
  gptResult: ExaminedResultEnumZod,
  hbsaResult: ExaminedResultEnumZod,
  antiHBResult: ExaminedResultEnumZod,
  bloodCheckMemo: z.string().nullish(),
  tgResult: ExaminedResultEnumZod,
  tg: z.number().nullish(),
  hdlResult: ExaminedResultEnumZod,
  hdl: z.number().nullish(),
  ldlResult: ExaminedResultEnumZod,
  ldl: z.number().nullish(),
  acResult: ExaminedResultEnumZod,
  ac: z.number().nullish(),
  pcResult: ExaminedResultEnumZod,
  pc: z.number().nullish(),
  hBeAgResult: ExaminedResultEnumZod,
  hBeAg: z.number().nullish(),
});

export const PhiBloodFileZod = z
  .object({
    身分證: z.string().nullish(),
    學號: z.string().nullish(),
    年級班級座號: z.string().nullish(),
    血色素: z.string().nullish(),
    血色素判讀: z.string().nullish(),
    白血球: z.string().nullish(),
    白血球判讀: z.string().nullish(),
    紅血球: z.string().nullish(),
    紅血球判讀: z.string().nullish(),
    血小板: z.string().nullish(),
    血小板判讀: z.string().nullish(),
    平均血球容積: z.string().nullish(),
    平均血球容積判讀: z.string().nullish(),
    總膽固醇: z.string().nullish(),
    總膽固醇判讀: z.string().nullish(),
    肌酸酐: z.string().nullish(),
    肌酸酐判讀: z.string().nullish(),
    尿酸: z.string().nullish(),
    尿酸判讀: z.string().nullish(),
    SGOT: z.string().nullish(),
    SGOT判讀: z.string().nullish(),
    SGPT: z.string().nullish(),
    SGPT判讀: z.string().nullish(),
    B型肝炎表面抗原: z.string().nullish(),
    B型肝炎表面抗原判讀: z.string().nullish(),
    B型肝炎表面抗體: z.string().nullish(),
    B型肝炎表面抗體判讀: z.string().nullish(),
    三酸甘油酯: z.string().nullish(),
    三酸甘油酯判讀: z.string().nullish(),
    高密度膽固醇: z.string().nullish(),
    高密度膽固醇判讀: z.string().nullish(),
    低密度膽固醇: z.string().nullish(),
    低密度膽固醇判讀: z.string().nullish(),
    空腹血糖: z.string().nullish(),
    空腹血糖判讀: z.string().nullish(),
    飯後血糖: z.string().nullish(),
    飯後血糖判讀: z.string().nullish(),
    B型肝炎e抗原: z.string().nullish(),
    B型肝炎e抗原判讀: z.string().nullish(),
    血尿素氮: z.string().nullish(),
    血尿素氮判讀: z.string().nullish(),
    血球容積比: z.string().nullish(),
    血球容積比判讀: z.string().nullish(),
    備註: z.string().nullish(),
  })
  .transform(async(arg) => {
    let _errorMsg = '';
    let _okUpload = true;
    let bloodSettingsData:string[] = [];
    let studentGrade = 0;

    //檢查【身分證】、【學號】、【年級班級座號】格式
    const checkStudentBaseDataResult = CheckStudentBaseData(
      arg.身分證,
      arg.學號,
      arg.年級班級座號
    );
    const _pid = checkStudentBaseDataResult.pid;
    const _sid = checkStudentBaseDataResult.sid;
    const _grade = checkStudentBaseDataResult.grade;
    const _no = checkStudentBaseDataResult.no;
    const _seat = checkStudentBaseDataResult.seat;
    if (!checkStudentBaseDataResult.okUpload) {
      _okUpload = checkStudentBaseDataResult.okUpload;
      _errorMsg += checkStudentBaseDataResult.errorMsg;
    }

    if(checkStudentBaseDataResult.okUpload) {
      // 取得學生年級
      await getStudentGrade();
      // 取得追加設定
      await apis.getPhysicalSettings().then((s) => {
        const settingData: physicalSettingObject = s.reduce((acc: physicalSettingObject, item: PhysicalSetting) => {
          acc[(item.grade)] = item;
          return acc;
        }, {});
        bloodSettingsData = settingData[studentGrade] ? settingData[studentGrade].bloodAdditional : [];
      });
    }
    
    async function getStudentGrade() {
      const student = [{
        pid: _pid,
        sid: _sid,
        grade: _grade,
        no: _no,
        seat: _seat,
      }];
      const [cf, matchFunc] = CheckShouldUseIdentify(student);
      await apis.checkClassesStudentExistsWithGrade(
        student,
        cf
      )
      .then((result) => {
          if(result.grade) {
            studentGrade = result.grade;
          }
      })  
      .catch((e) => {
        console.log('error',e)
      });
    }

    //血色素判讀
    const { code: hb, ...hbCheck } = CheckBloodNumberRange(arg.血色素, 0, { min: 4, max: 20 }, '血色素');
    if(Number.isNaN(hb)) {
      _errorMsg+= '【血色素】格式有誤，';
    } else if(hbCheck.errorMsg) {
      _errorMsg += hbCheck.errorMsg;
      _okUpload = _okUpload && hbCheck.okUpload;
    }
    const hbResultCheck = checkExaminedResultEnum(
      arg.血色素判讀,
      '血色素判讀'
    );
    if(hbResultCheck.isNull) {
      hbResultCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += hbResultCheck.errorMsg;
      _okUpload = _okUpload && hbResultCheck.okUpload;
    }
    const _hbResult = hbResultCheck.code;
    const { code: wbc, ...wbcCheck } = CheckBloodNumberRange(
      arg.白血球 || (arg as any)['白血球 '],
      0,
      { min: 0, max: 400 },
      '白血球'
    );
    if(Number.isNaN(wbc)) {
      _errorMsg+= '【白血球】格式有誤，';
    } else if(wbcCheck.errorMsg) {
      _errorMsg += wbcCheck.errorMsg;
      _okUpload = _okUpload && wbcCheck.okUpload;
    }
    const wbcResultCheck = checkExaminedResultEnum(
      arg.白血球判讀,
      '白血球判讀'
    );
    if(wbcResultCheck.isNull) {
      wbcResultCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += wbcResultCheck.errorMsg;
      _okUpload = _okUpload && wbcResultCheck.okUpload;
    }
    const _wbcResult = wbcResultCheck.code;
    const { code: rbc, ...rbcCheck } = CheckBloodNumberRange(
      arg.紅血球 || (arg as any)['紅血球 '],
      0,
      { min: 0, max: 60 },
      '紅血球'
    );
    if(Number.isNaN(rbc)) {
      _errorMsg+= '【紅血球】格式有誤，';
    } else if(rbcCheck.errorMsg) {
      _errorMsg += rbcCheck.errorMsg;
      _okUpload = _okUpload && rbcCheck.okUpload;
    }
    const rbcResultCheck = checkExaminedResultEnum(
      arg.紅血球判讀,
      '紅血球判讀'
    );
    if(rbcResultCheck.isNull) {
      rbcResultCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += rbcResultCheck.errorMsg;
      _okUpload = _okUpload && rbcResultCheck.okUpload;
    }
    const _rbcResult = rbcResultCheck.code;
    const { code: pl, ...plCheck } = CheckBloodNumberRange(arg.血小板, 0, {min: 0, max: 1000}, '血小板');
    if(Number.isNaN(pl)) {
      _errorMsg+= '【血小板】格式有誤，';
    } else if(plCheck.errorMsg) {
      _errorMsg += plCheck.errorMsg;
      _okUpload = _okUpload && plCheck.okUpload;
    }
    const plResultCheck = checkExaminedResultEnum(
      arg.血小板判讀,
      '血小板判讀'
    );
    if(plResultCheck.isNull) {
      plResultCheck.code = ExaminedResultEnum.NoProblem;
    } else{
      _errorMsg += plResultCheck.errorMsg;
      _okUpload = _okUpload && plResultCheck.okUpload;
    }
    const _plResult = plResultCheck.code;
    const { code: mcv, ...mcvCheck } = CheckBloodNumberRange(
      arg.平均血球容積,
      0,
      { min: 50, max: 200 },
      '平均血球容積'
    );
    if(Number.isNaN(mcv)) {
      _errorMsg+= '【平均血球容積】格式有誤，';
    } else if(mcvCheck.errorMsg) {
      _errorMsg += mcvCheck.errorMsg;
      _okUpload = _okUpload && mcvCheck.okUpload;
    }
    const mcvCheckResult = checkExaminedResultEnum(
      arg.平均血球容積判讀,
      '平均血球容積判讀'
    );
    if(mcvCheckResult.isNull) {
      mcvCheckResult.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += mcvCheckResult.errorMsg;
      _okUpload = _okUpload && mcvCheckResult.okUpload;
    }
    const _mcvResult = mcvCheckResult.code;
    const { code: cl, ...clCheck } = CheckBloodNumberRange(arg.總膽固醇, 0, { min: 0, max: 2000 },'總膽固醇');
    if(Number.isNaN(cl)) {
      _errorMsg+= '【總膽固醇】格式有誤，';
    } else if(clCheck.errorMsg) {
      _errorMsg += clCheck.errorMsg;
      _okUpload = _okUpload && clCheck.okUpload;
    }
    const clResultCheck = checkExaminedResultEnum(
      arg.總膽固醇判讀,
      '總膽固醇判讀'
    );
    if(clResultCheck.isNull) {
      clResultCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += clResultCheck.errorMsg;
      _okUpload = _okUpload && clResultCheck.okUpload;
    }
    const _clResult = clResultCheck.code;
    const { code: cr, ...crCheck } = CheckBloodNumberRange(
      arg.肌酸酐 || (arg as any)['肌酸酐 '],
      0,
      { min: 0, max: 20 },
      '肌酸酐'
    );
    if(Number.isNaN(cr)) {
      _errorMsg+= '【肌酸酐】格式有誤，';
    } else if(crCheck.errorMsg) {
      _errorMsg += crCheck.errorMsg;
      _okUpload = _okUpload && crCheck.okUpload;
    }
    const crResultCheck = checkExaminedResultEnum(
      arg.肌酸酐判讀,
      '肌酸酐判讀'
    );
    if(crResultCheck.isNull) {
      crResultCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += crResultCheck.errorMsg;
      _okUpload = _okUpload && crResultCheck.okUpload;
    }
    const _crResult = crResultCheck.code;
    const { code: ua, ...uaCheck } = CheckBloodNumberRange(arg.尿酸, 0, { min: 0, max: 20 }, '尿酸');
    if(Number.isNaN(ua)) {
      _errorMsg+= '【尿酸】格式有誤，';
    } else if(uaCheck.errorMsg) {
      _errorMsg += uaCheck.errorMsg;
      _okUpload = _okUpload && uaCheck.okUpload;
    }
    const uaResultCheck = checkExaminedResultEnum(
      arg.尿酸判讀,
      '尿酸判讀'
    );
    if(uaResultCheck.isNull) {
      uaResultCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += uaResultCheck.errorMsg;
      _okUpload = _okUpload && uaResultCheck.okUpload;
    }
    const _uaResult = uaResultCheck.code;
    const SGOTCheck = CheckBloodNumberRange(arg.SGOT, 0, { min: 0, max: 3000 },'SGOT');
    if(Number.isNaN(SGOTCheck.code)) {
      _errorMsg+= '【SGOT】格式有誤，';
    } else if(SGOTCheck.errorMsg) {
      _errorMsg += SGOTCheck.errorMsg;
      _okUpload = _okUpload && SGOTCheck.okUpload;
    }
    const _SGOT = SGOTCheck.code;
    const SGOTResultCheck = checkExaminedResultEnum(
      arg.SGOT判讀,
      'SGOT判讀'
    );
    if(SGOTResultCheck.isNull) {
      SGOTResultCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += SGOTResultCheck.errorMsg;
      _okUpload = _okUpload && SGOTResultCheck.okUpload;
    }
    const _SGOTResult = SGOTResultCheck.code;
    const SGPTCheck = CheckBloodNumberRange(arg.SGPT, 0, { min: 0, max: 3000 }, 'SGPT');
    if(Number.isNaN(SGPTCheck.code)) {
      _errorMsg+= '【SGPT】格式有誤，';
    } else if(SGPTCheck.errorMsg) {
      _errorMsg += SGPTCheck.errorMsg;
      _okUpload = _okUpload && SGPTCheck.okUpload;
    }
    const _SGPT = SGPTCheck.code;
    const SGPTResultCheck = checkExaminedResultEnum(
      arg.SGPT判讀,
      'SGPT判讀'
    );
    if(SGPTResultCheck.isNull) {
      SGPTResultCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += SGPTResultCheck.errorMsg;
      _okUpload = _okUpload && SGPTResultCheck.okUpload;
    }
    const _SGPTResult = SGPTResultCheck.code;
    const { code: hbsa, ...hbsaCheck } = CheckBloodNumberRange(
      arg.B型肝炎表面抗原,
      0,
      { min: 0, max: 999999.99994 },
      'B型肝炎表面抗原'
    );
    if(Number.isNaN(hbsa)) {
      _errorMsg+= '【B型肝炎表面抗原】格式有誤，';
    }
    _errorMsg += hbsaCheck.errorMsg;
    _okUpload = _okUpload && hbsaCheck.okUpload;
    const hbsaResultCheck = checkExaminedResultEnum(
      arg.B型肝炎表面抗原判讀,
      'B型肝炎表面抗原判讀'
    );
    if(hbsaResultCheck.isNull) {
      hbsaResultCheck.code = ExaminedResultEnum.NoProblem;
    } else {
      _errorMsg += hbsaResultCheck.errorMsg;
      _okUpload = _okUpload && hbsaResultCheck.okUpload;
    }
    const _hbsaResult = hbsaResultCheck.code;
    const { code: antiHB, ...antihbCheck } = CheckBloodNumberRange(
      arg.B型肝炎表面抗體,
      0,
      { min: 0, max: 999999.99994 },
      'B型肝炎表面抗體'
    );
    if(Number.isNaN(antiHB)) {
      _errorMsg+= '【B型肝炎表面抗體】格式有誤，';
    } else if(antihbCheck.errorMsg) {
      _errorMsg += antihbCheck.errorMsg;
      _okUpload = _okUpload && antihbCheck.okUpload;
    }
    const antihbResultCheck =
      checkExaminedResultEnum(arg.B型肝炎表面抗體判讀, 'B型肝炎表面抗體判讀');
      if(antihbResultCheck.isNull) {
        antihbResultCheck.code = ExaminedResultEnum.NoProblem;
      } else {
        _errorMsg += antihbResultCheck.errorMsg;
        _okUpload = _okUpload && antihbResultCheck.okUpload;
      }
    const _antihbResult = antihbResultCheck.code;
    const tgCheck = CheckBloodNumberRange(
      arg.三酸甘油酯,
      0,
      { min: 0, max: 999999.99994 },
      '三酸甘油酯'
    );
    if(Number.isNaN(tgCheck.code)) {
      _errorMsg+= '【三酸甘油酯】格式有誤，';
    }
    if(bloodSettingsData.includes('1')) { // 若有追加項目 欄位需輸入值
      _errorMsg += tgCheck.errorMsg;
      _okUpload = _okUpload && tgCheck.okUpload;
    } else {
      tgCheck.code = 0;
    }
    const _tg = tgCheck.code;
    const tgResultCheck = checkExaminedResultEnum(
      arg.三酸甘油酯判讀,
      '三酸甘油酯判讀'
    );
    if(tgResultCheck.isNull) {
      // 若有追加項目 顯示0無異常
      if(bloodSettingsData.includes('1')) {
        tgResultCheck.code = ExaminedResultEnum.NoProblem;   
      } else {
        // 若無追加項目 顯示9未受檢
        tgResultCheck.code = ExaminedResultEnum.NoExamined;
      }
    } else if(!tgResultCheck.okUpload && !tgResultCheck.isNull){
      _errorMsg += tgResultCheck.errorMsg;
      _okUpload = _okUpload && tgResultCheck.okUpload;
    }
    const _tgResult = tgResultCheck.code;
    const hdlCheck = CheckBloodNumberRange(
      arg.高密度膽固醇,
      0,
      { min: 0, max: 999999.99994 },
      '高密度膽固醇'
    );
    if(Number.isNaN(hdlCheck.code)) {
      _errorMsg+= '【高密度膽固醇】格式有誤，';
    }
    if(bloodSettingsData.includes('2')) { // 若有追加項目 欄位需輸入值
      _errorMsg += hdlCheck.errorMsg;
      _okUpload = _okUpload && hdlCheck.okUpload;
    } else {
      hdlCheck.code = 0;
    }
    const _hdl = hdlCheck.code;
    const hdlResultCheck = checkExaminedResultEnum(
      arg.高密度膽固醇判讀,
      '高密度膽固醇判讀'
    );
    if(hdlResultCheck.isNull) {
      // 若有追加項目 顯示0無異常
      if(bloodSettingsData.includes('2')) {
        hdlResultCheck.code = ExaminedResultEnum.NoProblem;   
      } else {
        // 若無追加項目 顯示9未受檢
        hdlResultCheck.code = ExaminedResultEnum.NoExamined;
      }
    } else if(!hdlResultCheck.okUpload && !hdlResultCheck.isNull){
      _errorMsg += hdlResultCheck.errorMsg;
      _okUpload = _okUpload && hdlResultCheck.okUpload;
    }
    const _hdlResult = hdlResultCheck.code;
    const ldlCheck = CheckBloodNumberRange(
      arg.低密度膽固醇,
      0,
      { min: 0, max: 999999.99994 },
      '低密度膽固醇'
    );
    if(Number.isNaN(ldlCheck.code)) {
      _errorMsg+= '【低密度膽固醇】格式有誤，';
    }
    if(bloodSettingsData.includes('3')) { // 若有追加項目 欄位需輸入值
      _errorMsg += ldlCheck.errorMsg;
      _okUpload = _okUpload && ldlCheck.okUpload;
    } else {
      ldlCheck.code = 0;
    }
    const _ldl = ldlCheck.code;
    const ldlResultCheck = checkExaminedResultEnum(
      arg.低密度膽固醇判讀,
      '低密度膽固醇判讀'
    );
    if(ldlResultCheck.isNull) {
      // 若有追加項目 顯示0無異常
      if(bloodSettingsData.includes('3')) {
        ldlResultCheck.code = ExaminedResultEnum.NoProblem;   
      } else {
        // 若無追加項目 顯示9未受檢
        ldlResultCheck.code = ExaminedResultEnum.NoExamined;
      }
    } else if(!ldlResultCheck.okUpload && !ldlResultCheck.isNull){
      _errorMsg += ldlResultCheck.errorMsg;
      _okUpload = _okUpload && ldlResultCheck.okUpload;
    }
    const _ldlResult = ldlResultCheck.code;
    const pcCheck = CheckBloodNumberRange(arg.空腹血糖, 0, { min: 0, max: 999999.99994 }, '空腹血糖');
    if(Number.isNaN(pcCheck.code)) {
      _errorMsg+= '【空腹血糖】格式有誤，';
    }
    if(bloodSettingsData.includes('5')) { // 若有追加項目 欄位需輸入值
      _errorMsg += pcCheck.errorMsg;
      _okUpload = _okUpload && pcCheck.okUpload;
    } else {
      pcCheck.code = 0;
    }
    const _pc = pcCheck.code;
    const pcResultCheck = checkExaminedResultEnum(
      arg.空腹血糖判讀,
      '空腹血糖判讀'
    );
    if(pcResultCheck.isNull) {
      // 若有追加項目 顯示0無異常
      if(bloodSettingsData.includes('5')) {
        pcResultCheck.code = ExaminedResultEnum.NoProblem;   
      } else {
        // 若無追加項目 顯示9未受檢
        pcResultCheck.code = ExaminedResultEnum.NoExamined;
      }
    } else if(!pcResultCheck.okUpload && !pcResultCheck.isNull){
      _errorMsg += pcResultCheck.errorMsg;
      _okUpload = _okUpload && pcResultCheck.okUpload;
    }
    const _pcResult = pcResultCheck.code;
    const acCheck = CheckBloodNumberRange(arg.飯後血糖, 0, { min: 0, max: 999999.99994 },'飯後血糖');
    if(Number.isNaN(acCheck.code)) {
      _errorMsg+= '【飯後血糖】格式有誤，';
    }
    if(bloodSettingsData.includes('4')) { // 若有追加項目 欄位需輸入值
      _errorMsg += acCheck.errorMsg;
      _okUpload = _okUpload && acCheck.okUpload;
    } else {
      acCheck.code = 0;
    }
    const _ac = acCheck.code;
    const acResultCheck = checkExaminedResultEnum(
      arg.飯後血糖判讀,
      '飯後血糖判讀'
    );
    if(acResultCheck.isNull) {
      // 若有追加項目 顯示0無異常
      if(bloodSettingsData.includes('4')) {
        acResultCheck.code = ExaminedResultEnum.NoProblem;   
      } else {
        // 若無追加項目 顯示9未受檢
        acResultCheck.code = ExaminedResultEnum.NoExamined;
      }
    } else if(!acResultCheck.okUpload && !acResultCheck.isNull){
      _errorMsg += acResultCheck.errorMsg;
      _okUpload = _okUpload && acResultCheck.okUpload;
    }
    const _acResult = acResultCheck.code;
    const hbeagCheck = CheckBloodNumberRange(
      arg.B型肝炎e抗原,
      0,
      { min: 0, max: 999999.99994 },
      'B型肝炎e抗原'
    );
    if(Number.isNaN(hbeagCheck.code)) {
      _errorMsg+= '【B型肝炎e抗原】格式有誤，';
    }
    if(bloodSettingsData.includes('6')) {
      _errorMsg += hbeagCheck.errorMsg;
      _okUpload = _okUpload && hbeagCheck.okUpload;
    }
    const _hbeag = hbeagCheck.code;
    const hbeagResultCheck = checkExaminedResultEnum(
      arg.B型肝炎e抗原判讀,
      'B型肝炎e抗原判讀'
    );
    if(bloodSettingsData.includes('6') && hbeagResultCheck.isNull) {
      hbeagResultCheck.code = ExaminedResultEnum.NoProblem;
    }
    const _hbeagResult = hbeagResultCheck.code;
    const bunCheck = CheckBloodNumberRange(arg.血尿素氮, 0, { min: 0, max: 999999.99994 }, '血尿素氮');
    if(Number.isNaN(bunCheck.code)) {
      _errorMsg+= '【血尿素氮】格式有誤，';
    }
    if(bloodSettingsData.includes('7')) {
      _errorMsg += bunCheck.errorMsg;
      _okUpload = _okUpload && bunCheck.okUpload;
    }
    const _bun = bunCheck.code;
    const bunResultCheck = checkExaminedResultEnum(
      arg.血尿素氮判讀,
      '血尿素氮判讀'
    );
    if(bunResultCheck.isNull) {
      // 若有追加項目 顯示0無異常
      if(bloodSettingsData.includes('7')) {
        bunResultCheck.code = ExaminedResultEnum.NoProblem;   
      } else {
        // 若無追加項目 顯示9未受檢
        bunResultCheck.code = ExaminedResultEnum.NoExamined;
      }
    } else if(!bunResultCheck.okUpload && !bunResultCheck.isNull){
      _errorMsg += bunResultCheck.errorMsg;
      _okUpload = _okUpload && bunResultCheck.okUpload;
    }
    const _bunResult = bunResultCheck.code;
    const { code: ht, ...htCheck } = CheckBloodNumberRange(
      arg.血球容積比,
      0,
      { min: 0, max: 999999.99994 },
      '血球容積比'
    );
    if(Number.isNaN(ht)) {
      _errorMsg+= '【血球容積比】格式有誤，';
    }
    if(bloodSettingsData.includes('8')) {
      _errorMsg += htCheck.errorMsg;
      _okUpload = _okUpload && htCheck.okUpload;
    }
    const htResultCheck = checkExaminedResultEnum(
      arg.血球容積比判讀,
      '血球容積比判讀'
    );
    if(htResultCheck.isNull) {
      // 若有追加項目 顯示0無異常
      if(bloodSettingsData.includes('8')) {
        htResultCheck.code = ExaminedResultEnum.NoProblem;   
      } else {
        // 若無追加項目 顯示9未受檢
        htResultCheck.code = ExaminedResultEnum.NoExamined;
      }
    } else if(!htResultCheck.okUpload && !htResultCheck.isNull){
      _errorMsg += htResultCheck.errorMsg;
      _okUpload = _okUpload && htResultCheck.okUpload;
    }
    const _htResult = htResultCheck.code;
    //移除最後一個'，'
    if (_errorMsg.length != 0) {
      _errorMsg = _errorMsg.slice(0, -1);
    }

    // console.log(arg);
    return {
      pid: _pid,
      sid: _sid,
      grade: _grade,
      no: _no,
      seat: _seat,
      sem: 0,
      year: 0,
      hb,
      wbc,
      rbc,
      pl,
      mcv,
      ht,
      cl,
      cr,
      ua,
      bun: _bun,
      got: _SGOT,
      gpt: _SGPT,
      hbsa,
      antiHB,
      hbResult: _hbResult,
      wbcResult: _wbcResult,
      rbcResult: _rbcResult,
      plResult: _plResult,
      mcvResult: _mcvResult,
      htResult: _htResult,
      clResult: _clResult,
      crResult: _crResult,
      uaResult: _uaResult,
      bunResult: _bunResult,
      gotResult: _SGOTResult,
      gptResult: _SGPTResult,
      hbsaResult: _hbsaResult,
      antiHBResult: _antihbResult,
      bloodCheckMemo: arg.備註,
      tgResult: _tgResult,
      tg: _tg,
      hdlResult: _hdlResult,
      hdl: _hdl,
      ldlResult: _ldlResult,
      ldl: _ldl,
      acResult: _acResult,
      ac: _ac,
      pcResult: _pcResult,
      pc: _pc,
      hBeAgResult: _hbeagResult,
      hBeAg: _hbeag,
      okUpload: _okUpload,
      remark: _errorMsg,
      nouse: arg.血色素判讀 === 'Hb' || arg.血色素判讀 === 'g/dl',
    };
  });

export type PhiBlood = z.infer<typeof PhiBloodZod>;
export type PhiBloodFile = z.infer<typeof PhiBloodFileZod>;
