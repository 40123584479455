import { DateTime } from 'luxon';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, DropdownButton, Form, Row } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { connect, ConnectedProps } from 'react-redux';
import { toast } from 'react-toastify';
import apis from '../../apis';
import {
  DateTimePicker,
  EditableProperties,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../../components';
import { ClassSem, WrapClassBaseLayout } from '../../layouts';
import { PHISight, SimpleStudent } from '../../model';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ApplicationState } from '../../redux/States';
import { Nullable } from '../../types';
import { SightTableHeader } from '../TableHeaders/SightHeader';
import DatePicker from 'react-multi-date-picker';

type StudentWithEditable = SimpleStudent &
  EditableProperties &
  Nullable<PHISight>;

const modalDisplayHeader: HeaderDisplayKeysWithType<StudentWithEditable>[] = [
  { property: 'seat', display: '座號' },
  { property: 'name', display: '學生' },
  ...(SightTableHeader as HeaderDisplayKeysWithType<StudentWithEditable>[]),
];

const mapState = (state: ApplicationState, ownProps: ClassSem) => ({
  ...ownProps,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const dropdownItems = {
  '1': '顯示全部',
  '2': '顯示視力不良者',
  '3': '顯示視力正常者',
} as { [k: string]: string };

const sightPDFPage: FunctionComponent<Props> = ({
  classId,
  className,
  year,
  sem,
  catchErrorForModal,
}) => {
  const [originalStudents, setOriginalStudent] = useState<
    StudentWithEditable[]
  >([]);
  const [students, setStudent] = useState<StudentWithEditable[]>([]);
  const [printSightHistories, setPrintSightHistories] = useState(false);
  const [printCurrentSem, setPrintCurrentSem] = useState(false);
  const [deadline, setDeadline] = useState<DateTime>();
  const [selectShow, setSelectShow] = useState<keyof typeof dropdownItems>('1');
  useEffect(() => {
    refreshStudents();
  }, [classId, year, sem]);

  function refreshStudents() {
    if (classId && year && sem) {
      apis
        .getClassStudentSight(classId, year, sem)
        .then((ss) => {
          const students = ss.map(({ sight, ...s }) => ({
            ...sight,
            ...s,
            selected: true,
          }));
          setOriginalStudent(students);
          setStudent(students);
        })
        .catch(catchErrorForModal);
    } else {
      setOriginalStudent([]);
      setStudent([]);
    }
  }

  if (!classId) return <></>;

  return (
    <>
      <Row className="mb-2">
        <DropdownButton
          className="mr-4"
          title={dropdownItems[selectShow]}
          onSelect={(k) => {
            if (!k || !dropdownItems[k]) return;
            setSelectShow(k);
            switch (k) {
              case '1':
                setStudent(originalStudents);
                break;
              case '2':
                setStudent(
                  originalStudents.filter((s, i) => s.measureResult !== '1')
                );
                break;
              case '3':
                setStudent(
                  originalStudents.filter((s, i) => s.measureResult === '1')
                );
                break;
              default:
                break;
            }
          }}
        >
          {Object.entries(dropdownItems).map(([k, s]) => (
            <DropdownItem key={`dd-${k}`} eventKey={k}>
              {s}
            </DropdownItem>
          ))}
        </DropdownButton>
        繳回期限：
        <div style={{ flex: '0 0 25%' }} className="mr-2">
          <DateTimePicker
            containerClass="px-2 py-1"
            value={deadline}
            onChange={(date) => {
              setDeadline(date);
            }}
          />
        </div>
        <Button
          className="ml-auto"
          onClick={() => {
            let pids = students.filter((s) => s.selected).map((s) => s.pid);
            pids = pids.length == 0 ? students.map((s) => s.pid) : pids;
            toast
              .promise(
                apis.downloadSightPDF(
                  classId,
                  pids,
                  year,
                  sem,
                  printCurrentSem,
                  printSightHistories,
                  deadline
                ),
                {
                  pending: '下載中...',
                  success: '下載成功！',
                  error: '下載失敗！請查看錯誤資訊。',
                }
              )
              .then((blob) => {
                const objlink = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objlink;
                a.setAttribute(
                  'download',
                  `視力查結果複檢通知單-${className}.pdf`
                );
                document.body.appendChild(a);
                a.click();

                a.parentNode?.removeChild(a);
              })
              .catch(catchErrorForModal);
          }}
        >
          <span className="feather icon-download"></span>
        </Button>
      </Row>
      <Row className="mb-2">
        <Form.Check
          className="mr-4"
          label="列印本學期視力表"
          checked={printCurrentSem}
          onChange={() => setPrintCurrentSem(!printCurrentSem)}
        />
        <Form.Check
          className="mr-4"
          label="列印歷年視力表"
          checked={printSightHistories}
          onChange={() => setPrintSightHistories(!printSightHistories)}
        />
      </Row>
      <Row>
        <EditableTableDiv
          headers={modalDisplayHeader}
          values={students}
          onSelected={(s) => {
            if (s.index !== undefined) {
              const v = students[s.index];
              v.selected = s.checked;
            } else {
              students.forEach((v) => {
                v.selected = s.checked;
              });
            }
            setStudent([...students]);
          }}
        />
      </Row>
    </>
  );
};

export const SightPDFPage = connector(WrapClassBaseLayout(sightPDFPage));
