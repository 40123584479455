import { z } from 'zod';
import {
  CheckStudentBaseData,
  CheckUrineEnum,
  checkExaminedResultEnum,
  CheckNumberRange,
} from '../utils/checkFormat';
import { ClassStudentZod } from './Class';
import {
  ExaminedResultEnum,
  ExaminedResultEnumZod,
  UrineEnumZod,
} from './Enums';
import { zodStringToDateTime, CheckShouldUseIdentify } from '../utils';
import apis from '../apis';
import { DateTime } from 'luxon';

interface SettingData {
  id: number;
  year: number;
  gradeID: number;
  labKind: number;
  checkDate: DateTime | null;
  checkDate2: DateTime | null;
  schoolId: string | null,
}

export const PhiUrineZod = z.object({
  inspect: z.number(),
  urine: ExaminedResultEnumZod,
  uProtein: UrineEnumZod.nullish(),
  uOB: UrineEnumZod.nullish(),
  uGlucose: UrineEnumZod.nullish(),
  uPh: z.number().nullish(),
  examDate: zodStringToDateTime().nullish(),
});

export const PhiParasiteZod = z.object({
  inspect: z.number(),
  parasite: ExaminedResultEnumZod,
  examDate: zodStringToDateTime(),
});

export const PhiLabZod = ClassStudentZod.merge(
  z.object({
    urine: PhiUrineZod.nullish(),
    parasite: PhiParasiteZod,
    followUpParasite: PhiParasiteZod.nullish(),
    followUpUrine: PhiUrineZod.nullish(),
    parasiteExamDate: zodStringToDateTime(),
    parasiteExamDate2: zodStringToDateTime(),
  })
);
export type PhiLab = z.infer<typeof PhiLabZod>;

export const PhiLabFileZod = z
  .object({
    /*身分證 學號 年級班級座號 學期 尿液檢查
      尿蛋白初檢    潛血初檢    尿糖初檢    酸鹼值初檢
      尿蛋白複檢    潛血複檢    尿糖複檢    酸鹼值複檢
    寄生蟲檢查
    檢查日期*/
    身分證: z.string().nullish(),
    學號: z.string().nullish(),
    年級班級座號: z.string().nullish(),
    尿液檢查: z.string().nullish(),
    尿蛋白初檢: z.string().nullish(),
    潛血初檢: z.string().nullish(),
    尿糖初檢: z.string().nullish(),
    酸鹼值初檢: z.string().nullish(),
    尿蛋白複檢: z.string().nullish(),
    潛血複檢: z.string().nullish(),
    尿糖複檢: z.string().nullish(),
    酸鹼值複檢: z.string().nullish(),
    寄生蟲檢查: z.string().nullish(),
    rank: z.number(),
  })
  .transform(async (arg) => {
    let _errorMsg = '';
    let _okUpload = true;
    let urineDataExist = false;
    let urineSettingsData: SettingData | undefined;
    let parasiteSettingsData: SettingData | undefined;
    let studentGrade = 0;
    let settingData = null;

    //檢查【身分證】、【學號】、【年級班級座號】格式
    const { pid, sid, grade, no, seat, ...checkStudentBaseDataResult } =
      CheckStudentBaseData(arg.身分證, arg.學號, arg.年級班級座號);
    if (!checkStudentBaseDataResult.okUpload) {
      _okUpload = checkStudentBaseDataResult.okUpload;
      _errorMsg += checkStudentBaseDataResult.errorMsg;
    }

    await getStudentGrade();
  
    // 實驗室檢查設定
    if(!settingData) {
      settingData = await apis.getLabSemSetting().then((s) => {
        return s
      });
    }
    (settingData as SettingData[]).forEach(v => {
      if(v.gradeID == studentGrade ) {
        if(v.labKind == 1) {
          parasiteSettingsData = v;
        }
        if(v.labKind === 2) {
          urineSettingsData = v;
        }
      }
    });

    async function getStudentGrade() {
      const student = [{
        pid: pid,
        sid: sid,
        grade: grade,
        no: no,
        seat: seat,
      }];
      const [cf, matchFunc] = CheckShouldUseIdentify(student);
      await apis.checkClassesStudentExistsWithGrade(
        student,
        cf
      )
      .then((result) => {
          if(result.grade) {
            studentGrade = result.grade;
          }
      })  
      .catch((e) => {
        console.log('error',e)
      });
    }

    //檢查【尿液檢查】欄位，必填欄位
    const { code: urine, ...urineResult } = checkExaminedResultEnum(
      arg.尿液檢查,
      '尿液檢查'
    );
    _okUpload = _okUpload && urineResult.okUpload ;
    if(urineResult.isNull) {
      _errorMsg += urineResult.errorMsg+ '不可空白';
    }

    // eslint-disable-next-line prefer-const
    let { code: uProtein, ...uProteinResult } = CheckUrineEnum(
      arg.尿蛋白初檢,
      '尿蛋白初檢'
    );
    if(urine != 9) {
      if(uProteinResult.isNull) { //若欄位為空白	顯示0無異常
        uProteinResult.okUpload = true;
        uProtein = 0;
        uProteinResult.isNull = false;
      }
    }
    // _okUpload = _okUpload && uProteinResult.okUpload;
    // _errorMsg += uProteinResult.errorMsg;
    urineDataExist = urineDataExist || !uProteinResult.isNull;
    // eslint-disable-next-line prefer-const
    let { code: uOB, ...uOBResult } = CheckUrineEnum(
      arg.潛血初檢,
      '潛血初檢'
    );
    if(urine != 9) {
      if(uOBResult.isNull) {
        uOB = 0;
        uOBResult.isNull = false;
      }
    }
    // _okUpload = _okUpload && uOBResult.okUpload;
    // _errorMsg += uOBResult.errorMsg;
    urineDataExist = urineDataExist || !uOBResult.isNull;
    // eslint-disable-next-line prefer-const
    let { code: uGlucose, ...uGlucoseResult } = CheckUrineEnum(
      arg.尿糖初檢,
      '尿糖初檢'
    );
    if(urine != 9) {
      if(uGlucoseResult.isNull) {
        uGlucose = 0;
        uGlucoseResult .isNull = false;
      }
    }
    // _okUpload = _okUpload && uGlucoseResult.okUpload;
    // _errorMsg += uGlucoseResult.errorMsg;
    urineDataExist = urineDataExist || !uGlucoseResult.isNull;
    // eslint-disable-next-line prefer-const
    let { code: uPh, ...uPhResult } = CheckNumberRange(
      arg.酸鹼值初檢,
      0,
      { min: 5, max: 9 },
      '酸鹼值初檢'
    );
    if(urine == 9) {
      uPh = 0;
      uPhResult.isNull = false;
    } else {
      _okUpload = _okUpload && uPhResult.okUpload;
      _errorMsg += uPhResult.errorMsg;
    }
    urineDataExist = urineDataExist || !uPhResult.isNull;

    // eslint-disable-next-line prefer-const
    let { code: uProtein2, ...uProteinResult2 } = CheckUrineEnum(
      arg.尿蛋白複檢,
      '尿蛋白複檢'
    );
    if(urine != 9 || urine != ExaminedResultEnum.NoExamined) {
      if(uProteinResult2.isNull) {
        uProtein2 = 0;
        uProteinResult2 .isNull = false;
      }
    }
    // _okUpload = _okUpload && uProteinResult2.okUpload;
    // _errorMsg += uProteinResult2.errorMsg;
    urineDataExist = urineDataExist || !uProteinResult2.isNull;
    // eslint-disable-next-line prefer-const
    let { code: uOB2, ...uOBResult2 } = CheckUrineEnum(
      arg.潛血複檢,
      '潛血複檢'
    );
    if(urine != 9 || urine != ExaminedResultEnum.NoExamined) {
      if(uOBResult2.isNull) {
        uOB2 = 0;
        uOBResult2.isNull = false;
      }
    }
    // _okUpload = _okUpload && uOBResult2.okUpload;
    // _errorMsg += uOBResult2.errorMsg;
    urineDataExist = urineDataExist || !uOBResult2.isNull;
    // eslint-disable-next-line prefer-const
    let { code: uGlucose2, ...uGlucoseResult2 } = CheckUrineEnum(
      arg.尿糖複檢,
      '尿糖複檢'
    );
    if(urine != 9 || urine != ExaminedResultEnum.NoExamined) {
      if(uGlucoseResult2.isNull) {
        uGlucose2 = 0;
        uGlucoseResult2.isNull = false;
      }
    }
    // _okUpload = _okUpload && uGlucoseResult2.okUpload;
    // _errorMsg += uGlucoseResult2.errorMsg;
    urineDataExist = urineDataExist || !uGlucoseResult2.isNull;
    // eslint-disable-next-line prefer-const
    let { code: uPh2, ...uPhResult2 } = CheckNumberRange(
      arg.酸鹼值複檢,
      0,
      { min: 5, max: 9 },
      '酸鹼值複檢'
    );

    if(urine == 9 || urine == 0) {
      uPh2 = 0;
      uPhResult2.isNull = false;
    } else if(uPhResult2.isNull){
      uPh2 = 0; 
    }
    urineDataExist = urineDataExist || !uPhResult2.isNull;

    // if (urineDataExist && urine === ExaminedResultEnum.NoExamined) {
    //   _okUpload = false;
    //   _errorMsg = '尿液檢查不應為未受檢，' + _errorMsg;
    // }

    //檢查【寄生蟲檢查】欄位
    const parasiteResult = checkExaminedResultEnum(
      arg.寄生蟲檢查,
      '寄生蟲檢查'
    );
    if(parasiteResult.isNull && arg.rank == 1) { // 國小必填，國高中沒有寄生蟲欄位
      _errorMsg+= parasiteResult.errorMsg;
    }
    const _parasiteResult = parasiteResult.code;
    //移除最後一個'，'
    if (_errorMsg.length != 0) {
      _errorMsg = _errorMsg.slice(0, -1);
    }

    return {
      pid,
      sid,
      grade,
      no,
      seat,
      urine,
      uProtein,
      uOB,
      uGlucose,
      uPh,
      uProtein2,
      uOB2,
      uGlucose2,
      uPh2,
      parasite: _parasiteResult,
      firstUrine:
        uProteinResult.isNull ||
        uOBResult.isNull ||
        uGlucoseResult.isNull ||
        uPhResult.isNull
          ? null
          : {
              inspect: 1,
              urine,
              uProtein,
              uOB,
              uGlucose,
              uPh,
              examDate: urineSettingsData ? urineSettingsData.checkDate : null
            },
      followUpUrine:
        uProteinResult2.isNull ||
        uOBResult2.isNull ||
        uGlucoseResult2.isNull ||
        uPhResult2.isNull
          ? null
          : {
              inspect: 2,
              urine: ExaminedResultEnum.InitialAbnoraml,
              uProtein: uProtein2,
              uOB: uOB2,
              uGlucose: uGlucose2,
              uPh: uPh2,
              examDate: urineSettingsData ? urineSettingsData.checkDate2 : null,
            },
      parasiteExamDate: parasiteSettingsData ? parasiteSettingsData.checkDate : null,
      parasiteExamDate2: parasiteSettingsData ? parasiteSettingsData.checkDate2 : null,
      okUpload: _okUpload,
      remark: _errorMsg,
    };
  });

export type PhiLabFile = z.infer<typeof PhiLabFileZod>;
