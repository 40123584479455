import React, { Fragment, FunctionComponent, useEffect, useState, useRef, ChangeEvent } from 'react';
import { ApplicationState } from '../../redux/States';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { downloadDataAsExcel, SheetHeaderDisplay} from '../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Form, InputGroup, FormControl, Col, Row as BSRow, Table, FormCheck, Modal, Container, } from 'react-bootstrap';
import { AuthedLayout, EditableTableDiv, HeaderDisplayKeysWithType, InputDropdownGroup } from '../../components';
import { StudentGradeNoSeat, StudentDisease } from '../../model';
import apis from '../../apis';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const disease: FunctionComponent<Props> = ({ 
  user,
  loading,
  catchErrorForModal 
}) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  type MetricPageData = StudentGradeNoSeat & StudentDisease;
  const { currentSemester = '', semesters = {} } = user || {};
  const [currentSem, setCurrentSem] = useState(currentSemester);
  const { year = -1, sem = -1 } = semesters[currentSem];
  const studentInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLInputElement>(null);
  const treatmentInputRef = useRef<HTMLInputElement>(null);
  const showStudentsData = useRef<HTMLInputElement>(null);
  const showInStudentsData = useRef<HTMLInputElement>(null);
  const [insertBtn, setInsertBtn] = useState<boolean>(true);
  const [inSchoolCheckbox, showInSchoolCheckbox] = useState<boolean>(false);
  const [sid, setSid] = useState<string>('');
  const [pid, setPid] = useState<string>('');
  const [grade, setGrade] = useState<number>();
  const [no, setNo] = useState<number>();
  const [seat, setSeat] = useState<number>();
  const [checkField, setCheckField] = useState<number>(0);
  const [diseaseItems, setdiseaseItems] = useState<object>();
  const [diseaseDropDownItems, setdiseaseDropDownItems] = useState<Record<number, string>>();
  const [diseaseDropDown, setDiseaseDropDown] = useState<number>();
  const [isDiseaseValid, setIsDiseaseValid] = useState<boolean>(false);
  const [disableDiseaseInSchool, setDisableDiseaseInSchool] = useState<boolean>(false);
  const [treatment, setTreatment] = useState<string>('');
  
  const [students, setStudent] = useState([] as MetricPageData[]);  
  const [editing, setEditing] = useState(false);
  const [editingStudent, setEditingStudent] = useState<MetricPageData>();
  const [deleting, setDeleting] = useState(false);
  const [deletingStudent, setDeletingStudent] = useState<MetricPageData>();
  const displayHeader: (SheetHeaderDisplay<MetricPageData> & 
    HeaderDisplayKeysWithType<MetricPageData>)[] = [
    { property: 'grade', display: '年級' },
    { property: 'no', display: '班級' },
    { property: 'seat', display: '座號' },
    { property: 'pid', display: '統編' },
    { property: 'name', display: '學生' },
    { property: 'diseaseId', display: '診斷代號' },
    { property: 'diseaseName', display: '疾病名稱' },
    { property: 'description', display: '陳述' },
    { property: 'treatment', display: '處置' },
  ];
 
  useEffect(() => {
    apis.GetDiseaseDropDown()
    .then((s) => {
      if(s.length) {
        setdiseaseItems(s);
        const results = s.reduce((obj, item) => {
          obj[item.id] = item.name;
          return obj;
        }, {} as Record<number, string>);
        setdiseaseDropDownItems(results);
      }
    })
    .catch(catchErrorForModal);
  }, [user, year, sem]);

  useEffect(() => { 
    // 檢查疾病代號
    setIsDiseaseValid(false);
    if (diseaseDropDown) {
      if(diseaseItems) {
        Object.values(diseaseItems).forEach((item, index) => {
          if(item.id == diseaseDropDown) {
            setIsDiseaseValid(true);
            setTreatment(item.treatment);
          }
        });
      }
      
      // 檢查是否可以點選新增按鈕
      if(studentInputRef.current?.value) {
        setInsertBtn(false); 
        CheckInput();
      }
  }
  }, [diseaseDropDown]);

  // 檢查是否可以點選新增按鈕
  function CheckInsertBtn() {
    setInsertBtn(true);
    if (diseaseDropDown) {
        if(studentInputRef.current?.value) {
          setInsertBtn(false); 
          CheckInput();
        }
    }
  }

  function CheckInput() {
    const inputStudent = studentInputRef.current?.value;
    
    // 檢查輸入身分證、學號或班級座號
    if(inputStudent) {
      if(inputStudent.substring(0, 1) == '=') {
        setSid(inputStudent.substring(1));
        setCheckField(2);
      } else {
        switch(inputStudent.length) {
          case 5:
            setGrade(parseInt(inputStudent.substring(0, 1)));
            setNo(parseInt(inputStudent.substring(1, 3)));
            setSeat(parseInt(inputStudent.substring(3, 5)));
            setCheckField(3);
            break;
          case 6:
            // 代入年級、班級、座號
            setGrade(parseInt(inputStudent.substring(0, 2)));
            setNo(parseInt(inputStudent.substring(2, 4)));
            setSeat(parseInt(inputStudent.substring(2, 4)));
            setCheckField(3);
            break;
          case 10:
          case 11:
          case 12:
            setPid(inputStudent);
            setCheckField(1);
            break;
        }
      }
    }
  }

  function insertDiseaseCheck() {
    // 檢查診斷代號
    if(isDiseaseValid) {
      // 檢查有無年級班級座號
      if(checkField == 3) {
        if(!grade || !no || !seat) {
          toast.error(`找不到該名學生!`);
          return
        }
      }
    } else {
      toast.error("診斷代號錯誤!", {});
      return
    }
    insertDisease();
  }

  function insertDisease() {
    if(diseaseDropDown) {
      // 送出api
      toast
      .promise(
        apis.insertPhiDisease(
            year, 
            sem,
            sid,
            seat || 0,
            no || 0,
            grade || 0,
            pid,
            diseaseDropDown,
            '', // 陳述
            treatment,
            new Date().toLocaleString('sv').slice(0, 10),
            checkField
        ),
        {
          pending: '資料新增中......',
          success: '新增成功！',
        }
      )
      .then((r) => {
        // 清空學號、診斷代號
        if(studentInputRef.current) {  
          studentInputRef.current.value = '';
        }
        setDiseaseDropDown(undefined);
        CheckInsertBtn(); // 按鈕diabled

        const pid = r[0].pid;
        getStudentByPid(pid);
      })
      .catch(catchErrorForModal);
    }
  }

  function getStudentByPid(pid: string) {
    // 顯示該學生個人疾病
    apis.GetStudentDiseaseByPid(year, sem, pid)
    .then((s) => {
      setStudent(s.map(({ studentDisease, ...m }) => ({
        ...studentDisease,
        ...m
      })));
    })
    .catch(catchErrorForModal);
  }

  function getStudentAll() {
    if(showStudentsData.current?.checked) {
      // 顯示只顯示在學學生checkbox
      showInSchoolCheckbox(true);
      setDisableDiseaseInSchool(true);
      apis.GetStudentDiseaseAll(year, sem)
      .then((s) => {
        setStudent(s.map(({ studentDisease, ...m }) => ({
          ...studentDisease,
          ...m
        })));
        setDisableDiseaseInSchool(false);
      })
      .catch(catchErrorForModal);
    } else {
      // 隱藏只顯示在學學生checkbox
      showInSchoolCheckbox(false);
      setStudent([] as MetricPageData[]);
    }
  }

  function getStudentInSchool() {
    if(showInStudentsData.current?.checked) {
      apis.GetStudentDiseaseInSchoolList(year, sem)
      .then((s) => {
        setStudent(s.map(({ studentDisease, ...m }) => ({
          ...studentDisease,
          ...m
        })));
      })
      .catch(catchErrorForModal);
    } else {
      getStudentAll();
    }
  }

  function onEditMetric(student: MetricPageData) {
    setEditing(true);
    setEditingStudent(student);
  }

  function onHideEditing() {
    setEditing(false);
    setEditingStudent(undefined);
  }

  function onDeleteMetric(student: MetricPageData) {
    setDeleting(true);
    setDeletingStudent(student);
  }

  function onHideDeleting() {
    setDeleting(false);
    setDeletingStudent(undefined);
  }

  function updateStudentDiseases() {
    if(editingStudent?.id && editingStudent?.enterDate) {   
      toast
      .promise(
        apis.updateStudentDisease(
          editingStudent?.id,
          editingStudent?.diseaseId,
          editingStudent?.diseaseName,
          descriptionInputRef.current?.value || '',
          treatmentInputRef.current?.value || '',
          editingStudent?.enterDate?.toString(),
          editingStudent?.yearClassId,
        ),
        {
          pending: '資料修改中......',
          success: '修改成功！',
          error: '修改失敗！請查看錯誤資訊。',
        }
      )
      .then((s) => {
        // 關閉編輯彈窗 
        setEditing(false);
        setEditingStudent(undefined);
        // 重新載入個人疾病清單
        getStudentInSchool();
      })
      .catch(catchErrorForModal);
    }
  }

  function deleteStudentDiseases() {
    if(deletingStudent?.id && deletingStudent?.enterDate) {   
      toast
      .promise(
        apis.deleteStudentDisease(
          deletingStudent?.id,
          deletingStudent?.diseaseId,
          deletingStudent?.diseaseName,
          deletingStudent?.description,
          deletingStudent?.treatment,
          deletingStudent?.enterDate?.toString(),
          deletingStudent?.yearClassId,
        ),
        {
          pending: '資料刪除中......',
          success: '刪除成功！',
          error: '刪除失敗！請查看錯誤資訊。',
        }
      )
      .then((s) => {
        // 關閉刪除彈窗 
        setDeleting(false);
        setDeletingStudent(undefined);
        // 重新載入個人疾病清單
        getStudentInSchool();
      })
      .catch(catchErrorForModal);
    }
  }

  return (
    <AuthedLayout>
      <BSRow className="justify-content-between">
        診斷代號：
        <Col sm={2}>
          <InputDropdownGroup
            className={'p-1 text-center'}
            name="diseaseId"
            type='number'
            options={diseaseDropDownItems || {}}
            defaultShow="無異常"
            value={diseaseDropDown || ''}
            onChange={(
              e: ChangeEvent<
                HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
              >
            ) => {
              setDiseaseDropDown(parseInt(e.target.value));
            }}
            onBlur={() => {}}
            onFocus={() => {}}
          />
        </Col>
        <div>
          <span>請輸入年級班級座號或身分證或學號(學號前請加=)：</span><br />
          <span className="text-primary">例如:國小三年級1班3號，應寫作「30103」</span>
        </div>
        <Col xs={2} className="mr-2">
            <FormControl 
              type="string"
              aria-label="studentInput"
              aria-describedby="basic-addon1"
              ref={studentInputRef}
              onChange={CheckInsertBtn}
            />
        </Col>
        <Button
          disabled={insertBtn}
          onClick={insertDiseaseCheck}
        >
          新增
        </Button>
      </BSRow>
      <BSRow className='mt-4 mb-2'>
        <Col>
          <div className='d-flex ml-2'>
            <FormCheck
              name="showStudentDisease"
              ref={showStudentsData}
              label="全部"
              onChange={(e) => getStudentAll()}
            />
            {inSchoolCheckbox && (
              <FormCheck
                name="showStudentDisease"
                className='ml-3'
                ref={showInStudentsData}
                label="只顯示在學學生"
                disabled={disableDiseaseInSchool}
                onChange={(e) => getStudentInSchool()}
             />
            )}
          </div>
        </Col>
        <Button
          disabled={!students.length}
          variant="success"
          className="text-dark"
          onClick={() => {
            downloadDataAsExcel({
              title: `${year}學年第${sem}學期全校個人疾病史清單`,
              values: students,
              headers: displayHeader,
              footer:
                '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
            });
          }}
        >
          Excel下載
        </Button>
      </BSRow>
      <BSRow className='mb-2'>
        <Col className="text-center">
          {year}學年第{sem}學期全校個人疾病史清單
        </Col>
      </BSRow>
      <BSRow>
        <EditableTableDiv
          editable
          deleteable
          values={students}
          headers={displayHeader}
          onEdit={onEditMetric}
          onDelete={onDeleteMetric}
        />
      </BSRow>
      <React.Fragment>
        <Modal
          show={editing}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton onHide={onHideEditing}>
            <Modal.Title id="contained-modal-title-vcenter">
              個人疾病史 - {editingStudent?.grade}年{editingStudent?.no}班{editingStudent?.seat}號 {editingStudent?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Container>
              <BSRow className='mb-3'>
                <Col sm={3}> 統編：</Col>
                <Col sm={3}>{editingStudent?.pid}</Col>
                <Col sm={3}>診斷代號：</Col>
                <Col sm={3}>{editingStudent?.diseaseId}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={3}> 學生：</Col>
                <Col sm={3}>{editingStudent?.name}</Col>
                <Col sm={3}> 疾病名稱：</Col>
                <Col sm={3}>{editingStudent?.diseaseName}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={3}>
                  陳述：
                </Col>
                <Col sm={9}>
                  <FormControl 
                    type="string"
                    aria-label="studentInput"
                    aria-describedby="basic-addon1"
                    defaultValue={editingStudent?.description}
                    ref={descriptionInputRef}
                  />
                </Col>
              </BSRow>
              <BSRow>
                <Col sm={3}>
                  處置：
                </Col>
                <Col sm={9}>
                  <FormControl 
                    type="string"
                    aria-label="studentInput"
                    aria-describedby="basic-addon1"
                    defaultValue={editingStudent?.treatment}
                    ref={treatmentInputRef}
                  />
                </Col>
              </BSRow>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" onClick={updateStudentDiseases}>
              儲存
            </Button>
            <Button type="reset" variant="secondary" onClick={onHideEditing}>
              關閉
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={deleting}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton onHide={onHideDeleting}>
            <Modal.Title id="contained-modal-title-vcenter">
              個人疾病史 - {deletingStudent?.grade}年{deletingStudent?.no}班{deletingStudent?.seat}號 {deletingStudent?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <BSRow className='mb-3'>
                <Col sm={6}> 統編：</Col>
                <Col sm={6}>{deletingStudent?.pid}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={6}>診斷代號：</Col>
                <Col sm={6}>{deletingStudent?.diseaseId}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={6}> 學生：</Col>
                <Col sm={6}>{deletingStudent?.name}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={6}> 疾病名稱：</Col>
                <Col sm={6}>{deletingStudent?.diseaseName}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={6}>陳述：</Col>
                <Col sm={6}>{deletingStudent?.description}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={6}>處置：</Col>
                <Col sm={6}>{deletingStudent?.treatment}</Col>
              </BSRow>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" type="submit" onClick={deleteStudentDiseases}>
              刪除
            </Button>
            <Button type="reset" variant="secondary" onClick={onHideDeleting}>
              關閉
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    </AuthedLayout>
  );
};

export const Disease = connector(disease);
