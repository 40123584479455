import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { ApplicationState } from '../../redux/States';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../../components';
import {
  CheckField,
  StudentDiseaseFile,
  StudentDiseaseFileZod,
  StudentDiseaseUpload,
} from '../../model';
import { Button, Form, Row } from 'react-bootstrap';
import XLSX from 'xlsx';
import {
  CheckShouldUseIdentify,
  assignExists,
  checkOkToUpload,
  takeClassStudent,
} from '../../utils';
import { z } from 'zod';
import apis from '../../apis';
import { toast } from 'react-toastify';
import { useActiveSems } from '../../hook';

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = { ...ErrorDispatches };

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const inputFileParser = z.array(StudentDiseaseFileZod);
const tableHeader: HeaderDisplayKeysWithType<StudentDiseaseFile>[] = [
  { property: 'remark', display: '備註' },
  { display: '身份證字號', property: 'pid' },
  { display: '學號', property: 'sid' },
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { display: '疾病代號', property: 'diseaseId' },
  { display: '陳述', property: 'description' },
  { display: '處置', property: 'treatment' },
];

function filterOutNonPhiXRayField({
  remark,
  okUpload,
  ...phiXRay
}: StudentDiseaseFile): StudentDiseaseUpload {
  return { ...phiXRay };
}

const importDisease: FunctionComponent<Props> = ({
  user: { semesters, currentSemester },
  catchErrorForModal,
  showError,
}) => {
  const [fileName, setFileName] = useState('檔案'); // 如 :  const [fileName, setFileName] = ''; 可?
  const [fileData, setFileData] = useState([] as StudentDiseaseFile[]);
  const [okToUpload, setOkToUpload] = useState(false);
  const [checkField, setCheckField] = useState(CheckField.None);

  const { selectedSemester, yearSemElement } = useActiveSems(
    semesters,
    currentSemester
  );

  return (
    <AuthedLayout>
      <Row className="mb-2">匯入學期：{yearSemElement}</Row>
      <Row className="mb-2">
        下載匯入格式：
        <a href="/Doc/個人疾病史7.xls" style={{ textDecoration: 'underline' }}>
          個人疾病史匯入格式
        </a>
      </Row>
      <Row className="mb-2">
        <Form.File
          type="file"
          className="my-file-label"
          id="inputGroupFile01"
          label={fileName}
          accept=".xlsx, .csv, .xls, .ods, .ots"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const { files } = e.target;
            try {
              if (files?.length === 1) {
                setFileName(files[0].name);
                const buf = await files[0].arrayBuffer();
                const workShop = XLSX.read(buf, {
                  type: 'buffer',
                  sheets: 0,
                });
                if (workShop.SheetNames[0]) {
                  const jsonData = XLSX.utils.sheet_to_json(
                    workShop.Sheets[workShop.SheetNames[0]],
                    { raw: false, rawNumbers: false }
                  );
                  const headerData = XLSX.utils.sheet_to_json(workShop.Sheets[workShop.SheetNames[0]], { header: 1 });
                  const headers = headerData[0] as string[];
                  const isCorrectFile = headers.find((h: string, i: number) => h === '疾病代號');
                  // 檢查表頭是否正確
                  if(!isCorrectFile) {
                    showError('Excel中的表頭欄位不正確，請確認是否選擇了正確的檔案。');
                    return;
                  }
                  const ss = await inputFileParser.parseAsync(jsonData);

                  if (ss) {
                    const [cf, matchFunc] = CheckShouldUseIdentify(ss);
                    console.log(cf, ss);
                    setCheckField(cf);
                    if (cf == CheckField.None) {
                      showError(
                        '匯入之檔案應有唯一使用"身份證"或"學號"或"班級座號"'
                      );
                      return;
                    }
                    await apis
                      .checkClassesStudentExists(
                        takeClassStudent(ss),
                        cf,
                        selectedSemester
                      )
                      .then((result) => {
                        // console.log('importPhiWH.ts #104',result)
                        // setFileData (merge result&ss)
                        setFileData(assignExists(matchFunc, ss, result));
                        //check ss is okToUpload ,then setOkToUpload(true);
                        setOkToUpload(checkOkToUpload(true, ss));
                      })
                      .catch((e) => {
                        catchErrorForModal(e);
                        setOkToUpload(false);
                      });
                  } else {
                    console.log(ss);
                  }
                }
              }
            } catch (e) {
              setFileName('檔案');
              setFileData([]);
              setOkToUpload(false);
              showError('檔案內容不正確，請檢查資料內容格式');
            } finally {
              e.target.value = '';
            }
          }}
          custom
        />
        <Button
          className="mr-0"
          disabled={!okToUpload}
          onClick={() => {
            if (fileData.length > 0 && checkField != CheckField.None)
              toast
                .promise(
                  apis.insertStudentDiseases(
                    fileData.map(filterOutNonPhiXRayField),
                    checkField
                  ),
                  {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                  }
                )
                .then(() => {
                  setOkToUpload(false);
                })
                .catch(catchErrorForModal);
          }}
        >
          匯入資料
        </Button>
      </Row>
      <Row>
        <EditableTableDiv
          headers={tableHeader}
          values={fileData}
          onRowRender={({ remark }) => ({
            rowClassName: remark ? 'bg-danger text-white' : '',
          })}
        />
      </Row>
    </AuthedLayout>
  );
};

export const ImportDiseasePage = connector(importDisease);
