import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EditableTableDiv, HeaderDisplayKeysWithType } from '../components';
import Apis from '../apis';
import { ErrorDispatches } from '../redux/Dispatches';
import { Nullable } from '../types';
import { PHISight, PHIWH, PHIWHSight, SimpleStudent } from '../model';
import { Row, Modal, Container, Button, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { ClassSem, WrapClassBaseLayout } from '../layouts';
import { ApplicationState } from '../redux/States';
import {
  DefaultWHContext,
  WH_CONTEXT_BMI_CODE_KEY,
  WHPropertyFields,
} from './TableHeaders/WHHeader';
import { semesterRange } from '../utils/date';
import {
  denormalizeStudentSight,
  normalizeStudentSight,
  SightCalculators,
  SightPropertyFields,
} from './TableHeaders/SightHeader';

type MetricPageData = SimpleStudent & Nullable<PHIWH> & Nullable<PHISight>;

const displayHeader: HeaderDisplayKeysWithType<MetricPageData>[] = [
  { property: 'seat', display: '座號' },
  { property: 'name', display: '學生' },
  { property: 'height', display: '身高' },
  { property: 'weight', display: '體重' },
  {
    property: 'sight0R',
    display: '裸視右',
    onRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    },
  },
  {
    property: 'sight0L',
    display: '裸視左',
    onRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    },
  },
  {
    property: 'sightR',
    display: '戴鏡右',
    onRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    },
  },
  {
    property: 'sightL',
    display: '戴鏡左',
    onRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    },
  },
  {
    property: 'examDate',
    display: '測量日',
    onRender: (v: unknown, i, e, c) => {
      if (e) {
        const { year, sem } = c.allValues;
        const [start, end] = semesterRange(year, sem);
        return WHPropertyFields.examDate(start, end, c.allValues);
      }
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
    },
  },
  {
    property: 'bmi',
    display: 'BMI',
    onRender: (value: unknown) => {
      if (typeof value === 'number') {
        return Math.round(value * 10) / 10; // 四捨五入到小數點後一位
      }
      return value;
    },
  },
  {
    property: 'bmiCode',
    display: '體位判讀',
    onRender: (v: unknown, i, e, c) => {
      const {
        [WH_CONTEXT_BMI_CODE_KEY]: Codes = DefaultWHContext[
          WH_CONTEXT_BMI_CODE_KEY
        ],
      } = (c?.context || DefaultWHContext) as typeof DefaultWHContext;
      return (typeof v === 'string' ? Codes[v] : (v as string)) || '';
    },
  },
];

const mapState = (state: ApplicationState, ownProps: ClassSem) => ({
  ...ownProps,
  ...state.auth,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;
const classPHIWHSightPage: FunctionComponent<Props> = ({
  classId,
  year,
  sem,
  catchErrorForModal,
}) => {
  const [students, setStudent] = useState([] as MetricPageData[]);
  const [inputing, setInputing] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [editingStudent, setEditingStudent] = useState<MetricPageData>();

  const [start, end] = useMemo(() => semesterRange(year, sem), [year, sem]);

  useEffect(() => {
    refreshStudentMetric();
  }, [classId, year, sem, inputing, toggle]);

  function refreshStudentMetric() {
    if (classId && year && sem) {
      Apis.getClassStudentWHSight(classId, year, sem)
        .then((d) => {
          const datas: MetricPageData[] = d.map((m) => {
            const { examDate, ...sight } = m.sight || {};
            return {
              ...m,
              ...sight,
              ...m.phiWH,
            };
          });
          setStudent(datas);
        })
        .catch(catchErrorForModal);
    } else {
      setStudent([]);
    }
  }

  function onHide() {
    setInputing(false);
    // setEditingStudent(undefined);
  }

  function onEditMetric(metric: MetricPageData) {
    setInputing(true);
    setEditingStudent(metric);
  }

  function onDelete(sight: MetricPageData) {
    toast
      .promise(Apis.deleteStudentWHSight(sight.pid, year, sem), {
        pending: '資料刪除中......',
        success: '資料刪除成功！',
        error: '資料刪除失敗！請查看錯誤資訊。',
      })
      .then(() => setToggle(!toggle))
      .catch(catchErrorForModal);
  }

  async function onUpdateStudentMetric(metric: PHIWHSight) {
    if (editingStudent && editingStudent.pid) {
      toast
        .promise(Apis.InsertStudentWHSight(editingStudent?.pid, metric), {
          pending: '資料上傳中......',
          success: '上傳成功！',
          error: '上傳失敗！請查看錯誤資訊。',
        })
        .then(() => {
          onHide();
        })
        .catch(catchErrorForModal);
    }
  }

  const rowClassName = 'pb-3 align-items-center';
  return (
    <React.Fragment>
      <Row>
        <EditableTableDiv
          editable
          deleteable
          values={students}
          headers={displayHeader}
          onEdit={onEditMetric}
          onDelete={onDelete}
        />
      </Row>
      <Modal
        show={inputing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="contained-modal-title-vcenter">
            身高體重 -{' '}
            <strong>
              {editingStudent?.seat} {editingStudent?.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Form
          initialValues={
            editingStudent ? denormalizeStudentSight(editingStudent) : undefined
          }
          onSubmit={(value: PHIWHSight) => {
            const data = {
              ...value,
              ...normalizeStudentSight(value),
              id: 0,
              sem,
              year,
              yearClassId: 0,
            };
            onUpdateStudentMetric(data);
          }}
          decorators={SightCalculators as any}
          subscription={{ submitting: true, pristine: true }}
          render={(prop) => {
            const { handleSubmit } = prop;
            return (
              <React.Fragment>
                <Modal.Body className="text-center">
                  <Container>
                    <Row className={rowClassName}>
                      {generateNumberField('身高', WHPropertyFields.height)}
                      {generateNumberField('體重', WHPropertyFields.weight)}
                    </Row>
                    <Row className={rowClassName}>
                      {generateNumberField(
                        '裸視右',
                        SightPropertyFields.sight0R
                      )}
                      {generateNumberField(
                        '裸視左',
                        SightPropertyFields.sight0L
                      )}
                    </Row>
                    <Row className={rowClassName}>
                      {generateNumberField(
                        '戴鏡右',
                        SightPropertyFields.sightR
                      )}
                      {generateNumberField(
                        '戴鏡左',
                        SightPropertyFields.sightL
                      )}
                    </Row>
                    <Row className={rowClassName}>
                      <Col sm="1">測量日:</Col>
                      <Col sm="3">
                        {WHPropertyFields.examDate(start, end, editingStudent)}
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" onClick={handleSubmit}>
                    儲存
                  </Button>
                  <Button type="reset" variant="secondary" onClick={onHide}>
                    關閉
                  </Button>
                </Modal.Footer>
              </React.Fragment>
            );
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

function generateNumberField(display: string, node: ReactNode): ReactElement {
  return (
    <React.Fragment>
      <Col sm="1">{display}:</Col>
      <Col sm="3">{node}</Col>
    </React.Fragment>
  );
}

export const ClassPHIWHSightPage = connector(
  WrapClassBaseLayout(classPHIWHSightPage)
);
