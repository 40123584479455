import { z } from 'zod';
import { DateTime } from 'luxon';
import { GetAcademicYear } from '../utils/semester';
import {
  CheckStudentBaseData,
  CheckSemester,
  CheckDate,
  CheckNumber,
  CheckDateInSemester,
  CheckCode,
} from '../utils/checkFormat';
import { zodStringToDateTime } from '../utils/zod';
import { ClassStudentZod } from './Class';
import { dateTimeFromString } from '../utils/date';

export const PhiWHSightZod = ClassStudentZod.merge(
  z.object({
    /* 身分證	學號	年級班級座號	學期	身高	體重	測量日 */
    // pid: z.string().nullish(),
    // sid: z.string().nullish(),
    // grade: z.string().nullish(),
    // no: z.string().nullish(),
    // seat: z.string().nullish(),
    sem: z.string(),
    year: z.number(),
    height: z.number(),
    weight: z.number(),
    bmi: z.number(),
    bmiCode: z.string(),
    examDate: zodStringToDateTime(),
    sight0L: z
      .number()
      .nullish()
      .transform((v) => (v == 0 ? null : v)),
    sight0R: z
      .number()
      .nullish()
      .transform((v) => (v == 0 ? null : v)),
    sightL: z
      .number()
      .nullish()
      .transform((v) => (v == 0 ? null : v)),
    sightR: z
      .number()
      .nullish()
      .transform((v) => (v == 0 ? null : v)),

    eNear: z.boolean().default(false),
    eNearL: z
      .number()
      .nullish()
      .transform((v) => (v == 0 ? null : v)),
    eNearR: z
      .number()
      .nullish()
      .transform((v) => (v == 0 ? null : v)),
    eFar: z.boolean().default(false),
    eFarL: z
      .number()
      .nullish()
      .transform((v) => (v == 0 ? null : v)),
    eFarR: z
      .number()
      .nullish()
      .transform((v) => (v == 0 ? null : v)),
    eSan: z.boolean().default(false),
    eSanL: z
      .number()
      .nullish()
      .transform((v) => (v == 0 ? null : v)),
    eSanR: z
      .number()
      .nullish()
      .transform((v) => (v == 0 ? null : v)),
    eWeak: z.boolean().default(false),
    eSight99: z.boolean().default(false),
    // eSight99State: z.string().nullish(),

    manageId: z.array(z.string()).nullish(),
    // manage: z.string().nullish(),
    // diopL: z.number().nullish().transform((v) => (v == 0 ? null : v)),
    // diopR: z.number().nullish().transform((v) => (v == 0 ? null : v)),
    noProblem: z.boolean().default(false),
    isDilated: z.boolean().default(false),
    isDilating: z.boolean().default(false),
    periodical: zodStringToDateTime().nullish(),
    measureResult: z.string().nullish(),
  })
);

export type PhiWHSight = z.infer<typeof PhiWHSightZod>;

export const PhiWHSightFileZod = z
  .object({
    身分證: z.string().nullish(),
    學號: z.string().nullish(),
    年級班級座號: z.string().nullish(),
    學期: z.string().nullish(),

    // wh
    身高: z.string().nullish(),
    體重: z.string().nullish(),
    測量日: z.string().nullish(),
    year: z.number(),
    sem: z.number(),

    // sight
    散瞳治療: z.string().nullish(),
    裸視右眼: z
      .union([z.string(), z.number()])
      .transform((s) => +s)
      .nullish(),
    裸視左眼: z
      .union([z.string(), z.number()])
      .transform((s) => +s)
      .nullish(),
    戴鏡右眼: z
      .union([z.string(), z.number()])
      .transform((s) => +s)
      .nullish(),
    戴鏡左眼: z
      .union([z.string(), z.number()])
      .transform((s) => +s)
      .nullish(),
    散瞳: z.string().nullish(),
    近視右眼: z
      .union([z.string(), z.number()])
      .transform((s) => +s)
      .nullish(),
    近視左眼: z
      .union([z.string(), z.number()])
      .transform((s) => +s)
      .nullish(),
    遠視右眼: z
      .union([z.string(), z.number()])
      .transform((s) => +s)
      .nullish(),
    遠視左眼: z
      .union([z.string(), z.number()])
      .transform((s) => +s)
      .nullish(),
    散光右眼: z
      .union([z.string(), z.number()])
      .transform((s) => +s)
      .nullish(),
    散光左眼: z
      .union([z.string(), z.number()])
      .transform((s) => +s)
      .nullish(),
    近視: z.string().nullish(),
    遠視: z.string().nullish(),
    散光: z.string().nullish(),
    弱視: z.string().nullish(),
    其他: z.string().nullish(),
    複檢無異狀: z.string().nullish(),
    備註: z.string().nullish(),
    醫師建議處置: z.string().nullish(),
    定期檢查: z.string().nullish(),
  })
  .transform((arg) => {
    let _okUpload = true;
    let _errorMsg = '';

    //檢查【身分證】、【學號】、【年級班級座號】格式
    const checkStudentBaseDataResult = CheckStudentBaseData(
      arg.身分證,
      arg.學號,
      arg.年級班級座號
    );
    const _pid = checkStudentBaseDataResult.pid;
    const _sid = checkStudentBaseDataResult.sid;
    const _grade = checkStudentBaseDataResult.grade;
    const _no = checkStudentBaseDataResult.no;
    const _seat = checkStudentBaseDataResult.seat;
    if (!checkStudentBaseDataResult.okUpload) {
      _okUpload = checkStudentBaseDataResult.okUpload;
      _errorMsg += checkStudentBaseDataResult.errorMsg;
    }

    const checkSemester = CheckSemester(arg.學期);
    const _semester = checkSemester.semester;
    if (!checkSemester.okUpload) {
      _okUpload = checkSemester.okUpload;
      _errorMsg += checkSemester.errorMsg;
    }

    let _height = -9;
    if (arg.身高 != '-9') {
      const checkHeight = CheckNumber(arg.身高, 0, '身高');
      if (!checkHeight.okUpload) {
        _okUpload = checkHeight.okUpload;
        _errorMsg += checkHeight.errorMsg;
      } else {
        _height = checkHeight.code;
        if (_height < 60 || _height > 226) {
          _okUpload = false;
          _errorMsg += '【身高】範圍應在60~226之間';
        }
      }
    }

    let _weight = -9;
    if (arg.體重 != '-9') {
      const checkWeight = CheckNumber(arg.體重, 0, '體重');
      if (!checkWeight.okUpload) {
        _okUpload = checkWeight.okUpload;
        _errorMsg += checkWeight.errorMsg;
      } else {
        _weight = checkWeight.code;
        if (_weight < 3 || _weight > 204) {
          _okUpload = false;
          _errorMsg += '【體重】範圍應在3~204之間';
        }
      }
    }
    //bmi計算
    let _bmi = -9;
    let _bmiCode = '';
    if (_height != null && _weight != null) {
      _bmi = _weight / ((_height * _height) / 10000);
    }

    switch (true) {
      case _bmi < 16:
        _bmiCode = '-2';
        break;
      case _bmi < 18.5 && _bmi >= 16:
        _bmiCode = '-1';
        break;
      case _bmi < 24 && _bmi >= 18.5:
        _bmiCode = '0';
        break;
      case _bmi < 27 && _bmi >= 24:
        _bmiCode = '1';
        break;
      case _bmi < 35 && _bmi >= 30:
        _bmiCode = '2';
        break;
      default:
        _bmiCode = '-9';
        break;
    }
    if (_height == -9 || _weight == -9) {
      _bmi = -9;
      _bmiCode = '-9';
    }

    const checkDate = CheckDateInSemester(
      arg.測量日,
      arg.year,
      arg.sem,
      '測量日'
    );
    const _examDate = arg.測量日;
    if (checkDate.isNull) {
      if (_height != -9 || _weight != -9) {
        _okUpload = false;
        _errorMsg += checkDate.errorMsg;
      }
    }
    //散瞳治療
    const checkIsDilated = CheckCode(arg.散瞳治療, ['0', '1'], '散瞳治療');
    let isDilated = '';
    if (!checkIsDilated.okUpload && checkIsDilated.code) {
      _okUpload = checkIsDilated.okUpload;
      _errorMsg += checkIsDilated.errorMsg;
    }
    isDilated = checkIsDilated.code;
    const _isDilated = isDilated == '1' ? true : false;
    const sight0Code = [-9, -8, -7, -6, -5, -1, null];
    //裸視右眼
    const _sight0R = arg.裸視右眼 == null ? null : arg.裸視右眼;
    if (!_sight0R && _sight0R != null && sight0Code.includes(_sight0R)) {
      //裸視 不排除null
      if (_sight0R < 1 || _sight0R > 20) {
        _okUpload = false;
        _errorMsg += '請確認【裸視右眼】數值，';
      }
    }

    //裸視左眼
    const _sight0L =
      _sight0R === -9 ? null : arg.裸視左眼 == null ? null : arg.裸視左眼;
    if (!_sight0L && _sight0L != null && sight0Code.includes(_sight0L)) {
      //裸視 不排除null
      if (_sight0L < 1 || _sight0L > 20) {
        _okUpload = false;
        _errorMsg += '請確認【裸視左眼】數值，';
      }
    }

    //戴鏡右眼
    const _sightR =
      _sight0R === -9 ? null : arg.戴鏡右眼 == null ? null : arg.戴鏡右眼;
    if (_sightR != 0 && _sightR != null) {
      if (_sightR < 1 || _sightR > 20) {
        _okUpload = false;
        _errorMsg += '請確認【戴鏡右眼】數值格式，';
      }
    }

    //戴鏡左眼
    const _sightL =
      _sight0R === -9 ? null : arg.戴鏡左眼 == null ? null : arg.戴鏡左眼;
    if (_sightL != 0 && _sightL != null) {
      if (_sightL < 1 || _sightL > 20) {
        _okUpload = false;
        _errorMsg += '請確認【戴鏡左眼】數值格式，';
      }
    }

    //散瞳
    const checkIsDilating = CheckCode(arg.散瞳, ['0', '1'], '散瞳');
    let isDilating = '';
    if (!checkIsDilating.okUpload && !checkIsDilating.isNull) {
      _okUpload = checkIsDilating.okUpload;
      _errorMsg += checkIsDilating.errorMsg;
    }
    isDilating = checkIsDilating.code;
    if (checkIsDilating.isNull) {
      isDilating = '0';
    }
    const _isDilating = isDilating == '1' ? true : false;
    //近視右眼
    const _eNearR = arg.近視右眼;
    if (!_eNearR && _eNearR != null) {
      if (_eNearR < 1 || _eNearR > 5000) {
        _okUpload = false;
        _errorMsg += '請確認【近視右眼】數值，';
      }
    }

    //近視左眼
    const _eNearL = arg.近視左眼;
    if (!_eNearL && _eNearL != null) {
      if (_eNearL < 1 || _eNearL > 5000) {
        _okUpload = false;
        _errorMsg += '請確認【近視左眼】數值，';
      }
    }

    //遠視右眼
    const _eFarR = arg.遠視右眼;
    if (!_eFarR && _eFarR != null) {
      if (_eFarR < 1 || _eFarR > 2500) {
        _okUpload = false;
        _errorMsg += '請確認【遠視右眼】數值，';
      }
    }

    //遠視左眼
    const _eFarL = arg.遠視左眼;
    if (!_eFarL && _eFarL != null) {
      if (_eFarL < 1 || _eFarL > 2500) {
        _okUpload = false;
        _errorMsg += '請確認【遠視左眼】數值，';
      }
    }

    //散光右眼
    let _sanR = arg.散光右眼;
    if (!_sanR && _sanR != null) {
      _sanR = Math.abs(_sanR);
      if (_sanR < 1 || _sanR > 2500) {
        _okUpload = false;
        _errorMsg += '請確認【散光右眼】數值，';
      }
      //輸入值轉負值儲存
      _sanR = -_sanR;
    }

    //散光左眼
    let _sanL = arg.散光左眼;
    if (!_sanL && _sanL != null) {
      _sanL = Math.abs(_sanL);
      if (_sanL < 1 || _sanL > 2500) {
        _okUpload = false;
        _errorMsg += '請確認【散光右眼】數值，';
      }
      //輸入值轉負值儲存
      _sanL = -_sanL;
    }

    //近視
    //「近視右」或「近視左」有資料時，則「近視否」(Table 欄位)為 1
    let _near = arg.近視 == '1' ? true : false;
    if (
      (_eNearR == null && _eNearL == null) ||
      (_eNearR == 0 && _eNearL == 0)
    ) {
      //_near = false
      if (_near == true) {
        _errorMsg += '請確認是否【近視】，';
        _okUpload = false;
      }
    } else {
      _near = true;
    }

    //遠視
    //「遠視右」或「遠視左」有資料時，則「遠視否」(Table 欄位)為 1
    let _far = arg.遠視 == '1' ? true : false;
    if ((_eFarR == null && _eFarL == null) || (_eFarR == 0 && _eFarL == 0)) {
      //_far = false
      if (_far == true) {
        _errorMsg += '請確認是否【遠視】，';
        _okUpload = false;
      }
    } else {
      _far = true;
    }

    //散光
    //「散光右」或「散光左」有資料時，則「散光否」(Table 欄位)為 1
    let _san = arg.散光 == '1' ? true : false;
    if ((_sanR == null && _sanL == null) || (_sanR == 0 && _sanL == 0)) {
      // san = false
      if (_san == true) {
        _errorMsg += '請確認是否【散光】，';
        _okUpload = false;
      }
    } else {
      _san = true;
    }

    //弱視
    const _weak = arg.弱視 == '1' ? true : false;
    //其他
    const _eSight99 = arg.其他 == '1' ? true : false;
    //複檢無異狀
    const _recheck = arg.複檢無異狀 == '1' ? true : false;
    //備註
    //醫師建議處置
    const _manageId =
      arg.醫師建議處置 == null ? null : arg.醫師建議處置.split(',');
    //定期檢查
    const periodicalDateTime = dateTimeFromString(arg.定期檢查 || '');
    if (!periodicalDateTime.isValid && arg.定期檢查) {
      _errorMsg += '【定期檢查】格式有誤，';
      _okUpload = false;
    }
    const _periodical = arg.定期檢查;

    //移除最後一個'，'
    if (_errorMsg.length != 0) {
      _errorMsg = _errorMsg.slice(0, -1);
    }

    return {
      pid: _pid,
      sid: _sid,
      grade: _grade,
      no: _no,
      seat: _seat,
      sem: _semester,
      year: GetAcademicYear,

      // wh
      height: _height,
      weight: _weight,
      bmi: _bmi,
      bmiCode: _bmiCode,
      examDate: _examDate,

      // sight
      sight0R: _sight0R, //裸視右
      sight0L: _sight0L, //裸視左
      sightR: _sightR, //戴鏡右
      sightL: _sightL, //戴鏡左
      isDilating: _isDilating,
      isDilated: _isDilated,
      eNearR: arg.近視右眼, //近視右
      eNearL: arg.近視左眼, //近視左
      eFarR: arg.遠視右眼, //遠視右
      eFarL: arg.遠視左眼, //遠視左
      eSanR: _sanR, //散光右
      eSanL: _sanL, //散光左
      eNear: _near, //近視
      eFar: _far, //遠視
      eSan: _san, //散光
      eWeak: arg.弱視 == '1' ? true : false, //弱視

      noProblem: _recheck, //複檢無異狀
      eSight99: _eSight99,
      manageId: _manageId, //醫師建議處置
      periodical: _periodical, //定期檢查
      yearClassId: 0,
      okUpload: _okUpload,
      remark: _errorMsg,
    };
  });

export type PhiWHSightFile = z.infer<typeof PhiWHSightFileZod>;
