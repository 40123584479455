import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { connect, ConnectedComponent, ConnectedProps } from 'react-redux';
import {
  LoginPage,
  BasicClassStudent,
  NotFound,
  ImportStudentPage,
  UpdateStudentPage,
  LoadingPage,
  SchoolInfo,
  ClassesSetting,
  ClassPHISightPage,
  ClassPHITeePage,
  StudentPHIPage,
  SolidPDFPage,
  SightPDFPage,
  TeeUnPassPDFPage,
  HomePage,
  DocPage,
  TransferOutPage,
  TransferInPage,
  ChangeClassPage,
  PhysicalSettingPage,
  LabSemPage,
  GraduatedStudentPHIPage,
  SightSchool,
  SightNoDiagNotice,
  SightLongDilation,
  SightOddNotice,
  Sight0RankNotice,
  SightClassNotice,
  SightSolid,
  SightDilated,
  SightDiagrate2,
  WHClassStatistic,
  SightShortDilation,
  SightDilatingNear,
  SightStaticRate2,
  SightStatic,
  SightClassCorrect,
  SightClassUnCorrect,
  SightCorrect,
  SightUnCorrect,
  SightDilatingNearRate,
  SightLongDilationRate,
  SightShortDilationRate,
  CheckTempInput,
  CheckTempRep,
  SightDeteriorateRate,
  WHClassAvg,
  WHGradeStatistic,
  WHClassSight,
  WHClassBmi,
  TeeStaticSexNeatInit,
  TeeClassStaticSexNeatInit,
  TeeStaticSexNeat,
  TeeClassStaticSexNeat,
  TeeStaticSexAll,
  TeeClassStatic,
  TeeStaticSex,
  Disease,
  SeriousCard,
  BodyMindBookN,
  DiseaseClass,
  DiseaseGrade,
  DiseaseClassBodyMind,
  InjuryPage,
  TeeStatic,
  DiseaseKind,
  InjuryAccList,
  InjuryClassAccList,
  InjuryAccStCount,
  InjuryAccListSortByClass,
  InjuryAccListSortByDate,
  SightIncreasedShort,
  SightHightNear,
  SightDiagMatrix,
  SightUnCheck,
  SightCantCheck,
  WHUnCheck,
  WHCantCheck,
  NoticePage,
  WHIncressedHeight,
  TeeCleanStaticGrade,
  TeeCleanStaticClass,
  TeeFluorStaticGrade,
  TeeFluorStaticClass,
  TeeUnCheck,
  SightUnCorrectChart,
  WHHeightChart,
  WHWeightChart,
  WHBmiChart,
  SightStatisticChart,
  HospitalPage,
  Retention,
  InjuryAccStaticChart,
  InjuryAccStaticMonthChart,
  WHSightClassList,
  WHClassList,
  CheckBloodStastic,
  CheckXRayStastic,
  CheckHealthCard,
  CheckStastic,
  CheckAllQuery,
  WHSchoolSight,
  WHClassListPDFPage,
  CheckNoteSel,
  OtherStCount,
  DiseaseStaticChart,
  ClassPHIWHSightPage,
} from './pages';
import { PrivateRoute } from './components';
import { ApplicationState } from './redux/States';
import { AuthDispatches, ErrorDispatches } from './redux/Dispatches';
import Apis from './apis';
import { ToastContainer } from 'react-toastify';

import './custom.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { MenuItem } from './model';
import { ClassPHIWHPage } from './pages/ClassPHIWHPage';
import { ImportPhiWHPage } from './pages/ImporPhiWH';
import { ImportPhiSightPage } from './pages/ImportPhiSight';
import { ImportPhiTeePage } from './pages/ImportPhiTee';
import { ImportPhiSundryPage } from './pages/ImportPhiSundry';
import { ImportPhiLabPage } from './pages/ImportPhiLab';
import { ImportPhiPhysicalPage } from './pages/ImportPhiPhysical';
import { FluorSetting, CleanSet } from './pages/Tee';
import {
  ImportDiseasePage,
  ImportPhiBloodPage,
  ImportPhiWHSightPage,
  ImportPhiXRayPage,
} from './pages/Imports';

const mapState = (state: ApplicationState) => ({
  ...state.auth,
});
const mapDispatch = { ...AuthDispatches, ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const urlPageMap: {
  [k: string]: ConnectedComponent<any, any> | FunctionComponent | undefined;
} = {
  '/students': BasicClassStudent,
  '/import/student': ImportStudentPage,
  '/import/update/student': UpdateStudentPage,
  '/school': SchoolInfo,
  '/classes/setting': ClassesSetting,
  '/class/phi/wh': ClassPHIWHPage,
  '/class/phi/whsight': ClassPHIWHSightPage,
  '/class/phi/sight': ClassPHISightPage,
  '/class/phi/tee': ClassPHITeePage,
  '/class/change': ChangeClassPage,
  '/import/phi/wh': ImportPhiWHPage,
  '/import/phi/sight': ImportPhiSightPage,
  '/import/phi/sundry': ImportPhiSundryPage,
  '/import/phi/lab': ImportPhiLabPage,
  '/import/phi/physical': ImportPhiPhysicalPage,
  '/import/phi/blood': ImportPhiBloodPage,
  '/import/phi/xray': ImportPhiXRayPage,
  '/import/phi/whsight': ImportPhiWHSightPage,
  '/import/disease': ImportDiseasePage,
  '/student/phi': StudentPHIPage,
  '/student/graduated/phi': GraduatedStudentPHIPage,
  '/import/phi/tee': ImportPhiTeePage,
  '/notices/solid': SolidPDFPage,
  '/notices/sight': SightPDFPage,
  '/notices/tee': TeeUnPassPDFPage,
  '/notices/whclasslist': WHClassListPDFPage,
  '/doc': DocPage,
  '/notice': NoticePage,
  '/transfer/out': TransferOutPage,
  '/transfer/in': TransferInPage,
  '/settings/physical': PhysicalSettingPage,
  '/checktemp/class/input': CheckTempInput,
  '/inputdata/disease': Disease,
  '/inputdata/seriouscard': SeriousCard,
  '/inputdata/bodymindbookn': BodyMindBookN,
  '/settings/labsem': LabSemPage,
  '/injury': InjuryPage,
  '/tee/fluor/setting': FluorSetting,
  '/tee/clean/cleanset': CleanSet,
  '/notice/sight/nodiag': SightNoDiagNotice,
  '/notice/sight/longdilation': SightLongDilation,
  '/notice/sight/shortdilation': SightShortDilation,
  '/notice/sight/dilatingnear': SightDilatingNear,
  '/notice/sight/odd': SightOddNotice,
  '/notice/sight/0rank': Sight0RankNotice,
  '/notice/sight/school': SightSchool,
  '/notice/sight/sightdilated': SightDilated,
  '/notice/sight/class': SightClassNotice,
  '/notice/sight/solid': SightSolid,
  '/notice/sight/classcorrect': SightClassCorrect,
  '/notice/sight/classuncorrect': SightClassUnCorrect,
  '/notice/sight/sightcorrect': SightCorrect,
  '/notice/sight/sightuncorrect': SightUnCorrect,
  '/notice/sight/staticrate2': SightStaticRate2,
  '/notice/sight/static': SightStatic,
  '/notice/sight/sightdiagrate2': SightDiagrate2,
  '/notice/sight/sightdilatingnearate': SightDilatingNearRate,
  '/notice/sight/sightlongdilationrate': SightLongDilationRate,
  '/notice/sight/sightshortdilationrate': SightShortDilationRate,
  '/notice/sight/sightdeterioraterate': SightDeteriorateRate,
  '/notice/sight/sightincreasedshort': SightIncreasedShort,
  '/notice/sight/sighthightnear': SightHightNear,
  '/notice/sight/sightdiagmatrix': SightDiagMatrix,
  '/notice/sight/sightuncheck': SightUnCheck,
  '/notice/sight/sightcantcheck': SightCantCheck,
  '/notice/sight/sightuncorrectchart': SightUnCorrectChart,
  '/notice/sight/sightstatisticchart': SightStatisticChart,
  '/notice/wh/class/statistic': WHClassStatistic,
  '/notices/wh/class/list': WHClassList,
  '/notice/check/checktemprep': CheckTempRep,
  '/notice/check/checkbloodstastic': CheckBloodStastic,
  '/notice/check/checkstastic': CheckStastic,
  '/notice/check/checkxraystastic': CheckXRayStastic,
  '/notice/check/checkhealthcard': CheckHealthCard,
  '/notice/check/checkallquery': CheckAllQuery,
  '/notice/check/checknotesel': CheckNoteSel,
  '/notice/wh/class/avg': WHClassAvg,
  '/notice/wh/class/sight': WHClassSight,
  '/notice/wh/school/sight': WHSchoolSight,
  '/notice/wh/class/bmi': WHClassBmi,
  '/notice/wh/grade/statistic': WHGradeStatistic,
  '/notice/wh/whuncheck': WHUnCheck,
  '/notice/wh/whcantcheck': WHCantCheck,
  '/notice/wh/whincressedheight': WHIncressedHeight,
  '/notice/wh/whheightchart': WHHeightChart,
  '/notice/wh/whweightchart': WHWeightChart,
  '/notice/wh/whbmichart': WHBmiChart,
  '/notices/wh/whsightclasslist': WHSightClassList,
  '/notice/teeth/teestaticsexneatinit': TeeStaticSexNeatInit,
  '/notice/teeth/teeclassstaticsexneatinit': TeeClassStaticSexNeatInit,
  '/notice/teeth/teestaticsexneat': TeeStaticSexNeat,
  '/notice/teeth/teeclassstaticsexneat': TeeClassStaticSexNeat,
  '/notice/teeth/teestaticsexall': TeeStaticSexAll,
  '/notice/teeth/teestatic': TeeClassStatic,
  '/notice/teeth/teeclassstatic': TeeStatic,
  '/notice/teeth/teestaticsex': TeeStaticSex,
  '/notice/teeth/teefluorstaticgrade': TeeFluorStaticGrade,
  '/notice/teeth/teefluorstaticclass': TeeFluorStaticClass,
  '/notice/teeth/teecleanstaticgrade': TeeCleanStaticGrade,
  '/notice/teeth/teecleanstaticclass': TeeCleanStaticClass,
  '/notice/teeth/teeuncheck': TeeUnCheck,
  '/settings/hospital': HospitalPage,
  '/notice/disease/diseaseclass': DiseaseClass,
  '/notice/disease/diseasegrade': DiseaseGrade,
  '/notice/disease/diseaseclassbodymind': DiseaseClassBodyMind,
  '/notice/disease/diseasekind': DiseaseKind,
  '/notice/disease/diseasestaticchart': DiseaseStaticChart,
  '/notice/injury/injuryacclist': InjuryAccList,
  '/notice/injury/injuryclassacclist': InjuryClassAccList,
  '/notice/injury/injuryaccstcount': InjuryAccStCount,
  '/notice/injury/injuryacclistsortbyclass': InjuryAccListSortByClass,
  '/notice/injury/injuryacclistsortbydate': InjuryAccListSortByDate,
  '/notice/injury/injuryaccstaticchart': InjuryAccStaticChart,
  '/notice/injury/injuryaccstaticmonthchart': InjuryAccStaticMonthChart,
  '/notice/other/otherstcount': OtherStCount,
  '/retention': Retention,
};

const reduceToRoute = (menus: MenuItem[]) => {
  return menus.reduce((pv, cv) => {
    const page = urlPageMap[cv.url?.toLocaleLowerCase() ?? ''];
    if (page && cv.url) {
      pv.push(
        <PrivateRoute
          key={cv.url}
          exact
          path={cv.url.toLocaleLowerCase()}
          component={page}
        />
      );
    }
    if (cv.children) {
      pv = pv.concat(reduceToRoute(cv.children));
    }
    return pv;
  }, [] as ReactElement[]);
};

export const app: FunctionComponent<Props> = ({
  isAuth,
  user,
  loading,
  checkAuth,
  catchErrorForModal,
  signOut,
}) => {
  function activeTimeoutCallback() {
    signOut();
  }
  useEffect(() => {
    checkAuth().catch((e) => {
      Apis.updateCacheToken();
      catchErrorForModal(e);
    });
    Apis.registerActiveTimeout(activeTimeoutCallback);
  }, []);

  const qs = new URLSearchParams(window.location.search);
  const lastPath = qs.get('lastPath') || '/';
  if (typeof isAuth === 'undefined' || loading) {
    return (
      <React.Fragment>
        <LoadingPage />
      </React.Fragment>
    );
  }
  if (!isAuth || !user) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact={true} path="/login" component={LoginPage} />
          <Redirect from="*" to="/login" />
        </Switch>
      </React.Fragment>
    );
  }
  const { menus } = user;
  const rountes = reduceToRoute(menus);
  return (
    <React.Fragment>
      <Switch>
        <Redirect from="/login" to={lastPath} />
        {rountes}
        <PrivateRoute exact={true} path="/" component={HomePage} />
        <Route path="*" component={NotFound} />
      </Switch>
      <ToastContainer autoClose={5000} pauseOnHover newestOnTop />
    </React.Fragment>
  );
};

export const App = connector(app);
