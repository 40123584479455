import React, { ReactNode, useEffect, useState } from 'react';
import { Month, YearSem } from '../model';
import apis from '../apis';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { listToObject } from '../utils';

type Argument = {
    yearSem?: YearSem;
};

const montharr = [
    { id: 1, name: "1月" },
    { id: 2, name: "2月" },
    { id: 3, name: "3月" },
    { id: 4, name: "4月" },
    { id: 5, name: "5月" },
    { id: 6, name: "6月" },
    { id: 7, name: "7月" },
    { id: 8, name: "8月" },
    { id: 9, name: "9月" },
    { id: 10, name: "10月" },
    { id: 11, name: "11月" },
    { id: 12, name: "12月" },
]

export const useMonth = ({
    yearSem,
}: Argument): {
    selectedmonth?: Month;
    allMonth: Record<string, Month>;
    element: ReactNode;
} => {
    const [showMonthId, setShowMonthId] = useState<string>('');
    const [month, setMonth] = useState<{ [k: string]: Month }>({});
    useEffect(() => {
        if (yearSem) {
            // Filter the months based on yearSem.sem value
            const filteredMonths =
                yearSem.sem === 1
                    ? [8, 9, 10, 11, 12, 1].map((id) => montharr.find((m) => m.id === id)!)
                    : yearSem.sem === 2
                    ? [2, 3, 4, 5, 6, 7].map((id) => montharr.find((m) => m.id === id)!)
                    : [];
            setMonth(
                listToObject(
                    filteredMonths,
                    (c) => c.id,
                    (c) => c
                )
            );
        } else {
            setMonth({});
        }
        setShowMonthId('');
    }, [yearSem?.year, yearSem?.sem]);

    return {
        selectedmonth: month[showMonthId],
        allMonth: month,
        element: (
            <>
                <DropdownButton
                    title={
                        yearSem
                            ? (showMonthId && month[showMonthId].name) || '請選擇月份'
                            : '請先選擇學年'
                    }
                    onSelect={(k: string | null) => {
                        if (k == null || !month[k]) return;
                        setShowMonthId(k);
                    }}
                >
                    {Object.entries(month).map(([i, n]) => (
                        <Dropdown.Item key={`class-${i}`} eventKey={i}>
                            {n.name}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </>
        ),
    };
};
