import React, { FunctionComponent, useState, useRef, useEffect, CSSProperties, useMemo } from 'react';
import {
  Button,
  Col,
  Container,
  FormCheck,
  FormControl,
  Modal,
  Row,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import { connect, ConnectedProps } from 'react-redux';
import apis from '../apis';
import {
  AuthedLayout,
  CheckBoxField,
  DateTimeField,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  TextField,
  DropdownCheckListField,
  EditableProperties,
} from '../components';
import { PhysicalSetting, ClassSetting, SchoolRank, ExaminedResultEnum, PHIPhysical, LeftRightEnum, TeeInspection, StrabismusKindEnum, PHIBlood, PHIXRay } from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { DateTime } from 'luxon';
import { I18N } from '../i18n-raw';
import { getPhysicalGradeByRank } from '../utils/grade';
import createDecorator from 'final-form-calculate';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Nullable } from '../types';

type PhysicalSettingPageData = Nullable<PhysicalSetting> & EditableProperties;
const transformCheckedBox = (v: PhysicalSetting[keyof PhysicalSetting]) => (
  <FormCheck checked={!!v} readOnly type={'checkbox'} />
);
const modalDisplayHeader: HeaderDisplayKeysWithType<PhysicalSettingPageData>[] = [
  { property: 'year', display: '入學年' },
  { property: 'grade', display: '年級' },
  { property: 'className', display: '班級' },
  { property: 'body', display: '身體診察受檢', onRender: transformCheckedBox },
  { property: 'blood', display: '血液受檢', onRender: transformCheckedBox },
  { property: 'xRay', display: 'Ｘ光受檢', onRender: transformCheckedBox },
  { property: 'bodyAdditional', display: '身體診察追加' },
  { property: 'bloodAdditional', display: '血液追加' },
  {
    property: 'bloodPressure',
    display: '血壓判讀',
    onRender: transformCheckedBox,
  },
  { property: 'pulse', display: '脈搏判讀', onRender: transformCheckedBox },
  { property: 'checkUnit', display: '健檢單位' },
  { property: 'doctor', display: '檢查醫師' },
  { property: 'teeDoctor', display: '牙科醫師' },
  {
    property: 'checkDate',
    display: '檢查日期',
    onRender: (v) => {
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
    },
  },
];

const mapState = (state: ApplicationState) => ({
  ...state.auth,
});
const dispatches = ErrorDispatches;
const connector = connect(mapState, dispatches);
const dashBorder: CSSProperties = {
  borderTopWidth: '2px',
  borderTopColor: 'gray',
  borderTopStyle: 'dashed',
};
type Props = ConnectedProps<typeof connector>;
interface CheckedClasses {
  [key: string]: boolean;
}
const physicalSettingPage: FunctionComponent<Props> = ({
  user,
  loading,
  catchErrorForModal,
}) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  const [inputing, setInputing] = useState(false);
  const [editing, setEditing] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [updateStudent, setUpdateStudent] = useState(false);
  const [editingSetting, setEditingSetting] = useState<PhysicalSetting>(
    {} as PhysicalSetting
  );
  const [settings, setSettings] = useState<PhysicalSetting[]>([]);
  const [classSettings, setClassSettings] = useState<ClassSetting[]>([]);
  const showCurrentRef = useRef<HTMLInputElement>(null);
  const updateStudentRef = useRef<HTMLInputElement>(null);
  const fromInputRef = useRef<HTMLInputElement>(null);
  const toInputRef = useRef<HTMLInputElement>(null);
  const physicalGradeRange = getPhysicalGradeByRank(
    user.schools[user.currentSchool].rank ?? SchoolRank.Junior
  );
  const [checkedClasses, setCheckedClasses] = useState<CheckedClasses>({});
  const [selectedIndex, setSelectedIndex] = useState<Set<number>>(
    new Set<number>()
  );
  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    const newCheckedClasses: CheckedClasses = {};
    classSettings.forEach((item) => {
      newCheckedClasses[item.id] = checked;
    });
    setCheckedClasses(newCheckedClasses);
  };
  const handleClassCheckChange = (id: string, checked: boolean) => {
    setCheckedClasses((prevState) => ({
      ...prevState,
      [id]: checked
    }));

    // 如果有一個 checkbox 被取消勾選，全選也應取消
    if (!checked) {
      setSelectAll(false);
    } else {
      const allChecked = classSettings.every(
        (item) => checkedClasses[item.id] || item.id === id
      );
      if (allChecked) {
        setSelectAll(true);
      }
    }
  };
  // const [bodyAdditional, setBodyAdditional] = useState<string[]>([]);
  // const [bloodAdditional, setBloodAdditional] = useState<string[]>([]);

  const { year, sem } = user.semesters[user.currentSemester];
  const currentSchool = user.currentSchool;
  const { rank } = user.schools[user.currentSchool];
  const physicalSettingData = useMemo(refreshSettings, [
    selectedIndex, settings
  ]);

  useEffect(() => {
    if (!!year && fromInputRef.current && toInputRef.current) {
      fromInputRef.current.value = year - 5 + '';
      toInputRef.current.value = year + '';
    }
    getSettings();
  }, [fromInputRef.current, toInputRef.current]);

  useEffect(() => {
    setUpdateStudent(!selectedIndex.size);
  }, [selectedIndex]);

  function refreshSettings() {
    return settings.map((s, i) => ({
      ...s,
      selected: selectedIndex.has(i),
      classId: classSettings.find(c => c.grade === s.grade && c.no === s.no)?.id
    }));
  }

  async function getSettings() {
    try {
      let from = year;
      let to = year;
      if (!fromInputRef.current || !fromInputRef.current?.value) {
        if (fromInputRef.current) fromInputRef.current.value = year + '';
      }
      if (!toInputRef.current || !toInputRef.current?.value) {
        if (toInputRef.current) toInputRef.current.value = year + '';
      }
      if (showCurrentRef.current?.checked) {
        from = +(fromInputRef.current?.value ?? year);
        to = +(toInputRef.current?.value ?? year);
      }
      const classes = await apis.getClassSettings(year, sem);
      const rawSettings = await apis.getPhysicalSettings(from, to);
      // const grades = getPhysicalGradeByRank(rank);
      const newSettings = rawSettings.map((e, i) => {
        const classData = classes.find(s => s.grade === e.grade && s.no === e.no);
        if (classData) {
          return { ...e, className: classData.name};
        }
        return e;
      });
      setSelectedIndex(new Set(newSettings.map((_, i) => i)));
      setSettings(newSettings.map((p, i) => ({
        ...p,
        selected: selectedIndex.has(i),
      })));
      setClassSettings(classes.filter((c) => physicalGradeRange.includes(c.grade)));
    } catch (e) {
      return catchErrorForModal(e);
    }
  }

  function onEdit(setting: PhysicalSettingPageData) {
    if(setting) {
      const editPhysical = settings.find(s => s.year == setting.year && s.grade == setting.grade && s.no == setting.no);
      if(editPhysical) {
        setEditingSetting(editPhysical);
      }
    }
    // setBodyAdditional(setting.bodyAdditional);
    // setBloodAdditional(setting.bloodAdditional);
    setInputing(true);
    setEditing(true);
  }

  function onDelete(setting: PhysicalSettingPageData) {
    if(setting) {
      const deletePhysical = settings.find(s => s.year == setting.year && s.grade == setting.grade && s.no == setting.no);
      if(deletePhysical) {
        apis
        .deletePhysicalSetting(deletePhysical)
        .then(() => {
          toast.success("成功刪除");
        })
        .catch(catchErrorForModal);
      }
    }
  }

  function onHide() {
    setEditingSetting({} as PhysicalSetting);
    // setBodyAdditional([]);
    // setBloodAdditional([]);
    setUpdateStudent(false);
    setInputing(false);
    setEditing(false);
    setCheckedClasses({});
  }

  function upsertSetting(setting: PhysicalSetting) {
    if(editing) {
      toast
      .promise(
        apis
        .upsertPhysicalSetting(setting),
        {
          pending: '資料更新中......',
          success: '更新成功！',
        })
      .then(() => {
        onHide();
        getSettings();
      })
      .catch(catchErrorForModal);
    } else {
      const classes = classSettings.filter((item) => checkedClasses[item.id]); // 勾選班級
      if(classes.length) {
        const data = classes.map((x) => {
          return {
            ...setting,
            grade: x.grade,
            no: x.no
          }
        });
        toast
        .promise(
          apis
            .upsertPhysicalSettingList(data),
            {
              pending: '資料設定中......',
              success: '設定成功！',
            })
        .then(() => {
          onHide();
          getSettings();
        })
        .catch(catchErrorForModal);
      } else {
        toast.error("請選擇班級");
      }
    }
  }

  function updateStudentPhysical() {
    const classIds = physicalSettingData.filter((s) => s.selected).map((s) => s.classId);
    if(classIds.length) {
      toast
        .promise(
          apis
          .updateStudentPhysicalSetting(
            physicalSettingData.filter((s) => s.selected),
            generateDefaultPhysical(physicalSettingData[0].grade),
            DEFAULT_BLOOD_RESULT,
            DEFAULT_XRAY_RESULT,
            classIds as string[],
            year,
            sem
          ),
          {
            pending: '資料更新中......',
            success: '更新成功！',
          }
        )
        .then(() => {
          onHide();
          getSettings();
        })
        .catch(catchErrorForModal);
    }
  }

  return (
    <AuthedLayout>
      <Row className="mb-4">
        <Col>
          <FormCheck
            ref={showCurrentRef}
            label="載入全年級設定資料"
            onChange={() => getSettings()} //TODO
          />
        </Col>
        顯示：
        <Col sm={1}>
          <FormControl
            min={year - 5}
            max={year}
            ref={fromInputRef}
            disabled={!showCurrentRef.current?.checked}
          />
        </Col>
        年~
        <Col sm={1}>
          <FormControl
            min={year - 5}
            max={year}
            ref={toInputRef}
            disabled={!showCurrentRef.current?.checked}
          />
        </Col>
        年
        <Col sm={2} className="ml-3 text-right">
          <Button
            disabled={!showCurrentRef.current?.checked}
            onClick={() => getSettings()}
          >
            查詢
          </Button>
          <Button
            className='ml-2 bg-info'
            disabled={false}
            onClick={() => {
              setInputing(true);
              setEditingSetting({
                year: year,
                schoolId: '',
                grade: 0,
                no: 0,
                body: false,
                blood: false,
                xRay: false,
                bodyAdditional: [],
                bloodAdditional: [],
                bloodPressure: false,
                pulse: false,
                checkUnit: null,
                doctor: null,
                teeDoctor: null,
                checkDate: null,
              } as PhysicalSetting);
            }}
          >
            設定
          </Button>
        </Col>
      </Row>
      <Row className="mb-2">
        <Button
          className='mr-1'
          disabled={updateStudent}
          onClick={() => updateStudentPhysical()}
        >
          更新每位學生健康資料
        </Button>根據上方表格，將更新已勾選班級的學生健康資料
      </Row>
      <Row className="mb-2">
        <EditableTableDiv
          headers={modalDisplayHeader}
          values={physicalSettingData}
          editable
          deleteable
          onSelected={(s) => {
            setSelectedIndex((last) => {
              if (s.index !== undefined) {
                if (s.checked) last.add(s.index);
                else last.delete(s.index);
                return new Set(last);
              } else {
                if (s.checked) return new Set(settings.keys());
                else return new Set();
              }
            });
          }}
          onEdit={(v) => onEdit(v)}
          onDelete={(v) => onDelete(v)}
        />
      </Row>
      <Modal
        show={inputing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="contained-modal-title-vcenter">
            健康資料設定 - <strong>{editingSetting?.year}</strong>
          </Modal.Title>
        </Modal.Header>
        <Form
          initialValues={editingSetting}
          onSubmit={upsertSetting}
          decorators={FormDecorators}
          subscription={{ submitting: true, pristine: true }}
          render={(prop) => {
            const { values, handleSubmit } = prop;
            return (
              <React.Fragment>
                <Modal.Body className="text-center">
                  <Container>
                    <Row className="mb-2">
                      <Col sm={1}>入學年:</Col>
                      <Col sm={3}>
                        <TextField property="year" disabled />
                      </Col>
                      {editing && (
                        <>
                          <Col sm={1}>年級:</Col>
                          <Col sm={3}>
                            {editingSetting.grade}年{editingSetting.className}班
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row className="mb-2">
                      <Col sm={1}>身體診察受檢:</Col>
                      <Col sm={3}>
                        <CheckBoxField property="body" />
                      </Col>
                      <Col sm={1}>血液受檢:</Col>
                      <Col sm={3}>
                        <CheckBoxField property="blood" />
                      </Col>
                      <Col sm={1}>Ｘ光受檢:</Col>
                      <Col sm={3}>
                        <CheckBoxField property="xRay" />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={1}>身體診察追加:</Col>
                      <Col sm={3}>
                        <DropdownCheckListField
                          property="bodyAdditional"
                          content={I18N.PhysicalBodyAdditional}
                        />
                      </Col>
                      <Col sm={1}>血液追加:</Col>
                      <Col sm={3}>
                        <DropdownCheckListField
                          property="bloodAdditional"
                          content={I18N.PhysicalBloodAdditional}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={1}>血壓判讀:</Col>
                      <Col sm={3}>
                        <CheckBoxField property="bloodPressure" />
                      </Col>
                      <Col sm={1}>脈搏判讀:</Col>
                      <Col sm={3}>
                        <CheckBoxField property="pulse" />
                      </Col>
                      <Col sm={1}>健檢單位:</Col>
                      <Col sm={3}>
                        <TextField property="checkUnit" />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={1}>檢查醫師:</Col>
                      <Col sm={3}>
                        <TextField property="doctor" />
                      </Col>
                      <Col sm={1}>牙科醫師:</Col>
                      <Col sm={3}>
                        <TextField property="teeDoctor" />
                      </Col>
                      <Col sm={1}>檢查日期:</Col>
                      <Col sm={3}>
                        <DateTimeField property="checkDate" />
                      </Col>
                    </Row>
                    {!editing && ( 
                      <Row className='pt-3 mt-4' style={dashBorder}>
                        <Col sm={1}>班級:</Col>
                        <Col className='text-left'>
                          <FormCheck
                            key='allClass'
                            checked={selectAll}
                            label='全選'
                            onChange={(e) => handleSelectAll(e.currentTarget.checked)}
                          />
                          <div className='d-flex'>
                            {classSettings.map((item) => (
                              <FormCheck
                                className='mr-2'
                                key={item.id}
                                checked={checkedClasses[item.id] || false}
                                label={`${item.grade}年${item.name}班`}
                                onChange={(e) => {
                                  handleClassCheckChange(item.id, e.currentTarget.checked);
                                  console.log(checkedClasses)
                                }}
                              />
                            ))}
                          </div>
                        </Col>
                      </Row>)}
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" onClick={handleSubmit}>
                    儲存
                  </Button>
                  <Button type="reset" variant="secondary" onClick={onHide}>
                    關閉
                  </Button>
                </Modal.Footer>
              </React.Fragment>
            );
          }}
        />
      </Modal>
    </AuthedLayout>
  );
};

export const PhysicalSettingPage = connector(physicalSettingPage);

function generateDefaultPhysical(grade: number): PHIPhysical {
  switch (grade) {
    case 1:
      return {
        ...DefaultPhysical,
        // eye
        trichiasis: ExaminedResultEnum.NoProblem,

        // Ear, Nose, and Throat
        eardrum: ExaminedResultEnum.NoProblem,
        earMisshapen: ExaminedResultEnum.NoProblem,
        cerumen: ExaminedResultEnum.NoProblem,
        tonsillitis: ExaminedResultEnum.NoProblem,

        cryptorchidism: ExaminedResultEnum.NoProblem,
        t15: TeeInspection.None,
      };
    case 4:
      return {
        ...DefaultPhysical,
        trichiasis: ExaminedResultEnum.NoProblem,

        // Ear, Nose, and Throat
        eardrum: ExaminedResultEnum.NoProblem,
        earMisshapen: ExaminedResultEnum.NoProblem,
        cerumen: ExaminedResultEnum.NoProblem,
        tonsillitis: ExaminedResultEnum.NoProblem,
        t15: TeeInspection.None,
      };
    case 7:
      return {
        ...DefaultPhysical,
        t09: ExaminedResultEnum.NoProblem,
      };
    case 10:
    case 13:
      return {
        ...DefaultPhysical,
        bpResult: ExaminedResultEnum.NoProblem,
        pulseResult: ExaminedResultEnum.NoProblem,
        t09: ExaminedResultEnum.NoProblem,
      };
    default:
      return {
        ...DefaultPhysical,
        colorBlind: ExaminedResultEnum.NoExamined,
        e99: ExaminedResultEnum.NoExamined,

        hearing: ExaminedResultEnum.NoExamined,
        o99: ExaminedResultEnum.NoExamined,

        torticollis: ExaminedResultEnum.NoExamined,
        mass: ExaminedResultEnum.NoExamined,
        goiter: ExaminedResultEnum.NoExamined,
        lymphadenectasis: ExaminedResultEnum.NoExamined,
        mass99: ExaminedResultEnum.NoExamined,
        n99: ExaminedResultEnum.NoExamined,

        cardiopulmonary: ExaminedResultEnum.NoExamined,
        heartMurmur: ExaminedResultEnum.NoExamined,
        arrhythmia: ExaminedResultEnum.NoExamined,
        wheeze: ExaminedResultEnum.NoExamined,
        cardiopulmonary99: ExaminedResultEnum.NoExamined,
        thorax: ExaminedResultEnum.NoExamined,
        c99: ExaminedResultEnum.NoExamined,

        abdomen: ExaminedResultEnum.NoExamined,
        a99: ExaminedResultEnum.NoExamined,

        scoliosis: ExaminedResultEnum.NoExamined,
        dysmelia: ExaminedResultEnum.NoExamined,
        polydactyly: ExaminedResultEnum.NoExamined,
        dysarthrosis: ExaminedResultEnum.NoExamined,
        dysmelia99: ExaminedResultEnum.NoExamined,
        squatting: ExaminedResultEnum.NoExamined,
        l99: ExaminedResultEnum.NoExamined,

        prepuce: ExaminedResultEnum.NoExamined,
        varicocele: ExaminedResultEnum.NoExamined,
        u99: ExaminedResultEnum.NoExamined,

        epidermophytosis: ExaminedResultEnum.NoExamined,
        scabies: ExaminedResultEnum.NoExamined,
        wart: ExaminedResultEnum.NoExamined,
        atopicDermatitis: ExaminedResultEnum.NoExamined,
        eczema: ExaminedResultEnum.NoExamined,
        d99: ExaminedResultEnum.NoExamined,

        t01: ExaminedResultEnum.NoExamined,
        t04: ExaminedResultEnum.NoExamined,
        t05: ExaminedResultEnum.NoExamined,
        t08: ExaminedResultEnum.NoExamined,
        t07: ExaminedResultEnum.NoExamined,
        t99: ExaminedResultEnum.NoExamined,
      };
  }
}

const FormDecorators = [
  createDecorator<PhysicalSetting>(
    {
      field: /body[Additional]?/,
      updates: {
        body: (value: boolean, allValues: PhysicalSetting | undefined) => {
          return (
            !!allValues &&
            (allValues?.body || !!allValues?.bodyAdditional?.length || false)
          );
        },
      },
    },
    {
      field: /blood[Additional]?/,
      updates: {
        blood: (value: boolean, allValues: PhysicalSetting | undefined) => {
          return (
            !!allValues &&
            (allValues?.blood || !!allValues?.bloodAdditional?.length || false)
          );
        },
      },
    }
  ),
];
const DefaultPhysical: PHIPhysical = {
  year: 0,
  sem: 0,
  grade: 0,
  yearClassId: 0,
  id: 0,
  bpResult: ExaminedResultEnum.NoExamined,
  waistResult: ExaminedResultEnum.NoExamined,
  pulseResult: ExaminedResultEnum.NoExamined,
  colorBlind: ExaminedResultEnum.NoProblem,
  e99: ExaminedResultEnum.NoProblem,
  hearing: ExaminedResultEnum.NoProblem,
  hearingLR: LeftRightEnum.None,
  earMisshapen: ExaminedResultEnum.NoExamined,
  earMisshapenLR: LeftRightEnum.None,
  eardrum: ExaminedResultEnum.NoExamined,
  eardrumLR: LeftRightEnum.None,
  cerumen: ExaminedResultEnum.NoExamined,
  cerumenLR: LeftRightEnum.None,
  tonsillitis: ExaminedResultEnum.NoExamined,
  o99: ExaminedResultEnum.NoProblem,
  torticollis: ExaminedResultEnum.NoProblem,
  mass: ExaminedResultEnum.NoProblem,
  goiter: ExaminedResultEnum.NoProblem,
  lymphadenectasis: ExaminedResultEnum.NoProblem,
  mass99: ExaminedResultEnum.NoProblem,
  n99: ExaminedResultEnum.NoProblem,
  cardiopulmonary: ExaminedResultEnum.NoProblem,
  heartMurmur: ExaminedResultEnum.NoProblem,
  arrhythmia: ExaminedResultEnum.NoProblem,
  wheeze: ExaminedResultEnum.NoProblem,
  cardiopulmonary99: ExaminedResultEnum.NoProblem,
  thorax: ExaminedResultEnum.NoProblem,
  c99: ExaminedResultEnum.NoProblem,
  abdomen: ExaminedResultEnum.NoProblem,
  a99: ExaminedResultEnum.NoProblem,
  scoliosis: ExaminedResultEnum.NoProblem,
  dysmelia: ExaminedResultEnum.NoProblem,
  polydactyly: ExaminedResultEnum.NoProblem,
  dysarthrosis: ExaminedResultEnum.NoProblem,
  dysmelia99: ExaminedResultEnum.NoProblem,
  squatting: ExaminedResultEnum.NoProblem,
  l99: ExaminedResultEnum.NoProblem,
  cryptorchidism: ExaminedResultEnum.NoExamined,
  prepuce: ExaminedResultEnum.NoProblem,
  varicocele: ExaminedResultEnum.NoProblem,
  u99: ExaminedResultEnum.NoProblem,
  epidermophytosis: ExaminedResultEnum.NoProblem,
  scabies: ExaminedResultEnum.NoProblem,
  wart: ExaminedResultEnum.NoProblem,
  atopicDermatitis: ExaminedResultEnum.NoProblem,
  eczema: ExaminedResultEnum.NoProblem,
  d99: ExaminedResultEnum.NoProblem,
  t01: ExaminedResultEnum.NoProblem,
  t02: ExaminedResultEnum.NoProblem,
  t03: ExaminedResultEnum.NoProblem,
  t04: ExaminedResultEnum.NoProblem,
  t05: ExaminedResultEnum.NoProblem,
  t06: ExaminedResultEnum.NoExamined,
  t07: ExaminedResultEnum.NoProblem,
  t08: ExaminedResultEnum.NoProblem,
  t99: ExaminedResultEnum.NoProblem,
  t09: ExaminedResultEnum.NoExamined,
  t11: TeeInspection.None,
  t12: TeeInspection.None,
  t13: TeeInspection.None,
  t15: TeeInspection.NoInspection,
  t16: ExaminedResultEnum.NoProblem,
  t17: ExaminedResultEnum.NoProblem,
  t18: ExaminedResultEnum.NoProblem,
  t19: ExaminedResultEnum.NoProblem,
  strabismus: ExaminedResultEnum.NoProblem,
  strabismusType: StrabismusKindEnum.None,
  trichiasis: ExaminedResultEnum.NoExamined,
  nystagmus: ExaminedResultEnum.NoExamined,
  blepharoptosis: ExaminedResultEnum.NoExamined,
  clp: ExaminedResultEnum.NoExamined,
  articulationDisorders: ExaminedResultEnum.NoExamined,
  preauricularFistula: ExaminedResultEnum.NoExamined,
  preauricularFistulaLR: ExaminedResultEnum.NoExamined,
  rhinitis: ExaminedResultEnum.NoExamined,
  allergicRhinitis: ExaminedResultEnum.NoExamined,
  hernia: ExaminedResultEnum.NoExamined,
  edema: ExaminedResultEnum.NoExamined,
  scrotalSwelling: ExaminedResultEnum.NoExamined,
  purpura: ExaminedResultEnum.NoExamined,
};
const DEFAULT_BLOOD_RESULT: PHIBlood = {
  id: 0,
  year: 0,
  sem: 0,
  grade: 0,
  yearClassId: 0,
  hbResult: ExaminedResultEnum.NoProblem,
  wbcResult: ExaminedResultEnum.NoProblem,
  rbcResult: ExaminedResultEnum.NoProblem,
  plResult: ExaminedResultEnum.NoProblem,
  mcvResult: ExaminedResultEnum.NoProblem,
  clResult: ExaminedResultEnum.NoProblem,
  crResult: ExaminedResultEnum.NoProblem,
  uaResult: ExaminedResultEnum.NoProblem,
  gotResult: ExaminedResultEnum.NoProblem,
  gptResult: ExaminedResultEnum.NoProblem,
  hbsaResult: ExaminedResultEnum.NoProblem,
  antiHBResult: ExaminedResultEnum.NoProblem,
  bunResult: ExaminedResultEnum.NoExamined,
  tgResult: ExaminedResultEnum.NoExamined,
  hdlResult: ExaminedResultEnum.NoExamined,
  ldlResult: ExaminedResultEnum.NoExamined,
  htResult: ExaminedResultEnum.NoExamined,
  acResult: ExaminedResultEnum.NoExamined,
  pcResult: ExaminedResultEnum.NoExamined,
  hBeAgResult: ExaminedResultEnum.NoExamined,
};
const DEFAULT_XRAY_RESULT: PHIXRay = {
  id: 0,
  year: 0,
  sem: 0,
  grade: 0,
  examined: true,
  xRay: ExaminedResultEnum.NoProblem,
  phthisis: ExaminedResultEnum.NoProblem,
  calcify: ExaminedResultEnum.NoProblem,
  c03: ExaminedResultEnum.NoProblem,
  water: ExaminedResultEnum.NoProblem,
  c02: ExaminedResultEnum.NoProblem,
  bigHeart: ExaminedResultEnum.NoProblem,
  brochus: ExaminedResultEnum.NoProblem,
  x99: ExaminedResultEnum.NoProblem,
};
