import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import XLSX from 'xlsx';
import { z } from 'zod';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../components';
import Apis from '../apis';
import {
  PhiWH,
  PhiWHFile,
  PhiWHFileZod,
  ClassStudent,
  CheckField,
} from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { dateTimeFromString } from '../utils/date';
import {
  CheckShouldUseIdentify,
  assignExists,
  checkOkToUpload,
  takeClassStudent,
} from '../utils';
import { useActiveSems } from '../hook';

const inputFileParser = z.array(PhiWHFileZod);
const headerDisplayKeys: HeaderDisplayKeysWithType<PhiWHFile>[] = [
  { display: '備註', property: 'remark' },
  { display: '身份證字號', property: 'pid' },
  { display: '學號', property: 'sid' },
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { display: '學期', property: 'sem' },
  { display: '身高', property: 'height' },
  { display: '體重', property: 'weight' },
  {
    display: '測量日',
    property: 'examDate',
    onRender: (v) => {
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
    },
  },
];

function filterOutNonPhiWHField({
  remark,
  examDate,
  ...phiwh
}: PhiWHFile): PhiWH {
  return { ...phiwh, examDate: examDate ? dateTimeFromString(examDate) : null };
}

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatch = ErrorDispatches;
const connector = connect(mapState, mapDispatch);
const validHeaders = ['身分證', '學號', '年級班級座號', '學期', '身高', '體重', '測量日'];
type Props = ConnectedProps<typeof connector>;

const importPhiWHPage: FunctionComponent<Props> = ({
  catchErrorForModal,
  showError,
  user: { semesters, currentSemester },
}) => {
  const [fileName, setFileName] = useState('檔案'); // 如 :  const [fileName, setFileName] = ''; 可?
  const [fileData, setFileData] = useState([] as PhiWHFile[]);
  const [okToUpload, setOkToUpload] = useState(false);
  const [checkField, setCheckField] = useState(CheckField.None);

  const { selectedSemester, yearSemElement: yearSemElement } = useActiveSems(
    semesters,
    currentSemester
  );

  return (
    <AuthedLayout>
      <Row className="mb-2">匯入學期：{yearSemElement}</Row>
      <Row className="mb-2">
        下載匯入格式：
        <a href="/Doc/身高體重13.xls" style={{ textDecoration: 'underline' }}>
          身高體重匯入格式
        </a>
      </Row>
      <Row className="mb-2">
        <Form.File
          type="file"
          className="my-file-label"
          id="inputGroupFile01"
          label={fileName}
          accept=".xlsx, .csv, .xls, .ods, .ots"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const { files } = e.target;
            try {
              if (files?.length === 1) {
                setFileName(files[0].name);
                const buf = await files[0].arrayBuffer();
                const workShop = XLSX.read(buf, {
                  type: 'buffer',
                  sheets: 0,
                });
                if (workShop.SheetNames[0]) {
                  const jsonData = XLSX.utils.sheet_to_json(
                    workShop.Sheets[workShop.SheetNames[0]],
                    { raw: false, rawNumbers: false }
                  );
                  const headerData = XLSX.utils.sheet_to_json(workShop.Sheets[workShop.SheetNames[0]], { header: 1 });
                  const headers = headerData[0] as string[];
                  const isCorrectFile = validHeaders.every((h: string, i: number) => h === headers[i]);
                  // 檢查表頭是否正確
                  if(!isCorrectFile) {
                    showError('Excel中的表頭欄位不正確，請確認是否選擇了正確的檔案。');
                    return;
                  }
                  const ss = await inputFileParser.parseAsync(
                    jsonData.map((j: any) => ({
                      ...j,
                      year: selectedSemester.year,
                      sem: selectedSemester.sem,
                    }))
                  );
                  if (ss) {
                    const [cf, matchFunc] = CheckShouldUseIdentify(ss);
                    setCheckField(cf);
                    if (cf == CheckField.None) {
                      showError(
                        '匯入之檔案應有唯一使用"身份證"或"學號"或"班級座號"'
                      );
                      return;
                    }
                    await Apis.checkClassesStudentExists(
                      takeClassStudent(ss),
                      cf,
                      selectedSemester
                    )
                      .then((result) => {
                        // console.log('importPhiWH.ts #104',result)
                        // setFileData (merge result&ss)
                        setFileData(assignExists(matchFunc, ss, result));
                        //check ss is okToUpload ,then setOkToUpload(true);
                        setOkToUpload(checkOkToUpload(true, ss));
                      })
                      .catch((e) => {
                        catchErrorForModal(e);
                        setOkToUpload(false);
                      });
                  } else {
                    console.log(ss);
                  }
                }
              }
            } catch (e) {
              console.log('importPhiWH.ts #143', e);
              setFileName('檔案');
              setFileData([]);
              setOkToUpload(false);
              showError('檔案內容不正確，請檢查資料內容格式');
            }
            e.target.value = '';
          }}
          custom
        />
        <Button
          className="mr-0"
          disabled={!okToUpload}
          onClick={() => {
            if (fileData.length > 0 && checkField != CheckField.None)
              toast
                .promise(
                  Apis.insertPhiWH(
                    fileData.map(filterOutNonPhiWHField),
                    checkField,
                    selectedSemester
                  ),
                  {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                  }
                )
                .then(() => {
                  setOkToUpload(false);
                })
                .catch(catchErrorForModal);
          }}
        >
          匯入資料
        </Button>
      </Row>
      <Row>
        <EditableTableDiv
          headers={headerDisplayKeys}
          values={fileData}
          onRowRender={({ examDate, ...rest }) => ({
            rowClassName: rest.remark ? 'bg-danger text-white' : '',
          })}
        />
      </Row>
    </AuthedLayout>
  );
};

export const ImportPhiWHPage = connector(importPhiWHPage);
