import React, {
  FunctionComponent,
  ReactNode,
  RefObject,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ExaminedResultEnum,
  PHIXRay,
  Semester,
  Student,
  YearSemClasses,
} from '../../model';
import {
  HeaderDisplayKeysWithType,
  InputDropdownGroupField,
  TextField,
} from '../../components';
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import { I18N } from '../../i18n-raw';
import apis, { ApiError, E_GRADE_INVALID } from '../../apis';
import createCalculator from 'final-form-calculate';
import { HoverToolTip } from '../../components/HoverTooltip';

type Props = {
  editable: boolean;
  xRay: PHIXRay[];
  student: Student;
  schoolId: string;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  grade: number;
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
};

export const XraySection: FunctionComponent<Props> = ({
  editable,
  xRay,
  student,
  schoolId,
  semesters,
  yearSems,
  grade,
  onApiError,
  onValueUpdated,
}) => {
  const [inputing, setInputing] = useState(false);
  const currentXRay = useMemo(
    () => xRay.find((s) => s.grade === grade && s.sem === 1),
    [grade, xRay]
  );
  function onValueUpdate(value: PHIXRay, examined: boolean) {
    const validGrade = yearSems.find((s) => s.grade === grade);
    if (validGrade) {
      const promise = examined
        ? apis.InsertStudentXRay(student.pid, {
            ...DEFAULT_XRAY_RESULT,
            ...value,
            grade: validGrade.grade,
            sem: 1,
            year: validGrade.year,
          })
        : apis.deleteStudentXRay(student.pid, validGrade.year, 1);

      promise.catch(onApiError).then(onValueUpdated);
    } else {
      onApiError(new ApiError(E_GRADE_INVALID));
    }
    setInputing(false);
  }

  const serialized = serializeXRay(currentXRay);

  return (
    <>
      <div className="sheet-title">
        X光檢查 {grade ?? ''}年級
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
          disabled={currentXRay?.schoolId !== schoolId}
        >
          {currentXRay && currentXRay.schoolId !== schoolId ? (
            <HoverToolTip tooltip={'無法修改非本校資料'}>
              <span className="feather icon-x text-danger border border-danger rounded" />
            </HoverToolTip>
          ) : (
            <span className="feather icon-edit" />
          )}
        </Button>
      </div>
      <Table bordered className="student-phi mb-2">
        <tbody>
          <tr>
            <th>受檢</th>
            <td>{currentXRay ? '有受檢' : '尚未受檢'}</td>
          </tr>
          {serialized.length ? (
            <tr>
              <th>血液檢查</th>
              <td>{serialized}</td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {inputing ? (
        <XrayModel
          xRay={currentXRay}
          student={student}
          grade={grade}
          onClose={() => setInputing(false)}
          onValueUpdate={onValueUpdate}
        />
      ) : (
        <></>
      )}
    </>
  );
};

type ModelProps = {
  xRay?: PHIXRay;
  student: Student;
  grade: number;
  onClose: () => void;
  onValueUpdate: (v: PHIXRay, examined: boolean) => void;
};
function XrayModel({
  xRay,
  student,
  grade,
  onClose,
  onValueUpdate,
}: ModelProps) {
  const [examined, setExamined] = useState(!!xRay);
  const divRef = useRef<HTMLDivElement>(null);
  return (
    <Form
      initialValues={xRay ?? NO_EXAMINED_XRAY_RESULT}
      decorators={PHIXRayCalculators}
      onSubmit={(v: PHIXRay) => {
        onValueUpdate(v, examined);
      }}
      validateOnBlur
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit, form }) => {
        return (
          <Modal
            show={true}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            dialogClassName="modal-dialog-full"
            centered
          >
            <Modal.Header closeButton onHide={onClose}>
              <Modal.Title id="contained-modal-title-vcenter">
                X光檢查 -{' '}
                <strong>
                  {student.seat} {student.name}
                </strong>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body ref={divRef} className="overflow-auto">
              <Table striped bordered hover className="text-break">
                <colgroup>
                  <col width={'7%'} />
                  <col width={'90%'} />
                </colgroup>
                <thead>
                  <tr>
                    <td className="align-middle">項目</td>
                    <td className="align-middle">
                      <Row noGutters className="align-items-center">
                        <Col xs={12} md={2}>
                          身分證： {student.pid}
                        </Col>
                        <Col xs={12} md={2}>
                          檢查年級： {grade}
                        </Col>
                        <Col xs={12} md={2}>
                          是否受檢：{' '}
                          <DropdownButton
                            className="d-inline mr-2"
                            title={examined ? '是' : '否'}
                            onSelect={(k: string | null) => {
                              setExamined((lastExamine) => {
                                const nextExamine = k == '0' ? false : true;
                                if (!lastExamine && nextExamine) {
                                  form.reset(DEFAULT_XRAY_RESULT);
                                } else if (!nextExamine) {
                                  form.reset(NO_EXAMINED_XRAY_RESULT);
                                }
                                return nextExamine;
                              });
                            }}
                          >
                            <Dropdown.Item eventKey={0}>否</Dropdown.Item>
                            <Dropdown.Item eventKey={1}>是</Dropdown.Item>
                          </DropdownButton>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>X光</td>
                    <td>
                      <Row noGutters>
                        {generateInput('xRay', divRef, !examined)}
                        {generateInput('phthisis', divRef, !examined)}
                        {generateInput('calcify', divRef, !examined)}
                        {generateInput('c03', divRef, !examined)}
                        {generateInput('water', divRef, !examined)}
                        {generateInput('c02', divRef, !examined)}
                        {generateInput('bigHeart', divRef, !examined)}
                        {generateInput('brochus', divRef, !examined)}
                      </Row>
                      <Row noGutters>
                        <Col className="d-flex align-items-center">
                          <InputDropdownGroupField
                            className={`wid-40 mx-1`}
                            property="x99"
                            content={I18N.ExaminationResult}
                            type="number"
                            needGroup={false}
                            disabled={!examined}
                          />
                          <div className="wid-80">其他</div>
                          <TextField property="x99State" />
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>備註</td>
                    <td>
                      <TextField property="memos" />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="align-middle">
                      <Button className="mx-2" onClick={() => handleSubmit()}>
                        <span className="feather icon-check mr-1" />
                        儲存
                      </Button>
                      <Button
                        className="mx-2"
                        variant="danger"
                        onClick={onClose}
                      >
                        <span className="feather icon-x  mr-1" />
                        取消
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
          </Modal>
        );
      }}
    />
  );
}

function generateInput(
  property: string,
  dropdownRef: RefObject<HTMLElement>,
  disabled: boolean
) {
  return (
    <Col sm={4} md={3} className="d-flex align-items-center my-1">
      <InputDropdownGroupField
        className={`wid-40 mx-1`}
        property={property}
        content={I18N.ExaminationResult}
        type="number"
        needGroup={false}
        disabled={disabled}
      />
      <div>{I18N.XRay[property]}</div>
    </Col>
  );
}

function serializeXRay(xRay?: PHIXRay): ReactNode[] {
  if (!xRay) return [];

  const nodes = Object.entries(I18N.XRay).reduce((nodes, [key, display]) => {
    const result = (xRay as any)[key];
    if (invalidStatus(result)) {
      nodes.push(
        <div key={key}>
          <span className="text-danger">*{display}:</span>
          {result}
        </div>
      );
    }

    return nodes;
  }, [] as ReactNode[]);

  if (invalidStatus(xRay.x99)) {
    nodes.push(
      <div key="x99">
        <span className="text-danger">*其他：</span>
        {xRay.x99}
      </div>
    );
    nodes.push(
      <div key="x99State">
        <span className="text-danger">*其他陳述：</span>
        {xRay.x99State}
      </div>
    );
  }
  if (xRay.memos) {
    nodes.push(
      <div key="memos">
        <span className="text-danger">*備註：</span>
        {xRay.memos}
      </div>
    );
  }

  return nodes;
}

function invalidStatus(data: ExaminedResultEnum): boolean {
  return data === 1 || data === 2 || data === 3;
}
const DEFAULT_XRAY_RESULT: PHIXRay = {
  id: 0,
  year: 0,
  sem: 0,
  grade: 0,
  examined: true,
  xRay: ExaminedResultEnum.NoProblem,
  phthisis: ExaminedResultEnum.NoProblem,
  calcify: ExaminedResultEnum.NoProblem,
  c03: ExaminedResultEnum.NoProblem,
  water: ExaminedResultEnum.NoProblem,
  c02: ExaminedResultEnum.NoProblem,
  bigHeart: ExaminedResultEnum.NoProblem,
  brochus: ExaminedResultEnum.NoProblem,
  x99: ExaminedResultEnum.NoProblem,
};

const NO_EXAMINED_XRAY_RESULT: PHIXRay = {
  id: 0,
  year: 0,
  sem: 0,
  grade: 0,
  examined: false,
  xRay: ExaminedResultEnum.NoExamined,
  phthisis: ExaminedResultEnum.NoExamined,
  calcify: ExaminedResultEnum.NoExamined,
  c03: ExaminedResultEnum.NoExamined,
  water: ExaminedResultEnum.NoExamined,
  c02: ExaminedResultEnum.NoExamined,
  bigHeart: ExaminedResultEnum.NoExamined,
  brochus: ExaminedResultEnum.NoExamined,
  x99: ExaminedResultEnum.NoExamined,
};

const PHIXRayCalculators = [
  createCalculator<PHIXRay>({
    field: /(phthisis|calcify|c03|water|c02|bigHeart|brochus)/,
    updates: {
      xRay: (value: number, allValues: PHIXRay | undefined) => {
        const examinedResult = [
          allValues?.phthisis,
          allValues?.calcify,
          allValues?.c03,
          allValues?.water,
          allValues?.c02,
          allValues?.bigHeart,
          allValues?.brochus,
          allValues?.x99,
        ];
        const examinedAbnormal = examinedResult
          .map((i) => i ?? ExaminedResultEnum.NoProblem)
          .filter((num) => num > 0);
        if (examinedAbnormal.length) {
          const min = Math.min(...examinedAbnormal);
          return min || allValues?.xRay;
        }
        return allValues?.xRay;
      },
    },
  }),
];
