import React, { ReactNode, useEffect, useState } from 'react';
import { Class, YearSem } from '../model';
import apis from '../apis';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { listToObject } from '../utils';
import { useDispatch } from 'react-redux';
import { ErrorDispatches } from '../redux/Dispatches';
import { useQuery } from './useQuery';
import { useHistory } from 'react-router';

const CLASS_QUERY_PARAM_KEY = 'class';

type Argument = {
  yearSem?: YearSem;
  disableQueryParamStore?: boolean;
};

export const useClassGrade = ({
  yearSem,
  disableQueryParamStore,
}: Argument): {
  selectedClassGrade?: Class;
  allClassGrade: Record<string, Class>;
  element: ReactNode;
} => {
  const searchParams = useQuery();
  const history = useHistory();
  const [showClassId, setShowClassId] = useState<string>(
    searchParams.get(CLASS_QUERY_PARAM_KEY) ?? ''
  );
  const [classes, setClasses] = useState<{ [k: string]: Class }>({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (yearSem)
      apis
        .getNoticeClasses(yearSem.year, yearSem.sem)
        .then((classes) => {
          if (classes.length)
            setClasses(
              listToObject(
                classes,
                (c) => c.id,
                (c) => c
              )
            );
          setShowClassId('');
        })
        .catch(dispatch(ErrorDispatches.globalCatch));
  }, [yearSem?.year, yearSem?.sem]);

  if (!disableQueryParamStore) {
    useEffect(() => {
      searchParams.set(CLASS_QUERY_PARAM_KEY, showClassId);
      history.push({ search: searchParams.toString() });
    }, [showClassId]);
  }

  return {
    selectedClassGrade: classes[showClassId],
    allClassGrade: classes,
    element: (
      <>
        <DropdownButton
          title={
            yearSem
              ? (showClassId &&
                  classes[showClassId] &&
                  classes[showClassId].name) ||
                '請選擇班級'
              : '請先選擇學年'
          }
          onSelect={(k: string | null) => {
            if (k == null || !classes[k]) return;
            setShowClassId(k);
          }}
        >
          {Object.entries(classes).map(([i, n]) => (
            <Dropdown.Item key={`class-${i}`} eventKey={i}>
              {n.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </>
    ),
  };
};
