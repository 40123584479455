import { z } from 'zod';
import { DateTime } from 'luxon';
import { GetAcademicYear } from '../utils/semester';
import {
  CheckStudentBaseData,
  CheckSemester,
  CheckDate,
  CheckNumber,
  CheckDateInSemester,
} from '../utils/checkFormat';
import { zodStringToDateTime } from '../utils/zod';
import { ClassStudentZod } from './Class';

export const PhiWHZod = ClassStudentZod.merge(
  z.object({
    /* 身分證	學號	年級班級座號	學期	身高	體重	測量日 */
    // pid: z.string().nullish(),
    // sid: z.string().nullish(),
    // grade: z.string().nullish(),
    // no: z.string().nullish(),
    // seat: z.string().nullish(),
    sem: z.string(),
    year: z.number(),
    height: z.number(),
    weight: z.number(),
    bmi: z.number(),
    bmiCode: z.string(),
    examDate: zodStringToDateTime(),
  })
);

export type PhiWH = z.infer<typeof PhiWHZod>;

export const PhiWHFileZod = z
  .object({
    身分證: z.string().nullish(),
    學號: z.string().nullish(),
    年級班級座號: z.string().nullish(),
    學期: z.string().nullish(),
    身高: z.string().nullish(),
    體重: z.string().nullish(),
    測量日: z.string().nullish(),
    year: z.number(),
    sem: z.number(),
  })
  .transform((arg) => {
    let _okUpload = true;
    let _errorMsg = '';

    //檢查【身分證】、【學號】、【年級班級座號】格式
    const checkStudentBaseDataResult = CheckStudentBaseData(
      arg.身分證,
      arg.學號,
      arg.年級班級座號
    );
    const _pid = checkStudentBaseDataResult.pid;
    const _sid = checkStudentBaseDataResult.sid;
    const _grade = checkStudentBaseDataResult.grade;
    const _no = checkStudentBaseDataResult.no;
    const _seat = checkStudentBaseDataResult.seat;
    if (!checkStudentBaseDataResult.okUpload) {
      _okUpload = checkStudentBaseDataResult.okUpload;
      _errorMsg += checkStudentBaseDataResult.errorMsg;
    }

    const checkSemester = CheckSemester(arg.學期);
    const _semester = checkSemester.semester;
    if (!checkSemester.okUpload) {
      _okUpload = checkSemester.okUpload;
      _errorMsg += checkSemester.errorMsg;
    } else if (+_semester !== arg.sem) {
      _okUpload = false;
      _errorMsg += '匯入學期不一致，';
    }

    let _height = -9;
    if (arg.身高 != '-9') {
      const checkHeight = CheckNumber(arg.身高, 0, '身高');
      if (!checkHeight.okUpload) {
        _okUpload = checkHeight.okUpload;
        _errorMsg += checkHeight.errorMsg;
      } else {
        _height = checkHeight.code;
        if (_height < 60 || _height > 226) {
          _okUpload = false;
          _errorMsg += '【身高】範圍應在60~226之間';
        }
      }
    }

    let _weight = -9;
    if (arg.體重 != '-9') {
      const checkWeight = CheckNumber(arg.體重, 0, '體重');
      if (!checkWeight.okUpload) {
        _okUpload = checkWeight.okUpload;
        _errorMsg += checkWeight.errorMsg;
      } else {
        _weight = checkWeight.code;
        if (_weight < 3 || _weight > 204) {
          _okUpload = false;
          _errorMsg += '【體重】範圍應在3~204之間';
        }
      }
    }
    //bmi計算
    let _bmi = -9;
    let _bmiCode = '';
    if (_height != null && _weight != null) {
      _bmi = _weight / ((_height * _height) / 10000);
    }

    switch (true) {
      case _bmi < 16:
        _bmiCode = '-2';
        break;
      case _bmi < 18.5 && _bmi >= 16:
        _bmiCode = '-1';
        break;
      case _bmi < 24 && _bmi >= 18.5:
        _bmiCode = '0';
        break;
      case _bmi < 27 && _bmi >= 24:
        _bmiCode = '1';
        break;
      case _bmi < 35 && _bmi >= 30:
        _bmiCode = '2';
        break;
      default:
        _bmiCode = '-9';
        break;
    }
    if (_height == -9 || _weight == -9) {
      _bmi = -9;
      _bmiCode = '-9';
    }

    const checkDate = CheckDateInSemester(
      arg.測量日,
      arg.year,
      arg.sem,
      '測量日'
    );
    const _examDate = arg.測量日;
    // 若身高體重 = -9 測量日非必填
    if (checkDate.isNull) {
      if (_height != -9 || _weight != -9) {
        _okUpload = false;
        _errorMsg += checkDate.errorMsg;
      }
    }

    //移除最後一個'，'
    if (_errorMsg.length != 0) {
      _errorMsg = _errorMsg.slice(0, -1);
    }

    return {
      pid: _pid,
      sid: _sid,
      grade: _grade,
      no: _no,
      seat: _seat,
      sem: _semester,
      year: GetAcademicYear,
      height: _height,
      weight: _weight,
      bmi: _bmi,
      bmiCode: _bmiCode,
      examDate: _examDate,
      okUpload: _okUpload,
      remark: _errorMsg,
    };
  });

export type PhiWHFile = z.infer<typeof PhiWHFileZod>;
