import React, { FunctionComponent, ReactElement, useState } from 'react';
import { PHITee, Semester, Student, YearSemClasses } from '../../model';
import {
  HeaderDisplayKeysWithType,
  InlineEditableTable,
  OverflowWithTooltip,
} from '../../components';
import apis from '../../apis';
import { Button, Col, FormCheck, Modal, Row, Table } from 'react-bootstrap';
import {
  DefaultTeeContext,
  ExtractedPHITee,
  combindFirstAndFollowUpExamination,
  extractFirstAndFollowUpExamination,
} from '../../utils/tee';
import {
  TeeFollowUpTableHeader,
  TeeTableHeader,
  ExtractedPHITeeCalculators,
} from '../TableHeaders/TeeHeader';

type PageData = PHITee;

type Props = {
  editable: boolean;
  tee: PageData[];
  student: Student;
  schoolId: string;
  semesters: Semester[];
  yearSems: YearSemClasses[];
  onApiError: (e: any) => void;
  onValueUpdated: () => void;
};

const inlineTeeTableHeader: HeaderDisplayKeysWithType<ExtractedPHITee>[] = [
  { property: 'year', display: '學年' },
  { property: 'sem', display: '學期' },
  ...TeeTableHeader,
];

const inlineFollowUpTeeTableHeader: HeaderDisplayKeysWithType<ExtractedPHITee>[] =
  [
    { property: 'year', display: '學年' },
    { property: 'sem', display: '學期' },
    ...TeeFollowUpTableHeader,
  ];

export const TeeSection: FunctionComponent<Props> = ({
  editable,
  tee,
  student,
  schoolId,
  semesters,
  yearSems,
  onApiError,
  onValueUpdated,
}) => {
  const [inputing, setInputing] = useState(false);
  const [followUp, setFollowUp] = useState(false);
  const currentSemester = semesters.find((s) => s.isNow);
  const too = tee.find(
    (s) => s.sem === currentSemester?.sem && s.year === currentSemester?.year
  );
  function onValueUpdate(value: ExtractedPHITee) {
    const combinedValue = combindFirstAndFollowUpExamination(value);
    apis
      .InsertStudentTee(student.pid, {
        id: 0,
        yearClassId: 0,
        ...combinedValue,
      })
      .catch(onApiError)
      .then(onValueUpdated);
  }

  function onValueDelete(value: ExtractedPHITee) {
    apis
      .deleteStudentSight(student.pid, value.year, value.sem)
      .catch(onApiError)
      .then(onValueUpdated);
  }

  yearSems
    .filter((s) => !tee.some((t) => t.sem === s.sem && t.year === s.year))
    .forEach(({ year, sem, yearClassId }) => {
      tee.push({
        year,
        sem,
        id: 0,
        yearClassId,
        tee: false,
        t01: 0,
        t02: 0,
        t04: 0,
        t05: 0,
        t06: 0,
        t07: 0,
        t08: 0,
        t99: 0,
        t09: 0,
        t11: 0,
        t12: 0,
        t13: 0,
        t14: 0,
        t15: 0,
        t16: 0,
        t17: 0,
        t03: 0,
        t18: 0,
        t19: 0,
      });
    });

  tee.sort((a, b) => a.sem - b.sem + (a.year - b.year) * 100);

  return (
    <>
      <div className="sheet-title">
        經常性口腔資料
        <Button
          key="cell-edit"
          className="border-0 px-2 py-1"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setInputing(true);
          }}
        >
          <span className="feather icon-edit" />
        </Button>
      </div>
      <Table bordered className="student-phi">
        <tbody>
          <tr>
            <th>口腔檢查 </th>
            <td>{generateCheckBox('', too?.tee)}</td>
          </tr>
          <tr>
            <th>未治療齲齒</th>
            <td>{too?.t01}</td>
          </tr>
          <tr>
            <th>已治療齲齒</th>
            <td>{too?.t11}</td>
          </tr>
          <tr>
            <th>上顎恆牙第一大臼齒齲齒經驗</th>
            <td>{too?.t12}</td>
          </tr>
          <tr>
            <th>下顎恆牙第一大臼齒齲齒經驗</th>
            <td>{too?.t13}</td>
          </tr>
          <tr>
            <th>恆牙臼齒窩溝封填</th>
            <td>{too?.t15}</td>
          </tr>
          <tr>
            <th>口腔衛生不良</th>
            <td>{too?.t04}</td>
          </tr>
          <tr>
            <th>牙結石</th>
            <td>{too?.t05}</td>
          </tr>
          <tr>
            <th>牙齦炎</th>
            <td>{too?.t08}</td>
          </tr>
          <tr>
            <th>咬合不正</th>
            <td>{too?.t07}</td>
          </tr>
          <tr>
            <th>口腔黏膜異常</th>
            <td>{too?.t09}</td>
          </tr>
          <tr>
            <th>牙周病</th>
            <td>{too?.t16}</td>
          </tr>
          <tr>
            <th>乳牙待拔牙</th>
            <td>{too?.t17}</td>
          </tr>
          <tr>
            <th>待拔牙</th>
            <td>{too?.t03}</td>
          </tr>
          <tr>
            <th>贅生牙</th>
            <td>{too?.t18}</td>
          </tr>
          <tr>
            <th>缺牙</th>
            <td>{too?.t02}</td>
          </tr>
          <tr>
            <th>阻生牙</th>
            <td>{too?.t19}</td>
          </tr>
          <tr>
            <th>口腔其它</th>
            <td>{too?.t99}</td>
          </tr>
          <tr>
            <th>其他陳述</th>
            <td>{too?.t99state}</td>
          </tr>
          <tr>
            <th>口檢表</th>
            <td>
              <OverflowWithTooltip>
                {Object.entries(too?.tSheet || {})
                  .map((s) => s.join(''))
                  .join(',')}
              </OverflowWithTooltip>
            </td>
          </tr>
        </tbody>
      </Table>
      <Modal
        show={inputing}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={() => setInputing(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            經常性口腔資料 -{' '}
            <strong>
              {student.seat} {student.name}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <Row noGutters className="mb-2">
            <Col
              sm={2}
              as={Button}
              disabled={!followUp}
              onClick={() => setFollowUp(false)}
              className="mr-2"
            >
              初檢
            </Col>
            <Col
              sm={2}
              as={Button}
              disabled={followUp}
              onClick={() => setFollowUp(true)}
            >
              複檢
            </Col>
          </Row>
          <InlineEditableTable
            values={tee.map((t) => extractFirstAndFollowUpExamination(t))}
            decorators={ExtractedPHITeeCalculators}
            headers={
              (followUp
                ? inlineFollowUpTeeTableHeader
                : inlineTeeTableHeader) as any
            }
            onValueUpdate={onValueUpdate}
            onValueDelete={onValueDelete}
            onRowRender={(v) => ({
              editable:
                editable &&
                (!v.schoolId || schoolId === v.schoolId) &&
                semesters.some((s) => s.sem === v.sem && s.year === v.year),
              deleteable:
                (!v.schoolId || schoolId === v.schoolId) &&
                semesters.some((s) => s.sem === v.sem && s.year === v.year),
            })}
            context={DefaultTeeContext}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

function generateCheckBox(
  label: string,
  value: boolean | undefined
): ReactElement {
  return (
    <>
      {label}
      <FormCheck inline readOnly checked={value || false} />
    </>
  );
}
