import React, { ReactNode } from 'react';
import { DateTime } from 'luxon';
import {
  DateTimeField,
  HeaderDisplayKeysWithType,
  NumberField,
} from '../../components';
import { PHIWH } from '../../model';
import { semesterRange } from '../../utils/date';

export const WHPropertyFields = {
  height: (
    <NumberField
      property="height"
      min={-10}
      max={226}
      validate={(v) =>
        (+v <= 226 && +v >= 60) || +v === -9 ? undefined : `值應在60~226或-9`
      }
    />
  ),
  weight: (
    <NumberField
      property="weight"
      min={-10}
      max={204}
      validate={(v) =>
        (+v <= 204 && +v >= 3) || +v === -9 ? undefined : `值應在3~204或-9`
      }
    />
  ),
  examDate: (start?: DateTime, end?: DateTime, allValues?: any) => (
    <DateTimeField
      property="examDate"
      start={start}
      end={end}
      validate={(v) => {
        const weight = allValues?.weight;
        const height = allValues?.height;
        if (
          (weight === -9 || height === -9) &&
          (v === null || v === undefined)
        ) {
          return undefined; // 允許空值
        }
        return v
          ? v instanceof DateTime && v.isValid
            ? undefined
            : v instanceof Date && v.valueOf()
              ? undefined
              : '日期格式不正確'
          : '日期格式不正確';
      }}
    />
  ),
} as const;

const transformNumberField = (
  editingNode: ReactNode,
  v: unknown,
  i: number,
  e?: boolean
) => (e ? editingNode : (v as string));

export const WH_CONTEXT_SEM_RANGE = 'WhSemesterRange';
export const WH_CONTEXT_BMI_CODE_KEY = 'WhBMICode';
export const DefaultWHContext = {
  [WH_CONTEXT_BMI_CODE_KEY]: {
    '-9': '未測量',
    '-2': '體型瘦弱',
    '-1': '體重過輕',
    '0': '體重適中',
    '1': '體重過重',
    '2': '體重超重',
  } as Record<string, string>,
  [WH_CONTEXT_SEM_RANGE]: [DateTime.now().minus({ months: 6 }), DateTime.now()],
} as const;

export const WHTableHeader: HeaderDisplayKeysWithType<PHIWH>[] = [
  {
    property: 'height',
    display: '身高（公分）',
    onRender: transformNumberField.bind(null, WHPropertyFields.height),
  },
  {
    property: 'weight',
    display: '體重（公斤）',
    onRender: transformNumberField.bind(null, WHPropertyFields.weight),
  },
  {
    property: 'bmi',
    display: 'BMI',
    onRender: (value: unknown) => {
      if (typeof value === 'number') {
        return Math.round(value * 10) / 10; // 四捨五入到小數點後一位
      }
      return value;
    }
  },
  {
    property: 'bmiCode',
    display: '判讀',
    onRender: (v: unknown, i, e, c) => {
      const {
        [WH_CONTEXT_BMI_CODE_KEY]: Codes = DefaultWHContext[
        WH_CONTEXT_BMI_CODE_KEY
        ],
      } = (c?.context || DefaultWHContext) as typeof DefaultWHContext;
      return (typeof v === 'string' ? Codes[v] : (v as string)) || '';
    },
  },
  {
    property: 'examDate',
    display: '測量日',
    onRender: (v: unknown, i, e, c) => {
      if (e) {
        const { year, sem } = c.allValues;
        const [start, end] = semesterRange(year, sem);
        return WHPropertyFields.examDate(start, end);
      }
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
    },
  },
];
