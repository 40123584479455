import { string, z } from 'zod';
import { PhysicalSettingZod, 
  PhysicalSetting, 
  LabSem, 
  LabSemWithNull,
  LabSemZod, 
  LabSemWithNullZod,
  LabSemCheckDate, 
  LabSemCheckDateZod,
  PHIParasiteZod,
  PHIPhysical,
  PHIBlood,
  PHIXRay
} from '../model';
import { get, post, put } from './BaseApi';

const PhysicalSettingsZod = z.array(PhysicalSettingZod);
const LabSemArr = z.array(LabSemZod);
const LabSemData = LabSemZod;
const LabSemWithNullData = LabSemWithNullZod;
export const PHIParasiteUpdateArr = z.array(z.object({
  id: z.number(),
  year: z.number(),
  sem: z.number(),
  grade: z.number(),
  no: z.number(),
  inspect: z.number().nullish(),
  parasite: z.number(),
  drug: z.boolean(),
  examDate: string(),
  yearClassId: z.number(),
}));
export async function getPhysicalSettings(
  fromYear: number,
  toYear: number
): Promise<PhysicalSetting[]>;
export async function getPhysicalSettings(): Promise<PhysicalSetting[]>;
export async function getPhysicalSettings(
  fromYear?: number,
  toYear?: number
): Promise<PhysicalSetting[]> {
  return fromYear && toYear
    ? await get(`/api/settings/physical`, PhysicalSettingsZod, {
        from: fromYear,
        to: toYear,
      })
    : await get(`/api/settings/physical`, PhysicalSettingsZod);
}

export async function upsertPhysicalSettingList(
  setting: PhysicalSetting[]
): Promise<void> {
  await post(`/api/settings/physical/update/list`, setting);
}
export async function upsertPhysicalSetting(
  setting: PhysicalSetting,
): Promise<void> {
  await post(`/api/settings/physical`, setting);
}

export async function updateStudentPhysicalSetting(
  setting: PhysicalSetting[],
  physical: PHIPhysical,
  blood: PHIBlood,
  xRay: PHIXRay,
  classIds: string[],
  year: number,
  sem: number
): Promise<void> {
  await post(`/api/settings/physical/student/update`, {
    PhysicalSettingView: setting,
    PhiPhysicalView: physical,
    PhiBloodView: blood,
    PHIXRayView: xRay,
    ClassIds: classIds,
    year: year,
    sem: sem
  });
}

export async function deletePhysicalSetting(
  setting: PhysicalSetting
): Promise<void> {
  await post(`/api/settings/physical/delete`, setting);
}

// 顯示所有實驗室檢查設定
export async function getLabSemSetting(
): Promise<LabSem[]> {
  return await get(`/api/settings/labsem/list`, LabSemArr, {})
}

// 只顯示現在學年實驗室檢查設定
export async function getLabSemSettingsNow(
  year: number
): Promise<LabSem[]> {
  return await get(`/api/settings/labsem/list/now`, LabSemArr, {year})
}

export async function insertLabSemSetting(
  year: number,
  sem: number,
  gradeId: number,
  labKind: number,
  checkDate: string,
  checkDate2: string,
  schoolId: string,
): Promise<void> {
  await post(`/api/settings/labsem/insert/${sem}`, {
    year, 
    gradeId, 
    labKind, 
    checkDate, 
    checkDate2, 
    schoolId
  });
}

export async function updateLabSemExamDate(
  id: number,
  year: number,
  gradeID: number,
  labKind: number,
  checkDate: string,
  checkDate2: string,
  schoolId: string,
  updateCheckDate: number,
  updateCheckDate2: number,
): Promise<void> {
  return await put(`/api/settings/labsem/update/${updateCheckDate}/${updateCheckDate2}`, {
    id,
    year,
    gradeID,
    labKind,
    checkDate,
    checkDate2,
    schoolId,
  })
}

export async function updateLabSemResult(
  labKind: number,
  year: number,
  sem: number,
  gradeID: number,
): Promise<void> {
  return await put(`/api/settings/labsem/update/result`, {
    labKind,
    year,
    sem,
    gradeID
  })
}

export async function deleteLabSemSettings(
  id: number,
  year: number,
  gradeID: number,
  labKind: number,
  checkDate: string,
  checkDate2: string,
  SchoolId: string,
): Promise<void> {
  return await put(`/api/settings/labsem/delete`, {
    id,
    year,
    gradeID,
    labKind,
    checkDate,
    checkDate2,
    SchoolId
  })
}

export async function getLabSemCheckDate2(
  labKind: number,
  year: number,
  gradeID: number,
): Promise<LabSemWithNull | null> {
  const response =  await get(`/api/settings/labsem/checkdate`, LabSemWithNullData, {
    labKind,
    year,
    gradeID
  });

  if (response === null) {
    return null;
  }

  return response;
}

// 更新寄生蟲檢查複查
export async function createLabSemParasite(
  pid: string,
  year: number,
  sem: number,
  grade: number,
  inspect: number,
  parasite: number,
  drug: boolean,
  examDate: string,
  yearClassId: number,
): Promise<void> {
  return await put(`/api/settings/labsem/parasite/create`, {
    pid,
    year,
    sem,
    grade,
    inspect,
    parasite,
    drug,
    examDate,
    yearClassId,
  })
}

// 新增尿液檢查
export async function createLabSemUrine(
  pid: string,
  year: number,
  sem: number,
  grade: number,
  inspect: number,
  urine: number,
  uProtein: number,
  uOB: number,
  uGlucose: number,
  uPh: number,
  followUp: string,
  examDate: string,
  yearClassId: number,
): Promise<void> {
  return await put(`/api/settings/labsem/urine/create`, {
    pid,
    year,
    sem,
    grade,
    inspect,
    urine,
    uProtein,
    uOB,
    uGlucose,
    uPh,
    followUp,
    examDate,
    yearClassId,
  })
}
