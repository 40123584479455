import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableProperties,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeat, WH } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { whbmiDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
import { DateTime } from 'luxon';
import { WrapClassBaseLayout, ClassSem } from '../../../layouts';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
type PageData = StudentGradeNoSeat & Nullable<Sight> & Nullable<WH> & { diag: string } & EditableProperties;

const boolRender = (value: boolean) => (
    <Form.Check type="checkbox" checked={value} readOnly />
);

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '座號', property: 'seat' },
        { display: '姓名', property: 'name' },
        { display: '性別', property: 'sex' },
        { display: '身高', property: 'height' },
        { display: '體重', property: 'weight' },
        {
            display: '測量日',
            property: 'examDate',
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
            },
            style: { width: '7%' }
        },
        {
            display: 'BMI',
            property: 'bmi',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return Math.round(value * 10) / 10;
                }
                return value;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return Math.round(value * 10) / 10;
                }
                return value as string;
            },
        },
        { display: '體位', property: 'diag', style: { width: '5%' } },
        {
            display: '裸視右',
            property: 'sight0R',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            }
        },
        {
            display: '裸視左',
            property: 'sight0L',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            }
        },
        {
            display: '戴鏡右',
            property: 'sightR',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            }
        },
        {
            display: '戴鏡左',
            property: 'sightL',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            }
        },
        { display: '近視', property: 'eNear', onRender: boolRender },
        { display: '遠視', property: 'eFar', onRender: boolRender },
        { display: '散光', property: 'eSan', onRender: boolRender },
        { display: '弱視', property: 'eWeak', onRender: boolRender },
        { display: '其他', property: 'eSight99', onRender: boolRender },
        { display: '複檢無異狀', property: 'noProblem', onRender: boolRender },
        { display: '備註', property: 'eSight99State' },
        {
            display: '處置代號',
            property: 'manageID',
            onRender: (v) => (Array.isArray(v) ? v.join(',') : v),
        },
        {
            display: '醫師建議處置',
            property: 'manage',
        },
    ];

const mapStates = (state: ApplicationState, ownProps: ClassSem) => ({
    ...ownProps,
    ...state.auth,
});
const mapDispatch = ErrorDispatches;

const connector = connect(mapStates, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const wHSightClassList: FunctionComponent<Props> = ({
    classId,
    className,
    year,
    sem,
    grade,
    no,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const { selectedClassGrade: classGrade, element: classGradeElement } =
        useClassGrade({
            yearSem
        });
    const [students, setStudents] = useState<PageData[]>([]);
    const [NeedReceipt, setNeedReceipt] = useState<boolean>(false);
    const [NeedSeparateReceipt, setNeedSeparateReceipt] = useState<boolean>(false);
    const [NeedPrintCurrentSight, setNeedPrintCurrentSight] = useState<boolean>(false);
    const [NeedReceiptDeadline, setNeedReceiptDeadline] = useState<boolean>(false);
    const [isPrintFitWeight, setIsPrintFitWeight] = useState<boolean>(false);
    const [wHSightClassListPdfDate, setWHSightClassListPdfDate] = useState<DateTime>();

    const [bmiselectedFilter, setbmiSelectedFilter] = useState("體位全部");
    const [sightselectedFilter, setsightselectedFilter] = useState("視力全部");
    const [sightReviewFilter, setSightReviewFilter] = useState<string | null>(null);

    function filterBmicode(value: string, data: PageData[]): PageData[] {
        const filterbmicode: string[] = [];
        switch (value) {
            case '體位正常':
                filterbmicode.push("0");
                break;
            case '體位異常':
                filterbmicode.push("-1");
                filterbmicode.push("1");
                filterbmicode.push("2");
                break;
            default:
                return data; // Always return something, even if it's the original list
        }
        return data.filter((s) => filterbmicode.includes(s.bmiCode ?? ""));
    }

    function filterSight(value: string, reviewFilter: string | null, data: PageData[]): PageData[] {
        let filtered = [...data];

        switch (value) {
            case '視力正常':
                filtered = filtered.filter((s) => (s.sight0R ?? 0) > 0.9 && (s.sight0L ?? 0) > 0.9);
                break;
            case '視力異常':
                filtered = filtered.filter((s) => (s.sight0R ?? 0) < 0.9 || (s.sight0L ?? 0) < 0.9);
                if (reviewFilter) {
                    filtered = filtered.filter((s) =>
                        reviewFilter === '已複檢' ? s.noProblem === true : s.noProblem === false
                    );
                }
                break;
            default:
                break;
        }

        return filtered;
    }

    useEffect(() => {
        if (year && classId) {
            apis
                .getWHClassSight(year, sem, classId)
                .then((r) =>
                    setStudents(
                        r.map(({ sight, wh, ...s }) => ({
                            ...sight,
                            ...wh,
                            ...s,
                            diag: whbmiDiag(wh?.bmiCode?.toString()),
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [classId, year, sem]);

    const filteredStudents = useMemo(() => {
        const sightFiltered = filterSight(sightselectedFilter, sightReviewFilter, students);
        const bmiFiltered = filterBmicode(bmiselectedFilter, students);
        return sightFiltered.filter(student => bmiFiltered.includes(student));
    }, [students, bmiselectedFilter, sightselectedFilter, sightReviewFilter]);

    if (!classId) return <></>;
    return (
        <>
            <Row className="align-items-start" style={{ margin: '5px' }}>
                <Col sm={6} >
                    <Row className=' mb-1'>
                        <Col sm={10} className=' border-bottom pt-0'>
                            <h3>通知單設定</h3>
                        </Col>
                    </Row>

                    <Row>
                        視力：
                        <Col sm={1} className='ps-0'>
                            <Form.Check
                                type="radio"
                                label="全部"
                                name="sight"
                                defaultChecked
                                onChange={(e) => {
                                    setsightselectedFilter("視力全部");
                                    setSightReviewFilter(null);
                                }}
                            />
                        </Col>
                        <Col sm={1} className='ps-0'>
                            <Form.Check
                                type="radio"
                                label="正常"
                                name="sight"
                                onChange={(e) => {
                                    setsightselectedFilter("視力正常");
                                    setSightReviewFilter(null);
                                }}
                            />
                        </Col>
                        <Col sm={1} className='ps-0'>
                            <Form.Check
                                type="radio"
                                label="異常"
                                name="sight"
                                onChange={(e) => {
                                    setsightselectedFilter("視力異常");
                                }}
                            />
                        </Col>

                        <Col sm={4} className='ps-0 d-inline-flex'>
                            （
                            <Form.Check
                                type="checkbox"
                                label="已複檢"
                                name="sight_check"
                                disabled={sightselectedFilter !== "視力異常"}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSightReviewFilter("已複檢");
                                    } else {
                                        setSightReviewFilter(null);
                                    }
                                }}
                            />
                            <Form.Check
                                type="checkbox"
                                label="未複檢"
                                name="sight_check"
                                disabled={sightselectedFilter !== "視力異常"}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSightReviewFilter("未複檢");
                                    } else {
                                        setSightReviewFilter(null);
                                    }
                                }}
                            />
                            ）
                        </Col>
                    </Row>
                    <Row>
                        體位：
                        <Col sm={1} className='ps-0'>
                            <Form.Check
                                type="radio"
                                label="全部"
                                name="wh"
                                defaultChecked
                                onChange={(e) => {
                                    setbmiSelectedFilter("體位全部");
                                }}
                            />
                        </Col>
                        <Col sm={1} className='ps-0'>
                            <Form.Check
                                type="radio"
                                label="正常"
                                name="wh"
                                onChange={(e) => {
                                    setbmiSelectedFilter("體位正常");
                                }}
                            />
                        </Col>
                        <Col sm={1} className='ps-0'>
                            <Form.Check
                                type="radio"
                                label="異常"
                                name="wh"
                                onChange={(e) => {
                                    setbmiSelectedFilter("體位異常");
                                }}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col sm={6}>
                    <Row className='mb-1'>
                        <Col sm={12} className='border-bottom pt-0'>
                            <h3>通知單設定</h3>
                        </Col>
                    </Row>
                    <Row className='mb-1'>
                        <Col sm={12} className='border-bottom mb-1'>
                            <h5>衛教內容設定</h5>
                        </Col>
                        <Col sm={12} className='mb-1'>
                            <Form.Label>列印視力值：</Form.Label>
                            <Form.Check
                                inline
                                type="radio"
                                label="是"
                                name="education"
                                onChange={(e) => {
                                    setNeedPrintCurrentSight(e.target.checked);
                                }}
                            />
                            <Form.Check
                                inline
                                type="radio"
                                label="否"
                                name="education"
                                defaultChecked
                                onChange={(e) => {
                                    setNeedPrintCurrentSight(e.target.checked);
                                }}
                            />
                        </Col>
                        <Col sm={12}>
                            <Form.Label>列印建議體重：</Form.Label>
                            <Form.Check
                                inline
                                type="radio"
                                label="是"
                                name="fitweight"
                                onChange={(e) => {
                                    setIsPrintFitWeight(e.target.checked);
                                }}
                            />
                            <Form.Check
                                inline
                                type="radio"
                                label="否"
                                name="fitweight"
                                defaultChecked
                                onChange={(e) => {
                                    setIsPrintFitWeight(e.target.checked);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-1'>
                        <Col sm={12} className='border-bottom mb-1'>
                            <h5>回條內容設定</h5>
                        </Col>
                        <Col sm={12} className='mb-1'>
                            <Form.Label>視力正常，且體位異常者：</Form.Label>
                            <Form.Check
                                inline
                                type="radio"
                                label="列印家長回條"
                                name="bmi_abnormal"
                                defaultChecked
                                onChange={(e) => {
                                    setNeedReceipt(e.target.checked);
                                }}
                            />
                            <Form.Check
                                inline
                                type="radio"
                                label="無回條單"
                                name="bmi_abnormal"
                                onChange={(e) => {
                                    setNeedReceipt(e.target.checked);
                                }}
                            />
                        </Col>
                        <Col sm={12} className='mb-1'>
                            <Form.Label>視力正常，且體位正常者：</Form.Label>
                            <Form.Check
                                inline
                                type="radio"
                                label="列印家長回條"
                                name="whsight_normal"
                                defaultChecked
                                onChange={(e) => {
                                    setNeedReceipt(e.target.checked);
                                }}
                            />
                            <Form.Check
                                inline
                                type="radio"
                                label="無回條單"
                                name="whsight_normal"
                                onChange={(e) => {
                                    setNeedReceipt(e.target.checked);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-1'>
                        <Col sm={12} className='border-bottom mb-1'>
                            <h5>繳回期限設定</h5>
                        </Col>
                        <Col sm={12} className='mb-1'>
                            <Form.Label>列印繳回期限：</Form.Label>
                            <Form.Check
                                inline
                                type="radio"
                                label="是"
                                name="print_date"
                                defaultChecked
                                onChange={(e) => {
                                    setNeedReceiptDeadline(e.target.checked);
                                }}
                            />
                            <Form.Check
                                inline
                                type="radio"
                                label="否"
                                name="print_date"
                                onChange={(e) => {
                                    setNeedReceiptDeadline(e.target.checked);
                                }}
                            />
                            <ReactDatePicker
                                className="wid-110"
                                dateFormat={'yyyy/MM/dd'}
                                selected={wHSightClassListPdfDate?.toJSDate()}
                                onChange={(date) => {
                                    if (date instanceof Date) {
                                        setWHSightClassListPdfDate(DateTime.fromJSDate(date));
                                    } else {
                                        setWHSightClassListPdfDate(undefined);
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-1'>
                        <Col sm={12} className='border-bottom mb-1'>
                            <h5>通知單格式設定</h5>
                        </Col>
                        <Col sm={12}>
                            <Form.Label>衛教內容與回條單分開列印：</Form.Label>
                            <Form.Check
                                inline
                                type="radio"
                                label="是"
                                name="print_separate"
                                onChange={(e) => {
                                    setNeedSeparateReceipt(e.target.checked);
                                }}
                            />
                            <Form.Check
                                inline
                                type="radio"
                                label="否"
                                name="print_separate"
                                defaultChecked
                                onChange={(e) => {
                                    setNeedSeparateReceipt(e.target.checked);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>


            <hr />

            <Row>
                <Button
                    className="ml-auto mb-2"
                    onClick={() => {
                        let pids = students.filter((s) => s.selected).map((s) => s.pid);
                        pids = pids.length == 0 ? students.map((s) => s.pid) : pids;
                        toast
                            .promise(
                                apis.downloadWHSightPDF(
                                    classId,
                                    pids,
                                    year,
                                    sem,
                                    NeedReceipt,
                                    NeedPrintCurrentSight,
                                    NeedSeparateReceipt,
                                    NeedReceiptDeadline,
                                    isPrintFitWeight,
                                    wHSightClassListPdfDate
                                ),
                                {
                                    pending: '下載中...',
                                    success: '下載成功！',
                                    error: '下載失敗！請查看錯誤資訊。',
                                }
                            )
                            .then((blob) => {
                                const objlink = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = objlink;
                                a.setAttribute(
                                    'download',
                                    `身高體重測量結果通知單-${className}.pdf`
                                );
                                document.body.appendChild(a);
                                a.click();

                                a.parentNode?.removeChild(a);
                            })
                            .catch(catchErrorForModal);
                    }}
                >
                    <span className="feather icon-download"></span>
                </Button>
                <EditableTableDiv
                    headers={tableHeader}
                    values={filteredStudents}
                    context={SightContext}
                    onSelected={(s) => {
                        if (s.index !== undefined) {
                            const v = students[s.index];
                            v.selected = s.checked;
                        } else {
                            students.forEach((v) => {
                                v.selected = s.checked;
                            });
                        }
                        setStudents([...students]);
                    }}
                />
            </Row>
        </>
    );
};

export const WHSightClassList = connector(WrapClassBaseLayout(wHSightClassList));